import { PropsWithChildren, ReactNode } from 'react';

import cardRecipe, { CardRecipeProps } from './card.styles';
import { cx } from '@/utils/cx';

type CardBodyProps = {
    className?: string;
    children?: ReactNode;
    ariaLabel?: string;
} & CardRecipeProps;

const CardBody = ({
    className,
    children,
    ariaLabel,
    ...rest
}: PropsWithChildren<CardBodyProps>) => {
    const [variantProps, localProps] = cardRecipe.splitVariantProps(rest);
    const recipeClassNames = cardRecipe(variantProps);

    return (
        <div
            className={cx(recipeClassNames.body, className)}
            aria-label={ariaLabel}
            {...localProps}>
            {children}
        </div>
    );
};

export { CardBody };
export type { CardBodyProps };
