
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Separator'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Separators may be added between menu items or sections in order to create non-labeled groupings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Actions">Actions</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuItem>New...</varnishUi.MenuItem>
                <varnishUi.MenuItem>Open...</varnishUi.MenuItem>
                <varnishUi.Separator />
                <varnishUi.MenuItem>Save</varnishUi.MenuItem>
                <varnishUi.MenuItem>Save as...</varnishUi.MenuItem>
                <varnishUi.MenuItem>Rename...</varnishUi.MenuItem>
                <varnishUi.Separator />
                <varnishUi.MenuItem>Page setup…</varnishUi.MenuItem>
                <varnishUi.MenuItem>Print…</varnishUi.MenuItem>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;