
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'Submenus'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Submenus can be created by wrapping an item and a submenu in a SubmenuTrigger.
The SubmenuTrigger accepts exactly two children: the first child should be the MenuItem which triggers opening of the submenu, and second child should be the Popover containing the submenu.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';

const VarnishChevronRightIcon = varnishUi.varnishIcon(ChevronRightIcon);

const className = emotionCss.css\`
    color: white;
    top: 0;
    right: 0;
    position: absolute;
\`;

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Actions">Actions</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuItem>Cut</varnishUi.MenuItem>
                <varnishUi.MenuItem>Copy</varnishUi.MenuItem>
                <varnishUi.MenuItem>Delete</varnishUi.MenuItem>
                <varnishUi.SubmenuTrigger>
                    <varnishUi.MenuItem>
                        <div>
                            Share
                            <VarnishChevronRightIcon
                                size="large"
                                color="secondary"
                                className={className}
                            />
                        </div>
                    </varnishUi.MenuItem>
                    <varnishUi.Popover>
                        <varnishUi.AriaMenu>
                            <varnishUi.MenuItem>SMS</varnishUi.MenuItem>
                            <varnishUi.MenuItem>X</varnishUi.MenuItem>
                            <varnishUi.SubmenuTrigger>
                                <varnishUi.MenuItem>
                                    <div>
                                        Email
                                        <VarnishChevronRightIcon
                                            size="large"
                                            color="secondary"
                                            className={className}
                                        />
                                    </div>
                                </varnishUi.MenuItem>
                                <varnishUi.Popover>
                                    <varnishUi.AriaMenu>
                                        <varnishUi.MenuItem>Work</varnishUi.MenuItem>
                                        <varnishUi.MenuItem>Personal</varnishUi.MenuItem>
                                    </varnishUi.AriaMenu>
                                </varnishUi.Popover>
                            </varnishUi.SubmenuTrigger>
                        </varnishUi.AriaMenu>
                    </varnishUi.Popover>
                </varnishUi.SubmenuTrigger>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;