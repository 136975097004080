
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Links'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`By default, interacting with an item in a Menu triggers onAction and optionally onSelectionChange depending on the selectionMode.
Alternatively, items may be links to another page or website.
This can be achieved by passing the href prop to the MenuItem component. Link items in a menu are not selectable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Links">Links</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuItem href="https://adobe.com/" target="_blank">
                    Adobe
                </varnishUi.MenuItem>
                <varnishUi.MenuItem href="https://apple.com/" target="_blank">
                    Apple
                </varnishUi.MenuItem>
                <varnishUi.MenuItem href="https://google.com/" target="_blank">
                    Google
                </varnishUi.MenuItem>
                <varnishUi.MenuItem href="https://microsoft.com/" target="_blank">
                    Microsoft
                </varnishUi.MenuItem>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;