
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Text Contrast'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Each color defined by Varnish has a `}<inlineCode parentName="p">{`contrastTextColor`}</inlineCode>{` attached to it. If the
color is used as the `}<inlineCode parentName="p">{`background`}</inlineCode>{` of an element, we suggest using the `}<inlineCode parentName="p">{`contrastTextColor`}</inlineCode>{` for readability.`}</p>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled, css } from '@mui/material/styles';
import mui from '@mui/material';
import ThemeMui from '@allenai/varnish2/theme';

const Demo = () => {
  const ColorDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'col',
  })<{ col: string }>(
    ({ theme, col }) =>css\`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: \${theme.spacing(1)};
        height: \${theme.spacing(5)};
        color: \${theme.color[col].contrastTextColor};
        background: \${theme.color[col].toString()};
        border: 1px solid
          \${theme.color[col].contrastTextColor};
      \`,
  );

  const theme = mui.useTheme();

  const colorsToRender = ['pink-100', 'dark-teal-100', 'purple-100', 'green-100', 'error-red-100'];

  const getContrastTextColorName = (c) => {
    const color = [theme.color['dark-teal-100'], theme.color['extra-dark-teal-100'], theme.color['cream-100']].find(color => color.rgba.toString() === theme.color[c].contrastTextColor);
    return color.displayName;
  }

  return (
    <mui.Stack spacing={1.5} width={1}>
      {colorsToRender.map((c) => (
        <ColorDiv key={c} col={c}>
          {c} ({getContrastTextColorName(c)})
        </ColorDiv>
      ))}
    </mui.Stack>
    )
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;