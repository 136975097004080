import * as Default from './demo/default.mdx';
import * as Selection from './demo/selection.mdx';
import * as Links from './demo/links.mdx';
import * as Sections from './demo/sections.mdx';
import * as Separator from './demo/separator.mdx';
import * as SectionLevelSection from './demo/sectionLevelSection.mdx';
import * as TextSlots from './demo/textslots.mdx';
import * as LongPress from './demo/longPress.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as Submenu from './demo/submenu.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const MenuExamples = [
    Default,
    Selection,
    Links,
    Sections,
    Separator,
    SectionLevelSection,
    TextSlots,
    LongPress,
    Disabled,
    Submenu,
    StylingCss,
    StylingStyled,
];
