import { Text as AriaText, type TextProps as AriaTextProps } from 'react-aria-components';
import { PropsWithChildren } from 'react';

type MenuTextProps = AriaTextProps;

const MenuText = (props: PropsWithChildren<MenuTextProps>) => {
    return <AriaText {...props} />;
};

export { MenuText };
export type { MenuTextProps };
