import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const selectRecipe = sva({
    slots: [
        'root',
        'button',
        'arrowIcon',
        'listBox',
        'listBoxSection',
        'listBoxHeader',
        'listBoxItem',
        'listBoxItemIcon',
        'listBoxItemText',
        'popover',
        'selectValue',
        'label',
        'description',
        'error',
    ],
    base: {
        root: {
            boxSizing: 'border-box',
            display: 'grid',
            gridTemplateRows: '[1fr auto 1fr]',
        },
        button: {
            fontSize: '[inherit]',
            fontFamily: 'body',
            fontWeight: 'medium',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '[fit-content]',
            cursor: 'pointer',
            border: '1px solid',
            gap: '1',
            transition: '[background-color 250ms, color 250ms, opacity 250ms]',
            _pressed: {
                borderColor: 'accent.secondary',
            },
            _disabled: {
                cursor: 'not-allowed',
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
                color: 'gray.80',
            },
            _hover: {
                _notDisabled: {
                    borderColor: 'green.100',
                },
            },
            _groupInvalid: {
                borderColor: 'error-red.100',
                color: 'error-red.100',
                _dark: {
                    color: 'error-red.60',
                },
            },
            // default light styles
            backgroundColor: '[white]',
            borderColor: '[white]',

            color: 'text.primary',

            _dark: {
                backgroundColor: 'background',
                borderColor: 'background',
                color: 'text.primary',
                _pressed: {
                    borderColor: 'accent.secondary',
                },
                _disabled: {
                    backgroundColor: 'gray.20',
                    borderColor: 'gray.40',
                    color: 'gray.80',
                },
            },
        },
        popover: {
            marginTop: '0',
            // keep these?:
            '&[data-placement=top]': {
                paddingBottom: '[1px]', // offset to show button's border
            },
            '&[data-placement=bottom]': {
                paddingTop: '[1px]', // offset to show button's border
            },
            color: 'text.primary',
        },
        listBox: {
            backgroundColor: '[white]',
            display: 'grid',
            // This min and the `listBoxItem` `paddingInlineEnd` need to visually match
            gridTemplateAreas: '"icon text"',
            gridTemplateColumns: '[minmax(8px, min-content) auto]',
            minWidth: '[100%]',
            padding: '0',
            justifySelf: 'center',
            _dark: {
                backgroundColor: 'background',
            },
            '[data-placement=top] &': {
                borderTopRadius: 'md',
            },
            '[data-placement=bottom] &': {
                borderBottomRadius: 'md',
            },
        },
        listBoxSection: {
            // pass through subgrid
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            gridColumn: 'icon / text',
            minWidth: '[100%]',
        },
        listBoxItem: {
            // subgrid
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            gridColumn: 'icon / text',
            alignItems: 'center',
            gap: '[8px]',
            padding: '[8px]',
            // this needs to visually match the `listBox` grid collapsing (which will visually be the left padding)
            paddingInlineEnd: '[12px]',
            cursor: 'pointer',

            // this makes all items have rounded hover and focus
            borderRadius: 'md',

            _active: {
                backgroundColor: 'cream.100', // form/menu/selected/bg
                _dark: {
                    backgroundColor: 'extra-dark-teal.100', // form/menu/selected/bg
                },
            },
            _hover: {
                backgroundColor: 'dark-teal.10', // form/menu/hovered/bg
                _dark: {
                    backgroundColor: 'cream.10', // form/menu/hovered/bg
                },
            },
        },
        listBoxItemIcon: {
            gridColumn: 'icon',
            justifySelf: 'center',
            '& svg': {
                width: '[100%]',
                height: '[100%]',
            },
        },
        listBoxItemText: {
            gridColumn: 'text',
        },
        arrowIcon: {
            color: 'accent.secondary',
            pointerEvents: 'none',
            transition: '[transform 250ms]',
            _groupDisabled: {
                color: 'gray.80',
            },
            _groupOpen: {
                transform: 'rotate(180deg)',
            },
        },
        selectValue: {
            display: 'flex',
            gap: '[8px]',
            alignItems: 'center',
        },
        listBoxHeader: {
            fontSize: 'md',
            border: '[none]',
            padding: '[8px 16px]',
            gridColumn: 'icon / text',
        },
        label: {},
        description: {},
        error: {},
    },
    variants: {
        fullWidth: {
            false: {},
            true: {
                root: {
                    width: '[100%]',
                },
                button: {
                    width: '[100%]',
                },
            },
        },
        popoverAutoWidth: {
            true: {
                popover: {
                    minWidth: 'var(--trigger-width)',
                },
            },
        },
        variant: {
            contained: {}, // default
            outlined: {
                button: {
                    border: '1px solid',
                    borderColor: 'dark-teal.20',
                    _dark: {
                        borderColor: 'cream.30',
                    },
                },
            },
        },
        shape: {
            box: {
                button: {
                    borderRadius: 'sm',
                },
                popover: {
                    paddingInline: '[4px]',
                },
            },
            rounded: {
                button: {
                    borderRadius: 'full',
                },
                popover: {
                    paddingInline: '[18px]', // 16px?
                },
            },
        },
        size: {
            small: {
                root: {
                    fontSize: '[12px]', // todo: add fontsize for xs
                },
                button: {
                    padding: '[8px 20px]',
                },
                listBoxItem: {
                    fontSize: '[12px]', // todo: add fontsize for xs
                    lineHeight: '[14px]',
                },
                listBoxItemIcon: {
                    width: '[16px]',
                    height: '[16px]',
                },
            },
            medium: {
                root: {
                    fontSize: 'sm',
                },
                button: {
                    padding: '[8px 24px]',
                },
                listBoxItem: {
                    fontSize: 'sm',
                    lineHeight: '[22px]',
                },
                listBoxItemIcon: {
                    width: '[20px]',
                    height: '[20px]',
                },
            },
            large: {
                root: {
                    fontSize: 'md',
                },
                button: {
                    padding: '[16px 24px]',
                },
                listBoxItem: {
                    fontSize: 'md',
                    lineHeight: '[24px]',
                },
                listBoxItemIcon: {
                    width: '[24px]',
                    height: '[24px]',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'outlined',
        shape: 'box',
        size: 'medium',
        popoverAutoWidth: true,
    },
});

type SelectRecipeProps = RecipeVariantProps<typeof selectRecipe>;

export { selectRecipe, selectRecipe as default };
export type { SelectRecipeProps };
