
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Sections'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Menu supports sections with headings in order to group items. Sections can be used by wrapping groups of MenuItems in a MenuSection component. A Header element may also be included to label the section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Actions">Actions</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuSection>
                    <varnishUi.MenuHeader>Styles</varnishUi.MenuHeader>
                    <varnishUi.MenuItem>Bold</varnishUi.MenuItem>
                    <varnishUi.MenuItem>Underline</varnishUi.MenuItem>
                </varnishUi.MenuSection>
                <varnishUi.MenuSection>
                    <varnishUi.MenuHeader>Align</varnishUi.MenuHeader>
                    <varnishUi.MenuItem>Left</varnishUi.MenuItem>
                    <varnishUi.MenuItem>Middle</varnishUi.MenuItem>
                    <varnishUi.MenuItem>Right</varnishUi.MenuItem>
                </varnishUi.MenuSection>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;