import {
    TextAreaProps as AriaTextAreaProps,
    TextFieldProps as AriaTextFieldProps,
    TextField as AriaTextField,
    ValidationResult,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { textAreaRecipe, TextAreaRecipeProps } from './textArea.styles';
import { Label } from '@/components/field/label/Label';
import { FieldError } from '@/components/field/fieldError/FieldError';
import { childFocusRing } from '@/components/shared/focusRing.styles';
import { Description } from '../description/Description';
import TextAreaControl from './TextAreaControl';

type TextAreaProps = {
    children?: string;
    minRows?: number;
    maxRows?: number;
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    className?: string;
    textAreaClassName?: string;
    growContainerClassName?: string;
    labelClassName?: string;
    descriptionClassName?: string;
    errorClassName?: string;
} & AriaTextAreaProps &
    AriaTextFieldProps &
    TextAreaRecipeProps;

const TextArea = ({
    minRows = 2,
    maxRows = 3,
    label,
    description,
    errorMessage,
    className,
    textAreaClassName,
    growContainerClassName,
    labelClassName,
    descriptionClassName,
    errorClassName,
    isDisabled,
    ...rest
}: TextAreaProps) => {
    const [variantProps, localProps] = textAreaRecipe.splitVariantProps(rest);
    const recipeClassNames = textAreaRecipe(variantProps);
    const { size } = rest;

    return (
        <AriaTextField
            isDisabled={isDisabled}
            className={cx('group', recipeClassNames.root, className)}
            {...localProps}>
            {label ? (
                <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                    {label}
                </Label>
            ) : null}

            <TextAreaControl
                includeTextField={false} // we provide the textfield parent component.
                minRows={minRows}
                maxRows={maxRows}
                className={cx(css(childFocusRing), recipeClassNames.displayContainer)}
                textAreaClassName={textAreaClassName}
                growContainerClassName={growContainerClassName}
            />
            {description ? (
                <Description
                    size={size}
                    className={cx(recipeClassNames.description, descriptionClassName)}>
                    {description}
                </Description>
            ) : null}
            <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                {errorMessage}
            </FieldError>
        </AriaTextField>
    );
};

export { TextArea };
export type { TextAreaProps };
