import * as React from 'react';

import {
    SelectProps as AriaSelectProps,
    Select as AriaSelect,
    ValidationResult,
} from 'react-aria-components';

import { Placement } from '@react-types/overlays';

import { createContext } from 'react';

import { cx } from '@/utils/cx';

import SelectValue from '@/components/select/SelectValue';
import SelectPopover from '@/components/select/SelectPopover';
import SelectListBox from '@/components/select/SelectListBox';
import SelectListBoxItem from '@/components/select/SelectListBoxItem';
import selectRecipe, { SelectRecipeProps } from '@/components/select/select.styles';
import { Label } from '@/components/field/label/Label';
import { Description } from '@/components/field/description/Description';
import { FieldError } from '@/components/field/fieldError/FieldError';
import SelectButton from '@/components/select/SelectButton';

export const SelectVariantContext = createContext<SelectRecipeProps>(undefined);

type SelectProps = {
    className?: string;
    items?: string[];
    popoverPlacement?: Placement;
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    buttonClassName?: string;
    descriptionClassName?: string;
    labelClassName?: string;
    popoverClassName?: string;
    listBoxItemClassName?: string;
    errorClassName?: string;
} & AriaSelectProps<HTMLDivElement> &
    SelectRecipeProps;

const Select = ({
    className,
    items,
    popoverPlacement = 'bottom',
    label,
    description,
    errorMessage,
    buttonClassName,
    descriptionClassName,
    labelClassName,
    popoverClassName,
    listBoxItemClassName,
    errorClassName,
    children,
    ...rest
}: React.PropsWithChildren<SelectProps>) => {
    const [open, setOpen] = React.useState(false);
    const [variantProps, localProps] = selectRecipe.splitVariantProps(rest);
    const recipeClassNames = selectRecipe(variantProps);
    const { size } = rest;

    return (
        <AriaSelect
            {...localProps}
            isOpen={open}
            onOpenChange={setOpen}
            className={cx('group', recipeClassNames.root, className)}>
            <SelectVariantContext.Provider value={variantProps}>
                <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                    {label}
                </Label>
                <SelectButton {...variantProps} className={buttonClassName}>
                    <SelectValue />
                    <ArrowDropDownIcon aria-hidden className={cx(recipeClassNames.arrowIcon)} />
                </SelectButton>
                <Description
                    size={size}
                    className={cx(recipeClassNames.description, descriptionClassName)}>
                    {description}
                </Description>
                <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                    {errorMessage}
                </FieldError>
                <SelectPopover
                    {...variantProps}
                    placement={popoverPlacement}
                    offset={0}
                    className={popoverClassName}>
                    <SelectListBox {...variantProps} className={cx(recipeClassNames.listBox)}>
                        {children}
                        {items?.map((item: string) => (
                            <SelectListBoxItem
                                key={item}
                                {...variantProps}
                                text={item}
                                className={listBoxItemClassName}
                            />
                        ))}
                    </SelectListBox>
                </SelectPopover>
            </SelectVariantContext.Provider>
        </AriaSelect>
    );
};

const ArrowDropDownIcon = ({ className }: { className: string }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="arrow_drop_down">
            <path id="Vector" d="M7 9.5L12 14.5L17 9.5H7Z" fill="currentColor" />
        </g>
    </svg>
);

export { Select };
export type { SelectProps };
