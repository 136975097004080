import {
    MenuSection as AriaMenuSection,
    type MenuSectionProps as AriaMenuSectionProps,
} from 'react-aria-components';

import { PropsWithChildren } from 'react';

import { menuRecipe, MenuRecipeProps } from './menu.styles';
import { cx } from '@/utils/cx';

type MenuSelectionProps<T = object> = {
    children?: React.ReactNode;
    className?: string;
} & AriaMenuSectionProps<T> &
    MenuRecipeProps;

const MenuSection = ({ className, children, ...rest }: PropsWithChildren<MenuSelectionProps>) => {
    const [variantProps, localProps] = menuRecipe.splitVariantProps(rest);
    const recipeClassNames = menuRecipe(variantProps);

    return (
        <AriaMenuSection {...localProps} className={cx(recipeClassNames.menuSection, className)}>
            {children}
        </AriaMenuSection>
    );
};

export { MenuSection };
export type { MenuSelectionProps };
