import {
    SubmenuTrigger as AriaSubmenuTrigger,
    type SubmenuTriggerProps as AriaSubmenuTriggerProps,
} from 'react-aria-components';
import { PropsWithChildren, ReactNode } from 'react';

type SubmenuTriggerProps = {
    children?: ReactNode;
} & AriaSubmenuTriggerProps;

const SubmenuTrigger = ({ children, ...rest }: PropsWithChildren<SubmenuTriggerProps>) => {
    return <AriaSubmenuTrigger {...rest}>{children}</AriaSubmenuTrigger>;
};

export { SubmenuTrigger };
export type { SubmenuTriggerProps };
