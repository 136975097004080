
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The IconButton offers many colors (the same as Button), `}<inlineCode parentName="p">{`primary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{`, `}<inlineCode parentName="p">{`info`}</inlineCode>{`, `}<inlineCode parentName="p">{`warning`}</inlineCode>{`, `}<inlineCode parentName="p">{`error`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="column" spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="contained" color="primary">
                <varnishUi.Icons.Spark />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" color="secondary">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" color="tertiary">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" color="info">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" color="warning">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" color="error">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="outlined" color="primary">
                <varnishUi.Icons.Spark />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" color="secondary">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" color="tertiary">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" color="info">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" color="warning">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" color="error">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="default" color="primary">
                <varnishUi.Icons.Spark />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="default" color="secondary">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="default" color="tertiary">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="default" color="info">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="default" color="warning">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="default" color="error">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;