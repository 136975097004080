
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A basic example of how to render a Card component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Card>
        <varnishUi.CardHeader>
            <p>Hello World</p>
        </varnishUi.CardHeader>
        <varnishUi.CardBody>
            <p>benevolent</p>
            <p>adjective</p>
            <p>well meaning and kindly.</p>
        </varnishUi.CardBody>
        <varnishUi.CardFooter>
            <varnishUi.Button>Learn More</varnishUi.Button>
        </varnishUi.CardFooter>
    </varnishUi.Card>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;