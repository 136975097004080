import {
    MenuItem as AriaMenuItem,
    type MenuItemProps as AriaMenuItemProps,
} from 'react-aria-components';

import { PropsWithChildren } from 'react';

import { css } from '@allenai/varnish-panda-runtime/css';

import { menuRecipe, MenuRecipeProps } from './menu.styles';
import { cx } from '@/utils/cx';
import focusRing from '../shared/focusRing.styles';

type MenuItemProps<T = object> = {
    children?: React.ReactNode;
    className?: string;
} & AriaMenuItemProps<T> &
    MenuRecipeProps;

const MenuItem = ({ className, children, ...rest }: PropsWithChildren<MenuItemProps>) => {
    const [variantProps, localProps] = menuRecipe.splitVariantProps(rest);
    const recipeClassNames = menuRecipe(variantProps);

    return (
        <AriaMenuItem
            {...localProps}
            className={cx(css(focusRing), recipeClassNames.menuItem, className)}>
            {children}
        </AriaMenuItem>
    );
};

export { MenuItem };
export type { MenuItemProps };
