
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 11,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <p><inlineCode parentName="p">{`color`}</inlineCode>{` controls the fill color and `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` controls the size.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

const CustomAriaMenu = styled(varnishUi.AriaMenu)\`
  √
\`;

const CustomMenuItem = styled(varnishUi.MenuItem)\`
    color: red;
    font-size: 32px;
\`;

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Menu">☰</varnishUi.Button>
        <varnishUi.Popover>
            <CustomAriaMenu>
                <CustomMenuItem>Open</CustomMenuItem>
                <CustomMenuItem>Rename…</CustomMenuItem>
                <CustomMenuItem>Duplicate</CustomMenuItem>
                <CustomMenuItem>Share…</CustomMenuItem>
                <CustomMenuItem>Delete…</CustomMenuItem>
            </CustomAriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;