import { useState } from 'react';

import {
    TextArea as AriaTextArea,
    TextField as AriaTextField,
    TextFieldProps as AriaTextFieldProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { textAreaRecipe } from './textArea.styles';
import { type TextAreaProps } from './TextArea';

// the grow container can be either a div (when its a `<TextArea>`) or
// a `<TextField>` like when its a `<Prompt>`
type GrowContainerElementType = React.ComponentType<AriaTextFieldProps> | 'div';

type TextAreaControlProps = {
    includeTextField?: boolean;
} & Omit<
    TextAreaProps,
    | 'label'
    | 'description'
    | 'errorMessage'
    | 'labelClassName'
    | 'descriptionClassName'
    | 'errorClassName'
>;

const TextAreaControl = ({
    // internal
    includeTextField = true, // if we are using the base <TextArea> we want the Field too, but if we provide a Field, we want just a div
    //
    minRows = 1,
    maxRows = Infinity,
    onChange,
    // classes
    className,
    growContainerClassName,
    textAreaClassName,
    ...rest
}: TextAreaControlProps) => {
    const [variantProps, localProps] = textAreaRecipe.splitVariantProps(rest);
    const recipeClassNames = textAreaRecipe(variantProps);

    // This could be a polymorphic component, but just using a flag to choose wrapper
    // this is written this way, because typescript can't quite figure out the props
    // This method is used to swap props depending on intent.
    let GrowContainerElement: GrowContainerElementType = 'div';
    let growContainerProps = {};
    let textAreaProps = localProps;

    if (includeTextField) {
        GrowContainerElement = AriaTextField;
        growContainerProps = localProps;
        textAreaProps = {};
    }

    // The container needs to grow, and have a height between minRows and maxRows. It also needs to allow text-overflow.
    // To acheive this, we give it an example string that satisfies those rules so it can calculate its correct height.
    // More details here: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    const [heightCalculationString, setHeightCalculationString] = useState(
        adjustStringToRowLimits('', minRows, maxRows)
    );

    return (
        <GrowContainerElement
            data-replicated-value={heightCalculationString}
            className={cx(
                className,
                recipeClassNames.growContainer,
                recipeClassNames.shared,
                growContainerClassName
            )}
            {...growContainerProps}>
            <AriaTextArea
                onChange={(event) => {
                    const expectedVisibleText = adjustStringToRowLimits(
                        event.target.value,
                        minRows,
                        maxRows
                    );
                    setHeightCalculationString(expectedVisibleText);

                    // if an onChange function was passed in, then evoke it here
                    if (onChange) {
                        onChange(event);
                    }
                }}
                className={cx(
                    recipeClassNames.textArea,
                    recipeClassNames.shared,
                    textAreaClassName
                )}
                {...textAreaProps}
            />
        </GrowContainerElement>
    );
};

// this function adds/removes newlines from a string until the number of rows is between minRows and maxRows
const adjustStringToRowLimits = (input: string, minRows: number, maxRows: number): string => {
    const rows = input.split('\n');
    for (let i = rows.length; i < minRows; i++) {
        rows.push('');
    }
    return rows.slice(0, maxRows).join('\n');
};

export { TextAreaControl, TextAreaControl as default };
export type { TextAreaControlProps };
