
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Video'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Example of a card using an video or iframe to reinforce the content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Card>
        <varnishUi.CardHeader>
            <p>This is a video</p>
        </varnishUi.CardHeader>
        <varnishUi.CardMedia>
            <iframe
                src="https://www.datocms-assets.com/64837/1722056285-ai2_hero_short_3s_4k_v01-2160p.mp4"
                style={{
                    width: '100%',
                    height: '100%',
                    aspectRatio: '16 / 9',
                    border: 'none',
                    display: 'block',
                }}
                allow="autoplay"
                allowFullScreen></iframe>
        </varnishUi.CardMedia>
        <varnishUi.CardBody>
            <p>Video Description.</p>
        </varnishUi.CardBody>
        <varnishUi.CardFooter>
            <varnishUi.Button>Learn More</varnishUi.Button>
        </varnishUi.CardFooter>
    </varnishUi.Card>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;