const varnishPandaTheme = {
  "breakpoints": {
    "xs": "0",
    "sm": "37.5rem",
    "md": "56.25rem",
    "lg": "75rem",
    "xl": "96rem"
  },
  "tokens": {
    "colors": {
      "extra-dark-teal": {
        "4": {
          "value": "rgba(3, 38, 41, 0.04)"
        },
        "10": {
          "value": "rgba(3, 38, 41, 0.1)"
        },
        "20": {
          "value": "rgba(3, 38, 41, 0.2)"
        },
        "30": {
          "value": "rgba(3, 38, 41, 0.3)"
        },
        "40": {
          "value": "rgba(3, 38, 41, 0.4)"
        },
        "50": {
          "value": "rgba(3, 38, 41, 0.5)"
        },
        "60": {
          "value": "rgba(3, 38, 41, 0.6)"
        },
        "70": {
          "value": "rgba(3, 38, 41, 0.7)"
        },
        "80": {
          "value": "rgba(3, 38, 41, 0.8)"
        },
        "90": {
          "value": "rgba(3, 38, 41, 0.9)"
        },
        "100": {
          "value": "rgba(3, 38, 41, 1)"
        }
      },
      "dark-teal": {
        "10": {
          "value": "rgba(10, 50, 53, 0.1)"
        },
        "20": {
          "value": "rgba(10, 50, 53, 0.2)"
        },
        "30": {
          "value": "rgba(10, 50, 53, 0.3)"
        },
        "40": {
          "value": "rgba(10, 50, 53, 0.4)"
        },
        "50": {
          "value": "rgba(10, 50, 53, 0.5)"
        },
        "60": {
          "value": "rgba(10, 50, 53, 0.6)"
        },
        "70": {
          "value": "rgba(10, 50, 53, 0.7)"
        },
        "80": {
          "value": "rgba(10, 50, 53, 0.8)"
        },
        "90": {
          "value": "rgba(10, 50, 53, 0.9)"
        },
        "100": {
          "value": "rgba(10, 50, 53, 1)"
        }
      },
      "cream": {
        "4": {
          "value": "rgba(250, 242, 233, 0.04)"
        },
        "10": {
          "value": "rgba(250, 242, 233, 0.1)"
        },
        "20": {
          "value": "rgba(250, 242, 233, 0.2)"
        },
        "30": {
          "value": "rgba(250, 242, 233, 0.3)"
        },
        "40": {
          "value": "rgba(250, 242, 233, 0.4)"
        },
        "50": {
          "value": "rgba(250, 242, 233, 0.5)"
        },
        "60": {
          "value": "rgba(250, 242, 233, 0.6)"
        },
        "70": {
          "value": "rgba(250, 242, 233, 0.7)"
        },
        "80": {
          "value": "rgba(250, 242, 233, 0.8)"
        },
        "90": {
          "value": "rgba(250, 242, 233, 0.9)"
        },
        "100": {
          "value": "rgba(250, 242, 233, 1)"
        }
      },
      "info-blue": {
        "20": {
          "value": "rgba(212, 231, 252, 1)"
        },
        "40": {
          "value": "rgba(170, 207, 249, 1)"
        },
        "60": {
          "value": "rgba(127, 184, 245, 1)"
        },
        "80": {
          "value": "rgba(85, 160, 242, 1)"
        },
        "100": {
          "value": "rgba(42, 136, 239, 1)"
        }
      },
      "error-red": {
        "20": {
          "value": "rgba(255, 218, 218, 1)"
        },
        "40": {
          "value": "rgba(254, 181, 181, 1)"
        },
        "60": {
          "value": "rgba(254, 144, 143, 1)"
        },
        "80": {
          "value": "rgba(253, 107, 106, 1)"
        },
        "100": {
          "value": "rgba(253, 70, 69, 1)"
        }
      },
      "success-green": {
        "20": {
          "value": "rgba(221, 235, 215, 1)"
        },
        "40": {
          "value": "rgba(187, 215, 174, 1)"
        },
        "60": {
          "value": "rgba(152, 196, 134, 1)"
        },
        "80": {
          "value": "rgba(118, 176, 93, 1)"
        },
        "100": {
          "value": "rgba(84, 156, 53, 1)"
        }
      },
      "warning-orange": {
        "20": {
          "value": "rgba(255, 237, 210, 1)"
        },
        "40": {
          "value": "rgba(255, 218, 164, 1)"
        },
        "60": {
          "value": "rgba(255, 200, 119, 1)"
        },
        "80": {
          "value": "rgba(255, 181, 73, 1)"
        },
        "100": {
          "value": "rgba(255, 163, 28, 1)"
        }
      },
      "teal": {
        "10": {
          "value": "rgba(16, 82, 87, 0.1)"
        },
        "20": {
          "value": "rgba(16, 82, 87, 0.2)"
        },
        "30": {
          "value": "rgba(16, 82, 87, 0.3)"
        },
        "40": {
          "value": "rgba(16, 82, 87, 0.4)"
        },
        "50": {
          "value": "rgba(16, 82, 87, 0.5)"
        },
        "60": {
          "value": "rgba(16, 82, 87, 0.6)"
        },
        "70": {
          "value": "rgba(16, 82, 87, 0.7)"
        },
        "80": {
          "value": "rgba(16, 82, 87, 0.8)"
        },
        "90": {
          "value": "rgba(16, 82, 87, 0.9)"
        },
        "100": {
          "value": "rgba(16, 82, 87, 1)"
        }
      },
      "pink": {
        "10": {
          "value": "rgba(253, 238, 245, 1)"
        },
        "20": {
          "value": "rgba(252, 220, 235, 1)"
        },
        "30": {
          "value": "rgba(251, 203, 225, 1)"
        },
        "40": {
          "value": "rgba(249, 186, 215, 1)"
        },
        "50": {
          "value": "rgba(247, 168, 205, 1)"
        },
        "60": {
          "value": "rgba(246, 151, 196, 1)"
        },
        "70": {
          "value": "rgba(245, 134, 186, 1)"
        },
        "80": {
          "value": "rgba(243, 117, 176, 1)"
        },
        "90": {
          "value": "rgba(242, 99, 166, 1)"
        },
        "100": {
          "value": "rgba(240, 82, 156, 1)"
        }
      },
      "purple": {
        "10": {
          "value": "rgba(247, 232, 253, 1)"
        },
        "20": {
          "value": "rgba(239, 209, 250, 1)"
        },
        "30": {
          "value": "rgba(232, 187, 248, 1)"
        },
        "40": {
          "value": "rgba(224, 164, 246, 1)"
        },
        "50": {
          "value": "rgba(216, 141, 243, 1)"
        },
        "60": {
          "value": "rgba(208, 118, 241, 1)"
        },
        "70": {
          "value": "rgba(200, 95, 239, 1)"
        },
        "80": {
          "value": "rgba(193, 73, 237, 1)"
        },
        "90": {
          "value": "rgba(185, 50, 234, 1)"
        },
        "100": {
          "value": "rgba(177, 27, 232, 1)"
        }
      },
      "green": {
        "10": {
          "value": "rgba(231, 250, 243, 1)"
        },
        "20": {
          "value": "rgba(207, 245, 232, 1)"
        },
        "30": {
          "value": "rgba(183, 239, 221, 1)"
        },
        "40": {
          "value": "rgba(159, 234, 209, 1)"
        },
        "50": {
          "value": "rgba(135, 229, 197, 1)"
        },
        "60": {
          "value": "rgba(111, 224, 186, 1)"
        },
        "70": {
          "value": "rgba(87, 219, 175, 1)"
        },
        "80": {
          "value": "rgba(63, 213, 163, 1)"
        },
        "90": {
          "value": "rgba(39, 208, 156, 1)"
        },
        "100": {
          "value": "rgba(15, 203, 140, 1)"
        }
      },
      "gray": {
        "10": {
          "value": "rgba(235, 235, 235, 1)"
        },
        "20": {
          "value": "rgba(214, 214, 214, 1)"
        },
        "30": {
          "value": "rgba(194, 194, 194, 1)"
        },
        "40": {
          "value": "rgba(174, 174, 174, 1)"
        },
        "50": {
          "value": "rgba(153, 153, 153, 1)"
        },
        "60": {
          "value": "rgba(133, 133, 133, 1)"
        },
        "70": {
          "value": "rgba(113, 113, 113, 1)"
        },
        "80": {
          "value": "rgba(93, 93, 93, 1)"
        },
        "90": {
          "value": "rgba(72, 72, 72, 1)"
        },
        "100": {
          "value": "rgba(52, 52, 52, 1)"
        }
      },
      "white": {
        "value": "rgba(255, 255, 255, 1)"
      },
      "black": {
        "value": "rgba(0, 0, 0, 1)"
      },
      "R1": {
        "value": "rgba(255, 242, 242, 1)"
      },
      "R2": {
        "value": "rgba(255, 225, 224, 1)"
      },
      "R3": {
        "value": "rgba(253, 193, 192, 1)"
      },
      "R4": {
        "value": "rgba(255, 159, 158, 1)"
      },
      "R5": {
        "value": "rgba(249, 128, 127, 1)"
      },
      "R6": {
        "value": "rgba(247, 96, 95, 1)"
      },
      "R7": {
        "value": "rgba(231, 80, 79, 1)"
      },
      "R8": {
        "value": "rgba(214, 63, 63, 1)"
      },
      "R9": {
        "value": "rgba(191, 45, 45, 1)"
      },
      "R10": {
        "value": "rgba(147, 34, 34, 1)"
      },
      "O1": {
        "value": "rgba(255, 249, 232, 1)"
      },
      "O2": {
        "value": "rgba(255, 241, 196, 1)"
      },
      "O3": {
        "value": "rgba(255, 227, 148, 1)"
      },
      "O4": {
        "value": "rgba(255, 212, 93, 1)"
      },
      "O5": {
        "value": "rgba(255, 199, 46, 1)"
      },
      "O6": {
        "value": "rgba(255, 187, 0, 1)"
      },
      "O7": {
        "value": "rgba(255, 162, 0, 1)"
      },
      "O8": {
        "value": "rgba(255, 145, 0, 1)"
      },
      "O9": {
        "value": "rgba(221, 101, 2, 1)"
      },
      "O10": {
        "value": "rgba(169, 64, 6, 1)"
      },
      "G1": {
        "value": "rgba(228, 255, 247, 1)"
      },
      "G2": {
        "value": "rgba(193, 247, 230, 1)"
      },
      "G3": {
        "value": "rgba(152, 234, 208, 1)"
      },
      "G4": {
        "value": "rgba(112, 221, 186, 1)"
      },
      "G5": {
        "value": "rgba(71, 207, 164, 1)"
      },
      "G6": {
        "value": "rgba(30, 194, 142, 1)"
      },
      "G7": {
        "value": "rgba(20, 168, 125, 1)"
      },
      "G8": {
        "value": "rgba(10, 143, 107, 1)"
      },
      "G9": {
        "value": "rgba(0, 117, 90, 1)"
      },
      "G10": {
        "value": "rgba(0, 83, 64, 1)"
      },
      "T1": {
        "value": "rgba(230, 253, 254, 1)"
      },
      "T2": {
        "value": "rgba(198, 243, 246, 1)"
      },
      "T3": {
        "value": "rgba(154, 231, 236, 1)"
      },
      "T4": {
        "value": "rgba(110, 220, 227, 1)"
      },
      "T5": {
        "value": "rgba(66, 208, 217, 1)"
      },
      "T6": {
        "value": "rgba(22, 196, 207, 1)"
      },
      "T7": {
        "value": "rgba(15, 169, 182, 1)"
      },
      "T8": {
        "value": "rgba(7, 142, 158, 1)"
      },
      "T9": {
        "value": "rgba(0, 115, 133, 1)"
      },
      "T10": {
        "value": "rgba(0, 71, 82, 1)"
      },
      "A1": {
        "value": "rgba(242, 252, 255, 1)"
      },
      "A2": {
        "value": "rgba(224, 249, 255, 1)"
      },
      "A3": {
        "value": "rgba(181, 240, 255, 1)"
      },
      "A4": {
        "value": "rgba(133, 233, 255, 1)"
      },
      "A5": {
        "value": "rgba(77, 225, 255, 1)"
      },
      "A6": {
        "value": "rgba(0, 213, 255, 1)"
      },
      "A7": {
        "value": "rgba(0, 193, 232, 1)"
      },
      "A8": {
        "value": "rgba(1, 162, 202, 1)"
      },
      "A9": {
        "value": "rgba(2, 120, 167, 1)"
      },
      "A10": {
        "value": "rgba(5, 73, 118, 1)"
      },
      "B1": {
        "value": "rgba(240, 247, 255, 1)"
      },
      "B2": {
        "value": "rgba(213, 234, 254, 1)"
      },
      "B3": {
        "value": "rgba(128, 189, 255, 1)"
      },
      "B4": {
        "value": "rgba(47, 133, 247, 1)"
      },
      "B5": {
        "value": "rgba(35, 118, 229, 1)"
      },
      "B6": {
        "value": "rgba(38, 94, 212, 1)"
      },
      "B7": {
        "value": "rgba(26, 76, 174, 1)"
      },
      "B8": {
        "value": "rgba(27, 69, 150, 1)"
      },
      "B9": {
        "value": "rgba(29, 61, 126, 1)"
      },
      "B10": {
        "value": "rgba(34, 51, 103, 1)"
      },
      "P1": {
        "value": "rgba(248, 247, 253, 1)"
      },
      "P2": {
        "value": "rgba(230, 227, 247, 1)"
      },
      "P3": {
        "value": "rgba(207, 201, 241, 1)"
      },
      "P4": {
        "value": "rgba(183, 175, 235, 1)"
      },
      "P5": {
        "value": "rgba(160, 148, 228, 1)"
      },
      "P6": {
        "value": "rgba(136, 122, 222, 1)"
      },
      "P7": {
        "value": "rgba(114, 101, 193, 1)"
      },
      "P8": {
        "value": "rgba(92, 80, 164, 1)"
      },
      "P9": {
        "value": "rgba(70, 59, 135, 1)"
      },
      "P10": {
        "value": "rgba(39, 31, 85, 1)"
      },
      "M1": {
        "value": "rgba(253, 247, 252, 1)"
      },
      "M2": {
        "value": "rgba(246, 223, 243, 1)"
      },
      "M3": {
        "value": "rgba(239, 192, 232, 1)"
      },
      "M4": {
        "value": "rgba(231, 162, 222, 1)"
      },
      "M5": {
        "value": "rgba(224, 131, 211, 1)"
      },
      "M6": {
        "value": "rgba(216, 100, 201, 1)"
      },
      "M7": {
        "value": "rgba(190, 84, 176, 1)"
      },
      "M8": {
        "value": "rgba(164, 67, 151, 1)"
      },
      "M9": {
        "value": "rgba(138, 51, 126, 1)"
      },
      "M10": {
        "value": "rgba(101, 41, 93, 1)"
      },
      "N1": {
        "value": "rgba(255, 255, 255, 1)"
      },
      "N2": {
        "value": "rgba(248, 249, 250, 1)"
      },
      "N3": {
        "value": "rgba(240, 244, 247, 1)"
      },
      "N4": {
        "value": "rgba(232, 236, 242, 1)"
      },
      "N5": {
        "value": "rgba(213, 218, 227, 1)"
      },
      "N6": {
        "value": "rgba(174, 183, 196, 1)"
      },
      "N7": {
        "value": "rgba(140, 150, 163, 1)"
      },
      "N8": {
        "value": "rgba(97, 108, 122, 1)"
      },
      "N9": {
        "value": "rgba(71, 81, 92, 1)"
      },
      "N10": {
        "value": "rgba(48, 57, 69, 1)"
      },
      "transparent": {
        "value": "rgba(0, 0, 0, 0)"
      }
    },
    "fonts": {
      "body": {
        "value": "Manrope, Arial, sans-serif"
      },
      "heading": {
        "value": "Telegraf, Manrope, Arial, sans-serif"
      },
      "monospace": {
        "value": "'Space Mono', monospace"
      }
    },
    "fontSizes": {
      "4xl": {
        "value": "2.25rem"
      },
      "3xl": {
        "value": "2rem"
      },
      "2xl": {
        "value": "1.5rem"
      },
      "xl": {
        "value": "1.25rem"
      },
      "lg": {
        "value": "1.125rem"
      },
      "md": {
        "value": "1rem"
      },
      "sm": {
        "value": "0.875rem"
      }
    },
    "fontWeights": {
      "light": {
        "value": 300
      },
      "regular": {
        "value": 400
      },
      "medium": {
        "value": 500
      },
      "semiBold": {
        "value": 600
      },
      "bold": {
        "value": 700
      }
    },
    "spacing": {
      "0": {
        "value": "0px"
      },
      "1": {
        "value": "8px"
      },
      "2": {
        "value": "16px"
      },
      "3": {
        "value": "24px"
      },
      "4": {
        "value": "32px"
      },
      "5": {
        "value": "40px"
      },
      "6": {
        "value": "48px"
      },
      "7": {
        "value": "56px"
      },
      "8": {
        "value": "64px"
      },
      "10": {
        "value": "80px"
      },
      "11": {
        "value": "88px"
      },
      "12": {
        "value": "96px"
      },
      "13": {
        "value": "104px"
      },
      "14": {
        "value": "112px"
      },
      "15": {
        "value": "120px"
      },
      "16": {
        "value": "128px"
      },
      "baseSpacingValue": {
        "value": 8
      }
    },
    "radii": {
      "xs": {
        "value": "2px"
      },
      "sm": {
        "value": "4px"
      },
      "md": {
        "value": "8px"
      },
      "lg": {
        "value": "12px"
      },
      "xl": {
        "value": "16px"
      },
      "full": {
        "value": "9999px"
      }
    },
    "sizes": {
      "icon": {
        "xs": {
          "value": "1rem"
        },
        "sm": {
          "value": "1.5rem"
        },
        "md": {
          "value": "1.75rem"
        },
        "lg": {
          "value": "2rem"
        },
        "xl": {
          "value": "3rem"
        }
      }
    }
  },
  "semanticTokens": {
    "colors": {
      "background": {
        "DEFAULT": {
          "value": {
            "base": "colors.cream.100",
            "_dark": "colors.extra-dark-teal.100"
          }
        },
        "reversed": {
          "value": {
            "base": "colors.extra-dark-teal.100",
            "_dark": "colors.cream.100"
          }
        },
        "opacity-100": {
          "DEFAULT": {
            "value": {
              "base": "colors.cream.100",
              "_dark": "colors.extra-dark-teal.100"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.extra-dark-teal.100",
              "_dark": "colors.cream.100"
            }
          }
        },
        "opacity-10": {
          "reversed": {
            "value": {
              "base": "colors.extra-dark-teal.10",
              "_dark": "colors.cream.10"
            }
          },
          "DEFAULT": {
            "value": {
              "base": "colors.cream.10",
              "_dark": "colors.extra-dark-teal.10"
            }
          }
        },
        "opacity-4": {
          "DEFAULT": {
            "value": {
              "base": "colors.cream.4",
              "_dark": "colors.extra-dark-teal.4"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.extra-dark-teal.4",
              "_dark": "colors.cream.4"
            }
          }
        }
      },
      "text": {
        "DEFAULT": {
          "value": {
            "base": "colors.dark-teal.100",
            "_dark": "colors.cream.100"
          }
        },
        "reversed": {
          "value": {
            "base": "colors.cream.100",
            "_dark": "colors.dark-teal.100"
          }
        },
        "placeholder": {
          "value": {
            "base": "colors.gray.60",
            "_dark": "colors.cream.50"
          }
        },
        "disabled": {
          "value": "colors.gray.60"
        },
        "error": {
          "value": {
            "base": "colors.error-red.100",
            "_dark": "colors.error-red.60"
          }
        },
        "primary": {
          "DEFAULT": {
            "value": {
              "base": "colors.dark-teal.100",
              "_dark": "colors.cream.100"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.cream.100",
              "_dark": "colors.dark-teal.100"
            }
          }
        },
        "secondary": {
          "DEFAULT": {
            "value": {
              "base": "colors.extra-dark-teal.100",
              "_dark": "colors.gray.10"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.cream.100",
              "_dark": "colors.gray.80"
            }
          }
        }
      },
      "elements": {
        "primary": {
          "fill": {
            "value": "colors.pink.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.pink.80"
            },
            "stroke": {
              "value": "colors.pink.100"
            }
          },
          "contrast": {
            "value": "colors.extra-dark-teal.100"
          },
          "stroke": {
            "value": "colors.pink.60"
          },
          "contrast-reversed": {
            "value": "colors.extra-dark-teal.100"
          }
        },
        "secondary": {
          "fill": {
            "value": "colors.green.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.green.80"
            },
            "stroke": {
              "value": "colors.green.100"
            }
          },
          "contrast": {
            "value": "colors.extra-dark-teal.100"
          },
          "stroke": {
            "value": "colors.green.40"
          },
          "contrast-reversed": {
            "value": "colors.extra-dark-teal.100"
          }
        },
        "tertiary": {
          "fill": {
            "value": "colors.purple.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.purple.80"
            },
            "stroke": {
              "value": "colors.purple.100"
            }
          },
          "contrast": {
            "value": "colors.cream.100"
          },
          "stroke": {
            "value": "colors.purple.60"
          },
          "contrast-reversed": {
            "value": "colors.cream.100"
          }
        },
        "error": {
          "fill": {
            "value": "colors.error-red.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.error-red.80"
            },
            "stroke": {
              "value": "colors.error-red.100"
            }
          },
          "contrast": {
            "value": "colors.cream.100"
          },
          "stroke": {
            "value": "colors.error-red.60"
          },
          "contrast-reversed": {
            "value": "colors.cream.100"
          }
        },
        "warning": {
          "fill": {
            "value": "colors.warning-orange.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.warning-orange.80"
            },
            "stroke": {
              "value": "colors.warning-orange.100"
            }
          },
          "contrast": {
            "value": "colors.extra-dark-teal.100"
          },
          "stroke": {
            "value": "colors.warning-orange.60"
          },
          "contrast-reversed": {
            "value": "colors.extra-dark-teal.100"
          }
        },
        "confirmation": {
          "fill": {
            "value": "colors.success-green.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.success-green.80"
            },
            "stroke": {
              "value": "colors.success-green.100"
            }
          },
          "contrast": {
            "value": "colors.cream.100"
          },
          "stroke": {
            "value": "colors.success-green.60"
          },
          "contrast-reversed": {
            "value": "colors.cream.100"
          }
        },
        "information": {
          "fill": {
            "value": "colors.info-blue.100"
          },
          "hovered": {
            "fill": {
              "value": "colors.info-blue.80"
            },
            "stroke": {
              "value": "colors.info-blue.100"
            }
          },
          "contrast": {
            "value": "colors.extra-dark-teal.100"
          },
          "stroke": {
            "value": "colors.info-blue.60"
          },
          "contrast-reversed": {
            "value": "colors.extra-dark-teal.100"
          }
        },
        "overrides": {
          "form": {
            "checkbox": {
              "stroke": {
                "value": {
                  "base": "colors.teal.80",
                  "_dark": "colors.cream.80"
                }
              },
              "hovered": {
                "stroke": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                },
                "fill": {
                  "value": {
                    "base": "colors.cream.20",
                    "_dark": "colors.extra-dark-teal.20"
                  }
                }
              },
              "fill": {
                "value": {
                  "base": "colors.cream.10",
                  "_dark": "colors.extra-dark-teal.10"
                }
              },
              "error": {
                "fill": {
                  "value": "colors.error-red.100"
                },
                "stroke": {
                  "value": "colors.error-red.100"
                },
                "contrast": {
                  "value": "colors.cream.100"
                }
              },
              "checked": {
                "fill": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                },
                "contrast": {
                  "value": {
                    "base": "colors.cream.100",
                    "_dark": "colors.extra-dark-teal.100"
                  }
                }
              },
              "disabled": {
                "fill": {
                  "value": "colors.gray.20"
                },
                "contrast": {
                  "value": "colors.gray.80"
                },
                "stroke": {
                  "value": "colors.gray.40"
                }
              }
            },
            "input": {
              "disabled": {
                "fill": {
                  "value": "colors.gray.20"
                },
                "contrast": {
                  "value": "colors.gray.60"
                },
                "stroke": {
                  "value": "colors.gray.40"
                }
              },
              "error": {
                "fill": {
                  "value": {
                    "base": "colors.white",
                    "_dark": "colors.dark-teal.100"
                  }
                },
                "stroke": {
                  "value": "colors.error-red.80"
                },
                "contrast": {
                  "value": "colors.error-red.100"
                }
              },
              "fill": {
                "value": {
                  "base": "colors.white",
                  "_dark": "colors.dark-teal.100"
                }
              },
              "stroke": {
                "value": {
                  "base": "colors.extra-dark-teal.20",
                  "_dark": "colors.cream.10"
                }
              },
              "hovered": {
                "fill": {
                  "value": {
                    "base": "colors.white",
                    "_dark": "colors.dark-teal.100"
                  }
                },
                "stroke": {
                  "value": "colors.green.100"
                }
              },
              "contrast": {
                "value": {
                  "base": "colors.pink.100",
                  "_dark": "colors.green.100"
                }
              }
            },
            "radio": {
              "fill": {
                "value": {
                  "base": "colors.cream.10",
                  "_dark": "colors.extra-dark-teal.10"
                }
              },
              "stroke": {
                "value": {
                  "base": "colors.teal.80",
                  "_dark": "colors.cream.80"
                }
              },
              "hovered": {
                "fill": {
                  "value": {
                    "base": "colors.cream.20",
                    "_dark": "colors.extra-dark-teal.20"
                  }
                },
                "stroke": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                }
              },
              "error": {
                "fill": {
                  "value": "colors.error-red.100"
                },
                "stroke": {
                  "value": "colors.error-red.100"
                },
                "contrast": {
                  "value": "colors.cream.100"
                }
              },
              "disabled": {
                "fill": {
                  "value": "colors.gray.20"
                },
                "stroke": {
                  "value": "colors.gray.40"
                },
                "contrast": {
                  "value": "colors.gray.80"
                }
              },
              "checked": {
                "fill": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                },
                "contrast": {
                  "value": {
                    "base": "colors.cream.100",
                    "_dark": "colors.extra-dark-teal.100"
                  }
                }
              }
            },
            "switch": {
              "fill": {
                "value": {
                  "base": "colors.cream.10",
                  "_dark": "colors.extra-dark-teal.10"
                }
              },
              "stroke": {
                "value": {
                  "base": "colors.teal.80",
                  "_dark": "colors.cream.80"
                }
              },
              "hovered": {
                "fill": {
                  "value": {
                    "base": "colors.cream.20",
                    "_dark": "colors.extra-dark-teal.20"
                  }
                },
                "stroke": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                }
              },
              "checked": {
                "fill": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                },
                "contrast": {
                  "value": {
                    "base": "colors.cream.100",
                    "_dark": "colors.extra-dark-teal.100"
                  }
                }
              },
              "error": {
                "fill": {
                  "value": "colors.error-red.100"
                },
                "stroke": {
                  "value": "colors.error-red.100"
                },
                "contrast": {
                  "value": "colors.cream.100"
                }
              },
              "disabled": {
                "fill": {
                  "value": "colors.gray.20"
                },
                "stroke": {
                  "value": "colors.gray.40"
                },
                "contrast": {
                  "value": "colors.gray.80"
                }
              }
            },
            "slider": {
              "fill": {
                "value": {
                  "base": "colors.extra-dark-teal.10",
                  "_dark": "colors.cream.10"
                }
              },
              "contrast": {
                "value": {
                  "base": "colors.teal.80",
                  "_dark": "colors.cream.80"
                }
              },
              "hovered": {
                "fill": {
                  "value": {
                    "base": "colors.cream.40",
                    "_dark": "colors.extra-dark-teal.40"
                  }
                },
                "contrast": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                },
                "stroke": {
                  "value": {
                    "base": "colors.teal.100",
                    "_dark": "colors.cream.100"
                  }
                }
              },
              "error": {
                "fill": {
                  "value": {
                    "base": "colors.extra-dark-teal.10",
                    "_dark": "colors.cream.10"
                  }
                },
                "stroke": {
                  "value": "colors.error-red.100"
                },
                "contrast": {
                  "value": "colors.error-red.100"
                }
              },
              "disabled": {
                "fill": {
                  "value": "colors.gray.20"
                },
                "stroke": {
                  "value": "colors.gray.80"
                },
                "contrast": {
                  "value": "colors.gray.80"
                }
              },
              "stroke": {
                "value": {
                  "base": "colors.teal.100",
                  "_dark": "colors.cream.100"
                }
              }
            }
          },
          "button": {
            "text": {
              "hovered": {
                "fill": {
                  "value": {
                    "base": "colors.extra-dark-teal.4",
                    "_dark": "colors.cream.4"
                  }
                }
              }
            }
          }
        },
        "disabled": {
          "fill": {
            "value": "colors.gray.40"
          },
          "hovered": {
            "fill": {
              "value": "colors.gray.40"
            },
            "stroke": {
              "value": "colors.gray.40"
            }
          },
          "contrast": {
            "value": "colors.cream.100"
          },
          "stroke": {
            "value": "colors.gray.40"
          },
          "contrast-reversed": {
            "value": "colors.gray.80"
          }
        },
        "default": {
          "fill": {
            "value": {
              "base": "colors.teal.100",
              "_dark": "colors.cream.100"
            }
          },
          "hovered": {
            "fill": {
              "value": {
                "base": "colors.teal.80",
                "_dark": "colors.cream.80"
              }
            },
            "stroke": {
              "value": {
                "base": "colors.teal.100",
                "_dark": "colors.cream.100"
              }
            }
          },
          "contrast": {
            "value": {
              "base": "colors.cream.100",
              "_dark": "colors.extra-dark-teal.100"
            }
          },
          "stroke": {
            "value": {
              "base": "colors.teal.40",
              "_dark": "colors.cream.40"
            }
          },
          "contrast-reversed": {
            "value": {
              "base": "colors.extra-dark-teal.100",
              "_dark": "colors.cream.100"
            }
          }
        },
        "faded": {
          "fill": {
            "value": {
              "base": "colors.extra-dark-teal.10",
              "_dark": "colors.cream.10"
            }
          },
          "hovered": {
            "fill": {
              "value": {
                "base": "colors.extra-dark-teal.40",
                "_dark": "colors.cream.40"
              }
            },
            "stroke": {
              "value": {
                "base": "colors.extra-dark-teal.40",
                "_dark": "colors.cream.40"
              }
            }
          },
          "contrast-reversed": {
            "value": {
              "base": "colors.cream.100",
              "_dark": "colors.extra-dark-teal.100"
            }
          },
          "stroke": {
            "value": {
              "base": "colors.extra-dark-teal.20",
              "_dark": "colors.cream.20"
            }
          },
          "contrast": {
            "value": {
              "base": "colors.extra-dark-teal.100",
              "_dark": "colors.cream.100"
            }
          }
        }
      },
      "links": {
        "DEFAULT": {
          "value": {
            "base": "colors.elements.primary.fill",
            "_dark": "colors.elements.secondary.fill"
          }
        },
        "reversed": {
          "value": {
            "base": "colors.elements.secondary.fill",
            "_dark": "colors.elements.primary.fill"
          }
        },
        "hovered": {
          "DEFAULT": {
            "value": {
              "base": "colors.elements.primary.hovered.fill",
              "_dark": "colors.elements.secondary.hovered.fill"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.elements.secondary.hovered.fill",
              "_dark": "colors.elements.primary.hovered.fill"
            }
          }
        }
      },
      "stroke": {
        "opacity-10": {
          "DEFAULT": {
            "value": {
              "base": "colors.extra-dark-teal.10",
              "_dark": "colors.cream.10"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.cream.10",
              "_dark": "colors.extra-dark-teal.10"
            }
          }
        },
        "opacity-100": {
          "DEFAULT": {
            "value": {
              "base": "colors.extra-dark-teal.100",
              "_dark": "colors.cream.100"
            }
          },
          "reversed": {
            "value": {
              "base": "colors.cream.100",
              "_dark": "colors.extra-dark-teal.100"
            }
          }
        },
        "disabled": {
          "DEFAULT": {
            "value": "colors.gray.40"
          }
        },
        "opacity-4": {
          "reversed": {
            "value": {
              "base": "colors.cream.4",
              "_dark": "colors.extra-dark-teal.4"
            }
          },
          "DEFAULT": {
            "value": {
              "base": "colors.extra-dark-teal.4",
              "_dark": "colors.cream.4"
            }
          }
        }
      },
      "icon": {
        "DEFAULT": {
          "value": {
            "base": "colors.dark-teal.100",
            "_dark": "colors.cream.100"
          }
        },
        "reversed": {
          "value": {
            "base": "colors.cream.100",
            "_dark": "colors.dark-teal.100"
          }
        },
        "disabled": {
          "value": "colors.gray.60"
        },
        "error": {
          "value": {
            "base": "colors.error-red.100",
            "_dark": "colors.error-red.60"
          }
        }
      },
      "charting": {
        "categorical": {
          "dark": {
            "Red": {
              "value": "colors.R4"
            },
            "Orange": {
              "value": "colors.O4"
            },
            "Aqua": {
              "value": "colors.A3"
            },
            "Teal": {
              "value": "colors.T3"
            },
            "Blue": {
              "value": "colors.B3"
            },
            "Magenta": {
              "value": "colors.M4"
            },
            "Purple": {
              "value": "colors.P4"
            },
            "Green": {
              "value": "colors.G4"
            }
          },
          "light": {
            "Red": {
              "value": "colors.R10"
            },
            "Orange": {
              "value": "colors.O9"
            },
            "Aqua": {
              "value": "colors.A10"
            },
            "Teal": {
              "value": "colors.T8"
            },
            "Blue": {
              "value": "colors.B6"
            },
            "Magenta": {
              "value": "colors.M10"
            },
            "Purple": {
              "value": "colors.P8"
            },
            "Green": {
              "value": "colors.G10"
            }
          }
        }
      },
      "accent": {
        "primary": {
          "value": "colors.pink.100"
        },
        "secondary": {
          "value": "colors.green.100"
        },
        "tertiary": {
          "value": "colors.purple.100"
        }
      },
      "alert": {
        "error": {
          "value": "colors.error-red.100"
        },
        "warning": {
          "value": "colors.warning-orange.100"
        },
        "confirmation": {
          "value": "colors.success-green.100"
        },
        "information": {
          "value": "colors.info-blue.100"
        }
      }
    }
  }
};
var varnish_tokens_panda_theme_default = varnishPandaTheme;

export { varnish_tokens_panda_theme_default as default };
