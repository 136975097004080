
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Shape'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The IconButton comes with two shapes: box (default) and rounded.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="column" spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="default">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="default" shape="rounded">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" shape="rounded">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" shape="rounded">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;