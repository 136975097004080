import { Menu as AriaMenu, type MenuProps as AriaMenuProps } from 'react-aria-components';

import { PropsWithChildren } from 'react';

import { menuRecipe, MenuRecipeProps } from './menu.styles';
import { cx } from '@/utils/cx';

type MenuProps<T = object> = {
    children?: React.ReactNode;
    className?: string;
} & AriaMenuProps<T> &
    MenuRecipeProps;

const Menu = ({ className, children, ...rest }: PropsWithChildren<MenuProps>) => {
    const [variantProps, localProps] = menuRecipe.splitVariantProps(rest);
    const recipeClassNames = menuRecipe(variantProps);

    return (
        <AriaMenu {...localProps} className={cx(recipeClassNames.root, className)}>
            {children}
        </AriaMenu>
    );
};

export { Menu as AriaMenu };
export type { MenuProps };
