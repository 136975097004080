import { useState } from 'react';
import { styled, css } from '@mui/material/styles';
import * as reactRouterDom from 'react-router-dom';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import * as varnishComponents from '@allenai/varnish2/components';
import * as varnishMuiTheme from '@allenai/varnish2/theme';
import * as nivobar from '@nivo/bar';
import * as nivobullet from '@nivo/bullet';
import * as nivoheatmap from '@nivo/heatmap';
import * as nivoline from '@nivo/line';
import * as nivopie from '@nivo/pie';
import * as nivoscatterplot from '@nivo/scatterplot';
import * as mui from '@mui/material';
import * as muiStyles from '@mui/material/styles';
import * as varnishUi from '@allenai/varnish-ui';
import * as varnishNivo from '@allenai/varnish-nivo';
import * as emotionCss from '@emotion/css';
import emotionStyled from '@emotion/styled';
import varnishTheme from '@allenai/varnish-theme';
import varnishThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailOutlined from '@mui/icons-material/MailOutlined';
import LocalGroceryStore from '@mui/icons-material/LocalGroceryStore';
import FolderIcon from '@mui/icons-material/Folder';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import varnishUiSprite from '@allenai/varnish-ui/varnish-ui-sprite.svg';

import { Divider } from './Divider';
import { PadDiv } from './PadDiv';
import { Lipsum } from './Lipsum';

import logoWithText from '../varnishLogo.svg';

const globalScope = {
    styled,
    css,
    emotionCss,
    emotionStyled,
    logoWithText,
    MoreHorizIcon,
    ContentCopyIcon,
    MailOutlined,
    FolderIcon,
    FileCopyIcon,
    ChevronRightIcon,
    Lipsum,
    LocalGroceryStore,
    PadDiv,
    mui,
    muiStyles,
    varnishComponents,
    varnishUi,
    varnishUiSprite,
    ...reactRouterDom,
    ...nivobar,
    ...nivobullet,
    ...nivoheatmap,
    ...nivoline,
    ...nivopie,
    ...nivoscatterplot,
    ...varnishNivo,
    Theme: varnishTheme,
    ThemeMui: varnishMuiTheme,
    ThemeArch: varnishThemeArch,
};

const StyledProvider = styled(LiveProvider)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const LiveWrapper = styled('div')`
    display: grid;
    justify-content: stretch;
    align-items: stretch;
`;

const StyledEditor = styled('div')`
    font-family: ${({ theme }) => theme.font.family.monospace};
    * > textarea {
        &:focus {
            outline: none;
        }
    }
`;

const StyledPreview = styled(LivePreview)`
    position: relative;
    padding: ${({ theme }) => theme.spacing(1)};
`;

const StyledError = styled(LiveError)`
    display: block;
    padding: ${({ theme }) => theme.spacing(1.5)};
    background: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.error.contrastText};
    white-space: pre-wrap;
    text-align: left;
    font-family: ${({ theme }) => theme.font.family.monospace};
`;

const CodeBtnRow = styled('div')`
    display: grid;
    grid-template-columns: ${({ theme }) => theme.spacing(3)} max-content 1fr;
    align-items: center;
`;

const LightMode = styled('div')`
    color-scheme: light;
    background-color: ${varnishTheme.palette.light.background.default.value};
`;

const DarkMode = styled('div')`
    color-scheme: dark;
    background-color: ${varnishTheme.color['extra-dark-teal-100']
        .value}; // TODO: update varnishTheme.palette.light.background.default.value
`;

const FullWidth = styled('div')`
    width: 100%;
`;

interface Props {
    lightAndDark?: boolean;
    code?: string;
    scope?: object;
    noInline?: boolean;
    transformCode?: (code: string) => string;
}

const DefaultLiveProvider = (props: Props) => {
    // setup defaults
    const noInline = props.noInline !== undefined ? props.noInline : true;

    // add shared scope to all
    const scope = { ...props.scope, ...globalScope };

    const [openEditor, setOpenEditor] = useState(false);

    return (
        <StyledProvider
            {...props}
            noInline={noInline}
            scope={scope}
            transformCode={props.transformCode}>
            <LiveWrapper>
                {props.lightAndDark ? (
                    <varnishUi.Stack spacing={16}>
                        <FullWidth>
                            Light Mode
                            <LightMode>
                                <StyledPreview />
                            </LightMode>
                        </FullWidth>
                        <FullWidth>
                            Dark Mode
                            <DarkMode data-color-mode="dark">
                                <StyledPreview />
                            </DarkMode>
                        </FullWidth>
                    </varnishUi.Stack>
                ) : (
                    <StyledPreview />
                )}
                <CodeBtnRow>
                    <Divider />
                    <mui.Button
                        variant="text"
                        size="small"
                        onClick={() => {
                            setOpenEditor(!openEditor);
                        }}>
                        {openEditor ? 'Hide Code' : 'Show/Edit Code'}
                    </mui.Button>
                    <Divider />
                </CodeBtnRow>
                {openEditor ? (
                    <StyledEditor>
                        <LiveEditor />
                    </StyledEditor>
                ) : null}
            </LiveWrapper>
            <StyledError />
        </StyledProvider>
    );
};

export { DefaultLiveProvider };
