
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Text slots'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`By default, items in a ListBox are labeled by their text contents for accessibility.
MenuItems also support the "label" and "description" slots to separate primary and secondary content, which improves screen reader announcements and can also be used for styling purposes.
The Keyboard component can also be used to display a keyboard shortcut.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Actions">Actions</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuItem textValue="Copy">
                    <varnishUi.MenuText slot="label">Copy</varnishUi.MenuText>
                    <varnishUi.MenuText slot="description">
                        Copy the selected text
                    </varnishUi.MenuText>
                    <varnishUi.Keyboard>⌘C</varnishUi.Keyboard>
                </varnishUi.MenuItem>
                <varnishUi.MenuItem textValue="Cut">
                    <varnishUi.MenuText slot="label">Cut</varnishUi.MenuText>
                    <varnishUi.MenuText slot="description">
                        Cut the selected text
                    </varnishUi.MenuText>
                    <varnishUi.Keyboard>⌘X</varnishUi.Keyboard>
                </varnishUi.MenuItem>
                <varnishUi.MenuItem textValue="Paste">
                    <varnishUi.MenuText slot="label">Paste</varnishUi.MenuText>
                    <varnishUi.MenuText slot="description">
                        Paste the copied text
                    </varnishUi.MenuText>
                    <varnishUi.Keyboard>⌘V</varnishUi.Keyboard>
                </varnishUi.MenuItem>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;