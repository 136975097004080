import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const cardRecipe = sva({
    slots: ['root', 'header', 'body', 'footer', 'media'],
    base: {
        root: {
            border: '1px solid',
            backgroundColor: 'background',
            borderColor: 'gray.30',
            borderRadius: '[4px]',
            display: 'grid',
            fontSize: '[17px]',
            gridTemplateRows: 'auto auto 1fr auto',
            gridTemplateAreas: `
                "header"
                "media"
                "body"
                "footer"
            `,
            gap: '[10px]',
            gridAutoColumns: '[minmax(200px, 1fr)]',
            maxWidth: '[100%]',
            overflow: 'hidden',
        },
        header: {
            color: 'text.primary',
            padding: '[10px]',
            width: '[100%]',
            gridArea: 'header',
        },
        media: {
            color: 'text.primary',
            width: '[100%]',
            gridArea: 'media',
        },
        body: {
            color: 'text.primary',
            padding: '[10px]',
            width: '[100%]',
            gridArea: 'body',
        },
        footer: {
            color: 'text.primary',
            padding: '[10px]',
            width: '[100%]',
            gridArea: 'footer',
        },
    },
});

type CardRecipeProps = RecipeVariantProps<typeof cardRecipe>;

export { cardRecipe, cardRecipe as default };
export type { CardRecipeProps };
