import { Keyboard as AriaKeyboard } from 'react-aria-components';
import { PropsWithChildren } from 'react';

type KeyboardProps = {
    children?: React.ReactNode;
};
const Keyboard = ({ children, ...rest }: PropsWithChildren<KeyboardProps>) => {
    return <AriaKeyboard {...rest}>{children}</AriaKeyboard>;
};

export { Keyboard };
export type { KeyboardProps };
