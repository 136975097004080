const varnishTokens = {
  "asset": {
    "icon": {
      "arrow": {
        "value": "icons/arrow.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.arrow",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "arrow"
        },
        "path": [
          "asset",
          "icon",
          "arrow"
        ]
      },
      "chat": {
        "value": "icons/chat.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.chat",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "chat"
        },
        "path": [
          "asset",
          "icon",
          "chat"
        ]
      },
      "close": {
        "value": "icons/close.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.close",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "close"
        },
        "path": [
          "asset",
          "icon",
          "close"
        ]
      },
      "download": {
        "value": "icons/download.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.download",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "download"
        },
        "path": [
          "asset",
          "icon",
          "download"
        ]
      },
      "plus": {
        "value": "icons/plus.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.plus",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "plus"
        },
        "path": [
          "asset",
          "icon",
          "plus"
        ]
      },
      "search": {
        "value": "icons/search.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.search",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "search"
        },
        "path": [
          "asset",
          "icon",
          "search"
        ]
      },
      "share": {
        "value": "icons/share.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.share",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "share"
        },
        "path": [
          "asset",
          "icon",
          "share"
        ]
      },
      "spark": {
        "value": "icons/spark.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.spark",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "spark"
        },
        "path": [
          "asset",
          "icon",
          "spark"
        ]
      },
      "time": {
        "value": "icons/time.svg",
        "type": "asset",
        "attribute": "",
        "name": "asset.icon.time",
        "attributes": {
          "category": "asset",
          "type": "icon",
          "item": "time"
        },
        "path": [
          "asset",
          "icon",
          "time"
        ]
      }
    }
  },
  "breakpoint": {
    "xs": {
      "value": "0",
      "$type": "dimension",
      "name": "breakpoint.xs",
      "attributes": {
        "category": "breakpoint",
        "type": "xs"
      },
      "path": [
        "breakpoint",
        "xs"
      ]
    },
    "sm": {
      "value": "37.5rem",
      "$type": "dimension",
      "name": "breakpoint.sm",
      "attributes": {
        "category": "breakpoint",
        "type": "sm"
      },
      "path": [
        "breakpoint",
        "sm"
      ]
    },
    "md": {
      "value": "56.25rem",
      "$type": "dimension",
      "name": "breakpoint.md",
      "attributes": {
        "category": "breakpoint",
        "type": "md"
      },
      "path": [
        "breakpoint",
        "md"
      ]
    },
    "lg": {
      "value": "75rem",
      "$type": "dimension",
      "name": "breakpoint.lg",
      "attributes": {
        "category": "breakpoint",
        "type": "lg"
      },
      "path": [
        "breakpoint",
        "lg"
      ]
    },
    "xl": {
      "value": "96rem",
      "$type": "dimension",
      "name": "breakpoint.xl",
      "attributes": {
        "category": "breakpoint",
        "type": "xl"
      },
      "path": [
        "breakpoint",
        "xl"
      ]
    }
  },
  "color": {
    "extra-dark-teal-4": {
      "value": "rgba(3, 38, 41, 0.04)",
      "$type": "color",
      "name": "color.extraDarkTeal.4",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "4",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.04
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.04
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.04
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "4"
      ]
    },
    "extra-dark-teal-10": {
      "value": "rgba(3, 38, 41, 0.1)",
      "$type": "color",
      "name": "color.extraDarkTeal.10",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "10",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.1
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.1
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.1
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "10"
      ]
    },
    "extra-dark-teal-20": {
      "value": "rgba(3, 38, 41, 0.2)",
      "$type": "color",
      "name": "color.extraDarkTeal.20",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "20",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.2
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.2
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.2
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "20"
      ]
    },
    "extra-dark-teal-30": {
      "value": "rgba(3, 38, 41, 0.3)",
      "$type": "color",
      "name": "color.extraDarkTeal.30",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "30",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.3
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.3
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.3
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "30"
      ]
    },
    "extra-dark-teal-40": {
      "value": "rgba(3, 38, 41, 0.4)",
      "$type": "color",
      "name": "color.extraDarkTeal.40",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "40",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.4
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.4
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.4
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "40"
      ]
    },
    "extra-dark-teal-50": {
      "value": "rgba(3, 38, 41, 0.5)",
      "$type": "color",
      "name": "color.extraDarkTeal.50",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "50",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.5
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.5
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.5
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "50"
      ]
    },
    "extra-dark-teal-60": {
      "value": "rgba(3, 38, 41, 0.6)",
      "$type": "color",
      "name": "color.extraDarkTeal.60",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "60",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.6
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.6
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.6
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "60"
      ]
    },
    "extra-dark-teal-70": {
      "value": "rgba(3, 38, 41, 0.7)",
      "$type": "color",
      "name": "color.extraDarkTeal.70",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "70",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.7
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.7
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.7
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "70"
      ]
    },
    "extra-dark-teal-80": {
      "value": "rgba(3, 38, 41, 0.8)",
      "$type": "color",
      "name": "color.extraDarkTeal.80",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "80",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.8
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.8
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.8
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "80"
      ]
    },
    "extra-dark-teal-90": {
      "value": "rgba(3, 38, 41, 0.9)",
      "$type": "color",
      "name": "color.extraDarkTeal.90",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "90",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 0.9
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 0.9
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 0.9
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "90"
      ]
    },
    "extra-dark-teal-100": {
      "value": "rgba(3, 38, 41, 1)",
      "$type": "color",
      "name": "color.extraDarkTeal.100",
      "attributes": {
        "category": "color",
        "type": "extra-dark-teal",
        "item": "100",
        "hex": "032629",
        "rgb": {
          "r": 3,
          "g": 38,
          "b": 41,
          "a": 1
        },
        "hsl": {
          "h": 184.73684210526318,
          "s": 0.8636363636363638,
          "l": 0.08627450980392157,
          "a": 1
        },
        "hsv": {
          "h": 184.73684210526318,
          "s": 0.926829268292683,
          "v": 0.1607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "extra-dark-teal",
        "100"
      ]
    },
    "dark-teal-10": {
      "value": "rgba(10, 50, 53, 0.1)",
      "$type": "color",
      "name": "color.darkTeal.10",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "10",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.1
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.1
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.1
        }
      },
      "path": [
        "color",
        "dark-teal",
        "10"
      ]
    },
    "dark-teal-20": {
      "value": "rgba(10, 50, 53, 0.2)",
      "$type": "color",
      "name": "color.darkTeal.20",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "20",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.2
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.2
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.2
        }
      },
      "path": [
        "color",
        "dark-teal",
        "20"
      ]
    },
    "dark-teal-30": {
      "value": "rgba(10, 50, 53, 0.3)",
      "$type": "color",
      "name": "color.darkTeal.30",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "30",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.3
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.3
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.3
        }
      },
      "path": [
        "color",
        "dark-teal",
        "30"
      ]
    },
    "dark-teal-40": {
      "value": "rgba(10, 50, 53, 0.4)",
      "$type": "color",
      "name": "color.darkTeal.40",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "40",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.4
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.4
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.4
        }
      },
      "path": [
        "color",
        "dark-teal",
        "40"
      ]
    },
    "dark-teal-50": {
      "value": "rgba(10, 50, 53, 0.5)",
      "$type": "color",
      "name": "color.darkTeal.50",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "50",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.5
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.5
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.5
        }
      },
      "path": [
        "color",
        "dark-teal",
        "50"
      ]
    },
    "dark-teal-60": {
      "value": "rgba(10, 50, 53, 0.6)",
      "$type": "color",
      "name": "color.darkTeal.60",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "60",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.6
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.6
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.6
        }
      },
      "path": [
        "color",
        "dark-teal",
        "60"
      ]
    },
    "dark-teal-70": {
      "value": "rgba(10, 50, 53, 0.7)",
      "$type": "color",
      "name": "color.darkTeal.70",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "70",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.7
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.7
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.7
        }
      },
      "path": [
        "color",
        "dark-teal",
        "70"
      ]
    },
    "dark-teal-80": {
      "value": "rgba(10, 50, 53, 0.8)",
      "$type": "color",
      "name": "color.darkTeal.80",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "80",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.8
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.8
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.8
        }
      },
      "path": [
        "color",
        "dark-teal",
        "80"
      ]
    },
    "dark-teal-90": {
      "value": "rgba(10, 50, 53, 0.9)",
      "$type": "color",
      "name": "color.darkTeal.90",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "90",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 0.9
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 0.9
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 0.9
        }
      },
      "path": [
        "color",
        "dark-teal",
        "90"
      ]
    },
    "dark-teal-100": {
      "value": "rgba(10, 50, 53, 1)",
      "$type": "color",
      "name": "color.darkTeal.100",
      "attributes": {
        "category": "color",
        "type": "dark-teal",
        "item": "100",
        "hex": "0a3235",
        "rgb": {
          "r": 10,
          "g": 50,
          "b": 53,
          "a": 1
        },
        "hsl": {
          "h": 184.18604651162792,
          "s": 0.6825396825396826,
          "l": 0.12352941176470589,
          "a": 1
        },
        "hsv": {
          "h": 184.18604651162792,
          "s": 0.8113207547169812,
          "v": 0.20784313725490197,
          "a": 1
        }
      },
      "path": [
        "color",
        "dark-teal",
        "100"
      ]
    },
    "cream-4": {
      "value": "rgba(250, 242, 233, 0.04)",
      "$type": "color",
      "name": "color.cream.4",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "4",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.04
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.04
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.04
        }
      },
      "path": [
        "color",
        "cream",
        "4"
      ]
    },
    "cream-10": {
      "value": "rgba(250, 242, 233, 0.1)",
      "$type": "color",
      "name": "color.cream.10",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "10",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.1
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.1
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.1
        }
      },
      "path": [
        "color",
        "cream",
        "10"
      ]
    },
    "cream-20": {
      "value": "rgba(250, 242, 233, 0.2)",
      "$type": "color",
      "name": "color.cream.20",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "20",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.2
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.2
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.2
        }
      },
      "path": [
        "color",
        "cream",
        "20"
      ]
    },
    "cream-30": {
      "value": "rgba(250, 242, 233, 0.3)",
      "$type": "color",
      "name": "color.cream.30",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "30",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.3
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.3
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.3
        }
      },
      "path": [
        "color",
        "cream",
        "30"
      ]
    },
    "cream-40": {
      "value": "rgba(250, 242, 233, 0.4)",
      "$type": "color",
      "name": "color.cream.40",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "40",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.4
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.4
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.4
        }
      },
      "path": [
        "color",
        "cream",
        "40"
      ]
    },
    "cream-50": {
      "value": "rgba(250, 242, 233, 0.5)",
      "$type": "color",
      "name": "color.cream.50",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "50",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.5
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.5
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.5
        }
      },
      "path": [
        "color",
        "cream",
        "50"
      ]
    },
    "cream-60": {
      "value": "rgba(250, 242, 233, 0.6)",
      "$type": "color",
      "name": "color.cream.60",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "60",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.6
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.6
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.6
        }
      },
      "path": [
        "color",
        "cream",
        "60"
      ]
    },
    "cream-70": {
      "value": "rgba(250, 242, 233, 0.7)",
      "$type": "color",
      "name": "color.cream.70",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "70",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.7
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.7
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.7
        }
      },
      "path": [
        "color",
        "cream",
        "70"
      ]
    },
    "cream-80": {
      "value": "rgba(250, 242, 233, 0.8)",
      "$type": "color",
      "name": "color.cream.80",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "80",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.8
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.8
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.8
        }
      },
      "path": [
        "color",
        "cream",
        "80"
      ]
    },
    "cream-90": {
      "value": "rgba(250, 242, 233, 0.9)",
      "$type": "color",
      "name": "color.cream.90",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "90",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 0.9
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 0.9
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 0.9
        }
      },
      "path": [
        "color",
        "cream",
        "90"
      ]
    },
    "cream-100": {
      "value": "rgba(250, 242, 233, 1)",
      "$type": "color",
      "name": "color.cream.100",
      "attributes": {
        "category": "color",
        "type": "cream",
        "item": "100",
        "hex": "faf2e9",
        "rgb": {
          "r": 250,
          "g": 242,
          "b": 233,
          "a": 1
        },
        "hsl": {
          "h": 31.764705882352935,
          "s": 0.6296296296296298,
          "l": 0.9470588235294117,
          "a": 1
        },
        "hsv": {
          "h": 31.764705882352935,
          "s": 0.06799999999999999,
          "v": 0.9803921568627451,
          "a": 1
        }
      },
      "path": [
        "color",
        "cream",
        "100"
      ]
    },
    "info-blue-20": {
      "value": "rgba(212, 231, 252, 1)",
      "$type": "color",
      "name": "color.infoBlue.20",
      "attributes": {
        "category": "color",
        "type": "info-blue",
        "item": "20",
        "hex": "d4e7fc",
        "rgb": {
          "r": 212,
          "g": 231,
          "b": 252,
          "a": 1
        },
        "hsl": {
          "h": 211.5,
          "s": 0.8695652173913048,
          "l": 0.9098039215686275,
          "a": 1
        },
        "hsv": {
          "h": 211.5,
          "s": 0.15873015873015872,
          "v": 0.9882352941176471,
          "a": 1
        }
      },
      "path": [
        "color",
        "info-blue",
        "20"
      ]
    },
    "info-blue-40": {
      "value": "rgba(170, 207, 249, 1)",
      "$type": "color",
      "name": "color.infoBlue.40",
      "attributes": {
        "category": "color",
        "type": "info-blue",
        "item": "40",
        "hex": "aacff9",
        "rgb": {
          "r": 170,
          "g": 207,
          "b": 249,
          "a": 1
        },
        "hsl": {
          "h": 211.89873417721515,
          "s": 0.8681318681318683,
          "l": 0.8215686274509804,
          "a": 1
        },
        "hsv": {
          "h": 211.89873417721515,
          "s": 0.3172690763052209,
          "v": 0.9764705882352941,
          "a": 1
        }
      },
      "path": [
        "color",
        "info-blue",
        "40"
      ]
    },
    "info-blue-60": {
      "value": "rgba(127, 184, 245, 1)",
      "$type": "color",
      "name": "color.infoBlue.60",
      "attributes": {
        "category": "color",
        "type": "info-blue",
        "item": "60",
        "hex": "7fb8f5",
        "rgb": {
          "r": 127,
          "g": 184,
          "b": 245,
          "a": 1
        },
        "hsl": {
          "h": 211.01694915254237,
          "s": 0.8550724637681162,
          "l": 0.7294117647058824,
          "a": 1
        },
        "hsv": {
          "h": 211.01694915254237,
          "s": 0.4816326530612245,
          "v": 0.9607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "info-blue",
        "60"
      ]
    },
    "info-blue-80": {
      "value": "rgba(85, 160, 242, 1)",
      "$type": "color",
      "name": "color.infoBlue.80",
      "attributes": {
        "category": "color",
        "type": "info-blue",
        "item": "80",
        "hex": "55a0f2",
        "rgb": {
          "r": 85,
          "g": 160,
          "b": 242,
          "a": 1
        },
        "hsl": {
          "h": 211.3375796178344,
          "s": 0.8579234972677595,
          "l": 0.6411764705882352,
          "a": 1
        },
        "hsv": {
          "h": 211.3375796178344,
          "s": 0.6487603305785125,
          "v": 0.9490196078431372,
          "a": 1
        }
      },
      "path": [
        "color",
        "info-blue",
        "80"
      ]
    },
    "info-blue-100": {
      "value": "rgba(42, 136, 239, 1)",
      "$type": "color",
      "name": "color.infoBlue.100",
      "attributes": {
        "category": "color",
        "type": "info-blue",
        "item": "100",
        "hex": "2a88ef",
        "rgb": {
          "r": 42,
          "g": 136,
          "b": 239,
          "a": 1
        },
        "hsl": {
          "h": 211.37055837563452,
          "s": 0.8602620087336245,
          "l": 0.5509803921568628,
          "a": 1
        },
        "hsv": {
          "h": 211.37055837563452,
          "s": 0.8242677824267782,
          "v": 0.9372549019607843,
          "a": 1
        }
      },
      "path": [
        "color",
        "info-blue",
        "100"
      ]
    },
    "error-red-20": {
      "value": "rgba(255, 218, 218, 1)",
      "$type": "color",
      "name": "color.errorRed.20",
      "attributes": {
        "category": "color",
        "type": "error-red",
        "item": "20",
        "hex": "ffdada",
        "rgb": {
          "r": 255,
          "g": 218,
          "b": 218,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 1,
          "l": 0.9274509803921569,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.14509803921568631,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "error-red",
        "20"
      ]
    },
    "error-red-40": {
      "value": "rgba(254, 181, 181, 1)",
      "$type": "color",
      "name": "color.errorRed.40",
      "attributes": {
        "category": "color",
        "type": "error-red",
        "item": "40",
        "hex": "feb5b5",
        "rgb": {
          "r": 254,
          "g": 181,
          "b": 181,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0.9733333333333334,
          "l": 0.8529411764705883,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.2874015748031496,
          "v": 0.996078431372549,
          "a": 1
        }
      },
      "path": [
        "color",
        "error-red",
        "40"
      ]
    },
    "error-red-60": {
      "value": "rgba(254, 144, 143, 1)",
      "$type": "color",
      "name": "color.errorRed.60",
      "attributes": {
        "category": "color",
        "type": "error-red",
        "item": "60",
        "hex": "fe908f",
        "rgb": {
          "r": 254,
          "g": 144,
          "b": 143,
          "a": 1
        },
        "hsl": {
          "h": 0.5405405405405387,
          "s": 0.9823008849557523,
          "l": 0.7784313725490196,
          "a": 1
        },
        "hsv": {
          "h": 0.5405405405405387,
          "s": 0.43700787401574803,
          "v": 0.996078431372549,
          "a": 1
        }
      },
      "path": [
        "color",
        "error-red",
        "60"
      ]
    },
    "error-red-80": {
      "value": "rgba(253, 107, 106, 1)",
      "$type": "color",
      "name": "color.errorRed.80",
      "attributes": {
        "category": "color",
        "type": "error-red",
        "item": "80",
        "hex": "fd6b6a",
        "rgb": {
          "r": 253,
          "g": 107,
          "b": 106,
          "a": 1
        },
        "hsl": {
          "h": 0.40816326530612107,
          "s": 0.9735099337748345,
          "l": 0.703921568627451,
          "a": 1
        },
        "hsv": {
          "h": 0.40816326530612107,
          "s": 0.5810276679841897,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "path": [
        "color",
        "error-red",
        "80"
      ]
    },
    "error-red-100": {
      "value": "rgba(253, 70, 69, 1)",
      "$type": "color",
      "name": "color.errorRed.100",
      "attributes": {
        "category": "color",
        "type": "error-red",
        "item": "100",
        "hex": "fd4645",
        "rgb": {
          "r": 253,
          "g": 70,
          "b": 69,
          "a": 1
        },
        "hsl": {
          "h": 0.3260869565217426,
          "s": 0.9787234042553192,
          "l": 0.6313725490196078,
          "a": 1
        },
        "hsv": {
          "h": 0.3260869565217426,
          "s": 0.7272727272727274,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "path": [
        "color",
        "error-red",
        "100"
      ]
    },
    "success-green-20": {
      "value": "rgba(221, 235, 215, 1)",
      "$type": "color",
      "name": "color.successGreen.20",
      "attributes": {
        "category": "color",
        "type": "success-green",
        "item": "20",
        "hex": "ddebd7",
        "rgb": {
          "r": 221,
          "g": 235,
          "b": 215,
          "a": 1
        },
        "hsl": {
          "h": 101.99999999999997,
          "s": 0.33333333333333287,
          "l": 0.8823529411764706,
          "a": 1
        },
        "hsv": {
          "h": 101.99999999999997,
          "s": 0.08510638297872335,
          "v": 0.9215686274509803,
          "a": 1
        }
      },
      "path": [
        "color",
        "success-green",
        "20"
      ]
    },
    "success-green-40": {
      "value": "rgba(187, 215, 174, 1)",
      "$type": "color",
      "name": "color.successGreen.40",
      "attributes": {
        "category": "color",
        "type": "success-green",
        "item": "40",
        "hex": "bbd7ae",
        "rgb": {
          "r": 187,
          "g": 215,
          "b": 174,
          "a": 1
        },
        "hsl": {
          "h": 100.9756097560976,
          "s": 0.3388429752066116,
          "l": 0.7627450980392156,
          "a": 1
        },
        "hsv": {
          "h": 100.9756097560976,
          "s": 0.19069767441860463,
          "v": 0.8431372549019608,
          "a": 1
        }
      },
      "path": [
        "color",
        "success-green",
        "40"
      ]
    },
    "success-green-60": {
      "value": "rgba(152, 196, 134, 1)",
      "$type": "color",
      "name": "color.successGreen.60",
      "attributes": {
        "category": "color",
        "type": "success-green",
        "item": "60",
        "hex": "98c486",
        "rgb": {
          "r": 152,
          "g": 196,
          "b": 134,
          "a": 1
        },
        "hsl": {
          "h": 102.58064516129033,
          "s": 0.34444444444444444,
          "l": 0.6470588235294118,
          "a": 1
        },
        "hsv": {
          "h": 102.58064516129033,
          "s": 0.3163265306122449,
          "v": 0.7686274509803922,
          "a": 1
        }
      },
      "path": [
        "color",
        "success-green",
        "60"
      ]
    },
    "success-green-80": {
      "value": "rgba(118, 176, 93, 1)",
      "$type": "color",
      "name": "color.successGreen.80",
      "attributes": {
        "category": "color",
        "type": "success-green",
        "item": "80",
        "hex": "76b05d",
        "rgb": {
          "r": 118,
          "g": 176,
          "b": 93,
          "a": 1
        },
        "hsl": {
          "h": 101.92771084337349,
          "s": 0.34439834024896265,
          "l": 0.5274509803921569,
          "a": 1
        },
        "hsv": {
          "h": 101.92771084337349,
          "s": 0.4715909090909091,
          "v": 0.6901960784313725,
          "a": 1
        }
      },
      "path": [
        "color",
        "success-green",
        "80"
      ]
    },
    "success-green-100": {
      "value": "rgba(84, 156, 53, 1)",
      "$type": "color",
      "name": "color.successGreen.100",
      "attributes": {
        "category": "color",
        "type": "success-green",
        "item": "100",
        "hex": "549c35",
        "rgb": {
          "r": 84,
          "g": 156,
          "b": 53,
          "a": 1
        },
        "hsl": {
          "h": 101.94174757281554,
          "s": 0.492822966507177,
          "l": 0.4098039215686275,
          "a": 1
        },
        "hsv": {
          "h": 101.94174757281554,
          "s": 0.6602564102564102,
          "v": 0.611764705882353,
          "a": 1
        }
      },
      "path": [
        "color",
        "success-green",
        "100"
      ]
    },
    "warning-orange-20": {
      "value": "rgba(255, 237, 210, 1)",
      "$type": "color",
      "name": "color.warningOrange.20",
      "attributes": {
        "category": "color",
        "type": "warning-orange",
        "item": "20",
        "hex": "ffedd2",
        "rgb": {
          "r": 255,
          "g": 237,
          "b": 210,
          "a": 1
        },
        "hsl": {
          "h": 36.000000000000014,
          "s": 1,
          "l": 0.9117647058823529,
          "a": 1
        },
        "hsv": {
          "h": 36.000000000000014,
          "s": 0.17647058823529416,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warning-orange",
        "20"
      ]
    },
    "warning-orange-40": {
      "value": "rgba(255, 218, 164, 1)",
      "$type": "color",
      "name": "color.warningOrange.40",
      "attributes": {
        "category": "color",
        "type": "warning-orange",
        "item": "40",
        "hex": "ffdaa4",
        "rgb": {
          "r": 255,
          "g": 218,
          "b": 164,
          "a": 1
        },
        "hsl": {
          "h": 35.6043956043956,
          "s": 1,
          "l": 0.8215686274509804,
          "a": 1
        },
        "hsv": {
          "h": 35.6043956043956,
          "s": 0.35686274509803917,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warning-orange",
        "40"
      ]
    },
    "warning-orange-60": {
      "value": "rgba(255, 200, 119, 1)",
      "$type": "color",
      "name": "color.warningOrange.60",
      "attributes": {
        "category": "color",
        "type": "warning-orange",
        "item": "60",
        "hex": "ffc877",
        "rgb": {
          "r": 255,
          "g": 200,
          "b": 119,
          "a": 1
        },
        "hsl": {
          "h": 35.73529411764706,
          "s": 1,
          "l": 0.7333333333333334,
          "a": 1
        },
        "hsv": {
          "h": 35.73529411764706,
          "s": 0.5333333333333333,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warning-orange",
        "60"
      ]
    },
    "warning-orange-80": {
      "value": "rgba(255, 181, 73, 1)",
      "$type": "color",
      "name": "color.warningOrange.80",
      "attributes": {
        "category": "color",
        "type": "warning-orange",
        "item": "80",
        "hex": "ffb549",
        "rgb": {
          "r": 255,
          "g": 181,
          "b": 73,
          "a": 1
        },
        "hsl": {
          "h": 35.604395604395606,
          "s": 1,
          "l": 0.6431372549019607,
          "a": 1
        },
        "hsv": {
          "h": 35.604395604395606,
          "s": 0.7137254901960784,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warning-orange",
        "80"
      ]
    },
    "warning-orange-100": {
      "value": "rgba(255, 163, 28, 1)",
      "$type": "color",
      "name": "color.warningOrange.100",
      "attributes": {
        "category": "color",
        "type": "warning-orange",
        "item": "100",
        "hex": "ffa31c",
        "rgb": {
          "r": 255,
          "g": 163,
          "b": 28,
          "a": 1
        },
        "hsl": {
          "h": 35.68281938325991,
          "s": 1,
          "l": 0.5549019607843138,
          "a": 1
        },
        "hsv": {
          "h": 35.68281938325991,
          "s": 0.8901960784313725,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warning-orange",
        "100"
      ]
    },
    "teal-10": {
      "value": "rgba(16, 82, 87, 0.1)",
      "$type": "color",
      "name": "color.teal.10",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "10",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.1
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.1
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.1
        }
      },
      "path": [
        "color",
        "teal",
        "10"
      ]
    },
    "teal-20": {
      "value": "rgba(16, 82, 87, 0.2)",
      "$type": "color",
      "name": "color.teal.20",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "20",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.2
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.2
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.2
        }
      },
      "path": [
        "color",
        "teal",
        "20"
      ]
    },
    "teal-30": {
      "value": "rgba(16, 82, 87, 0.3)",
      "$type": "color",
      "name": "color.teal.30",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "30",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.3
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.3
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.3
        }
      },
      "path": [
        "color",
        "teal",
        "30"
      ]
    },
    "teal-40": {
      "value": "rgba(16, 82, 87, 0.4)",
      "$type": "color",
      "name": "color.teal.40",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "40",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.4
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.4
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.4
        }
      },
      "path": [
        "color",
        "teal",
        "40"
      ]
    },
    "teal-50": {
      "value": "rgba(16, 82, 87, 0.5)",
      "$type": "color",
      "name": "color.teal.50",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "50",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.5
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.5
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.5
        }
      },
      "path": [
        "color",
        "teal",
        "50"
      ]
    },
    "teal-60": {
      "value": "rgba(16, 82, 87, 0.6)",
      "$type": "color",
      "name": "color.teal.60",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "60",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.6
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.6
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.6
        }
      },
      "path": [
        "color",
        "teal",
        "60"
      ]
    },
    "teal-70": {
      "value": "rgba(16, 82, 87, 0.7)",
      "$type": "color",
      "name": "color.teal.70",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "70",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.7
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.7
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.7
        }
      },
      "path": [
        "color",
        "teal",
        "70"
      ]
    },
    "teal-80": {
      "value": "rgba(16, 82, 87, 0.8)",
      "$type": "color",
      "name": "color.teal.80",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "80",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.8
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.8
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.8
        }
      },
      "path": [
        "color",
        "teal",
        "80"
      ]
    },
    "teal-90": {
      "value": "rgba(16, 82, 87, 0.9)",
      "$type": "color",
      "name": "color.teal.90",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "90",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 0.9
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 0.9
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 0.9
        }
      },
      "path": [
        "color",
        "teal",
        "90"
      ]
    },
    "teal-100": {
      "value": "rgba(16, 82, 87, 1)",
      "$type": "color",
      "name": "color.teal.100",
      "attributes": {
        "category": "color",
        "type": "teal",
        "item": "100",
        "hex": "105257",
        "rgb": {
          "r": 16,
          "g": 82,
          "b": 87,
          "a": 1
        },
        "hsl": {
          "h": 184.22535211267603,
          "s": 0.6893203883495146,
          "l": 0.2019607843137255,
          "a": 1
        },
        "hsv": {
          "h": 184.22535211267603,
          "s": 0.8160919540229885,
          "v": 0.3411764705882353,
          "a": 1
        }
      },
      "path": [
        "color",
        "teal",
        "100"
      ]
    },
    "pink-10": {
      "value": "rgba(253, 238, 245, 1)",
      "$type": "color",
      "name": "color.pink.10",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "10",
        "hex": "fdeef5",
        "rgb": {
          "r": 253,
          "g": 238,
          "b": 245,
          "a": 1
        },
        "hsl": {
          "h": 332,
          "s": 0.789473684210527,
          "l": 0.9627450980392157,
          "a": 1
        },
        "hsv": {
          "h": 332,
          "s": 0.059288537549407126,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "10"
      ]
    },
    "pink-20": {
      "value": "rgba(252, 220, 235, 1)",
      "$type": "color",
      "name": "color.pink.20",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "20",
        "hex": "fcdceb",
        "rgb": {
          "r": 252,
          "g": 220,
          "b": 235,
          "a": 1
        },
        "hsl": {
          "h": 331.87500000000006,
          "s": 0.8421052631578952,
          "l": 0.9254901960784314,
          "a": 1
        },
        "hsv": {
          "h": 331.87500000000006,
          "s": 0.12698412698412698,
          "v": 0.9882352941176471,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "20"
      ]
    },
    "pink-30": {
      "value": "rgba(251, 203, 225, 1)",
      "$type": "color",
      "name": "color.pink.30",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "30",
        "hex": "fbcbe1",
        "rgb": {
          "r": 251,
          "g": 203,
          "b": 225,
          "a": 1
        },
        "hsl": {
          "h": 332.5,
          "s": 0.8571428571428571,
          "l": 0.8901960784313725,
          "a": 1
        },
        "hsv": {
          "h": 332.5,
          "s": 0.19123505976095617,
          "v": 0.984313725490196,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "30"
      ]
    },
    "pink-40": {
      "value": "rgba(249, 186, 215, 1)",
      "$type": "color",
      "name": "color.pink.40",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "40",
        "hex": "f9bad7",
        "rgb": {
          "r": 249,
          "g": 186,
          "b": 215,
          "a": 1
        },
        "hsl": {
          "h": 332.38095238095235,
          "s": 0.8400000000000002,
          "l": 0.8529411764705882,
          "a": 1
        },
        "hsv": {
          "h": 332.38095238095235,
          "s": 0.2530120481927711,
          "v": 0.9764705882352941,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "40"
      ]
    },
    "pink-50": {
      "value": "rgba(247, 168, 205, 1)",
      "$type": "color",
      "name": "color.pink.50",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "50",
        "hex": "f7a8cd",
        "rgb": {
          "r": 247,
          "g": 168,
          "b": 205,
          "a": 1
        },
        "hsl": {
          "h": 331.8987341772152,
          "s": 0.8315789473684213,
          "l": 0.8137254901960784,
          "a": 1
        },
        "hsv": {
          "h": 331.8987341772152,
          "s": 0.31983805668016196,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "50"
      ]
    },
    "pink-60": {
      "value": "rgba(246, 151, 196, 1)",
      "$type": "color",
      "name": "color.pink.60",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "60",
        "hex": "f697c4",
        "rgb": {
          "r": 246,
          "g": 151,
          "b": 196,
          "a": 1
        },
        "hsl": {
          "h": 331.57894736842104,
          "s": 0.8407079646017702,
          "l": 0.7784313725490196,
          "a": 1
        },
        "hsv": {
          "h": 331.57894736842104,
          "s": 0.3861788617886179,
          "v": 0.9647058823529412,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "60"
      ]
    },
    "pink-70": {
      "value": "rgba(245, 134, 186, 1)",
      "$type": "color",
      "name": "color.pink.70",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "70",
        "hex": "f586ba",
        "rgb": {
          "r": 245,
          "g": 134,
          "b": 186,
          "a": 1
        },
        "hsl": {
          "h": 331.89189189189193,
          "s": 0.8473282442748095,
          "l": 0.7431372549019608,
          "a": 1
        },
        "hsv": {
          "h": 331.89189189189193,
          "s": 0.4530612244897959,
          "v": 0.9607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "70"
      ]
    },
    "pink-80": {
      "value": "rgba(243, 117, 176, 1)",
      "$type": "color",
      "name": "color.pink.80",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "80",
        "hex": "f375b0",
        "rgb": {
          "r": 243,
          "g": 117,
          "b": 176,
          "a": 1
        },
        "hsl": {
          "h": 331.9047619047619,
          "s": 0.8399999999999997,
          "l": 0.7058823529411764,
          "a": 1
        },
        "hsv": {
          "h": 331.9047619047619,
          "s": 0.5185185185185185,
          "v": 0.9529411764705882,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "80"
      ]
    },
    "pink-90": {
      "value": "rgba(242, 99, 166, 1)",
      "$type": "color",
      "name": "color.pink.90",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "90",
        "hex": "f263a6",
        "rgb": {
          "r": 242,
          "g": 99,
          "b": 166,
          "a": 1
        },
        "hsl": {
          "h": 331.8881118881119,
          "s": 0.846153846153846,
          "l": 0.6686274509803921,
          "a": 1
        },
        "hsv": {
          "h": 331.8881118881119,
          "s": 0.5909090909090909,
          "v": 0.9490196078431372,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "90"
      ]
    },
    "pink-100": {
      "value": "rgba(240, 82, 156, 1)",
      "$type": "color",
      "name": "color.pink.100",
      "attributes": {
        "category": "color",
        "type": "pink",
        "item": "100",
        "hex": "f0529c",
        "rgb": {
          "r": 240,
          "g": 82,
          "b": 156,
          "a": 1
        },
        "hsl": {
          "h": 331.8987341772152,
          "s": 0.8404255319148936,
          "l": 0.6313725490196078,
          "a": 1
        },
        "hsv": {
          "h": 331.8987341772152,
          "s": 0.6583333333333333,
          "v": 0.9411764705882353,
          "a": 1
        }
      },
      "path": [
        "color",
        "pink",
        "100"
      ]
    },
    "purple-10": {
      "value": "rgba(247, 232, 253, 1)",
      "$type": "color",
      "name": "color.purple.10",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "10",
        "hex": "f7e8fd",
        "rgb": {
          "r": 247,
          "g": 232,
          "b": 253,
          "a": 1
        },
        "hsl": {
          "h": 282.85714285714283,
          "s": 0.8400000000000005,
          "l": 0.9509803921568627,
          "a": 1
        },
        "hsv": {
          "h": 282.85714285714283,
          "s": 0.08300395256917,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "10"
      ]
    },
    "purple-20": {
      "value": "rgba(239, 209, 250, 1)",
      "$type": "color",
      "name": "color.purple.20",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "20",
        "hex": "efd1fa",
        "rgb": {
          "r": 239,
          "g": 209,
          "b": 250,
          "a": 1
        },
        "hsl": {
          "h": 283.9024390243903,
          "s": 0.8039215686274511,
          "l": 0.8999999999999999,
          "a": 1
        },
        "hsv": {
          "h": 283.9024390243903,
          "s": 0.16399999999999998,
          "v": 0.9803921568627451,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "20"
      ]
    },
    "purple-30": {
      "value": "rgba(232, 187, 248, 1)",
      "$type": "color",
      "name": "color.purple.30",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "30",
        "hex": "e8bbf8",
        "rgb": {
          "r": 232,
          "g": 187,
          "b": 248,
          "a": 1
        },
        "hsl": {
          "h": 284.2622950819672,
          "s": 0.8133333333333336,
          "l": 0.8529411764705882,
          "a": 1
        },
        "hsv": {
          "h": 284.2622950819672,
          "s": 0.2459677419354839,
          "v": 0.9725490196078431,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "30"
      ]
    },
    "purple-40": {
      "value": "rgba(224, 164, 246, 1)",
      "$type": "color",
      "name": "color.purple.40",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "40",
        "hex": "e0a4f6",
        "rgb": {
          "r": 224,
          "g": 164,
          "b": 246,
          "a": 1
        },
        "hsl": {
          "h": 283.9024390243902,
          "s": 0.8200000000000003,
          "l": 0.803921568627451,
          "a": 1
        },
        "hsv": {
          "h": 283.9024390243902,
          "s": 0.3333333333333333,
          "v": 0.9647058823529412,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "40"
      ]
    },
    "purple-50": {
      "value": "rgba(216, 141, 243, 1)",
      "$type": "color",
      "name": "color.purple.50",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "50",
        "hex": "d88df3",
        "rgb": {
          "r": 216,
          "g": 141,
          "b": 243,
          "a": 1
        },
        "hsl": {
          "h": 284.11764705882354,
          "s": 0.8095238095238093,
          "l": 0.7529411764705882,
          "a": 1
        },
        "hsv": {
          "h": 284.11764705882354,
          "s": 0.419753086419753,
          "v": 0.9529411764705882,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "50"
      ]
    },
    "purple-60": {
      "value": "rgba(208, 118, 241, 1)",
      "$type": "color",
      "name": "color.purple.60",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "60",
        "hex": "d076f1",
        "rgb": {
          "r": 208,
          "g": 118,
          "b": 241,
          "a": 1
        },
        "hsl": {
          "h": 283.9024390243902,
          "s": 0.814569536423841,
          "l": 0.7039215686274509,
          "a": 1
        },
        "hsv": {
          "h": 283.9024390243902,
          "s": 0.5103734439834025,
          "v": 0.9450980392156862,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "60"
      ]
    },
    "purple-70": {
      "value": "rgba(200, 95, 239, 1)",
      "$type": "color",
      "name": "color.purple.70",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "70",
        "hex": "c85fef",
        "rgb": {
          "r": 200,
          "g": 95,
          "b": 239,
          "a": 1
        },
        "hsl": {
          "h": 283.75000000000006,
          "s": 0.8181818181818182,
          "l": 0.6549019607843137,
          "a": 1
        },
        "hsv": {
          "h": 283.75000000000006,
          "s": 0.602510460251046,
          "v": 0.9372549019607843,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "70"
      ]
    },
    "purple-80": {
      "value": "rgba(193, 73, 237, 1)",
      "$type": "color",
      "name": "color.purple.80",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "80",
        "hex": "c149ed",
        "rgb": {
          "r": 193,
          "g": 73,
          "b": 237,
          "a": 1
        },
        "hsl": {
          "h": 283.9024390243902,
          "s": 0.8200000000000001,
          "l": 0.607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 283.9024390243902,
          "s": 0.6919831223628692,
          "v": 0.9294117647058824,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "80"
      ]
    },
    "purple-90": {
      "value": "rgba(185, 50, 234, 1)",
      "$type": "color",
      "name": "color.purple.90",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "90",
        "hex": "b932ea",
        "rgb": {
          "r": 185,
          "g": 50,
          "b": 234,
          "a": 1
        },
        "hsl": {
          "h": 284.0217391304348,
          "s": 0.814159292035398,
          "l": 0.5568627450980392,
          "a": 1
        },
        "hsv": {
          "h": 284.0217391304348,
          "s": 0.7863247863247863,
          "v": 0.9176470588235294,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "90"
      ]
    },
    "purple-100": {
      "value": "rgba(177, 27, 232, 1)",
      "$type": "color",
      "name": "color.purple.100",
      "attributes": {
        "category": "color",
        "type": "purple",
        "item": "100",
        "hex": "b11be8",
        "rgb": {
          "r": 177,
          "g": 27,
          "b": 232,
          "a": 1
        },
        "hsl": {
          "h": 283.9024390243902,
          "s": 0.8167330677290836,
          "l": 0.5078431372549019,
          "a": 1
        },
        "hsv": {
          "h": 283.9024390243902,
          "s": 0.8836206896551725,
          "v": 0.9098039215686274,
          "a": 1
        }
      },
      "path": [
        "color",
        "purple",
        "100"
      ]
    },
    "green-10": {
      "value": "rgba(231, 250, 243, 1)",
      "$type": "color",
      "name": "color.green.10",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "10",
        "hex": "e7faf3",
        "rgb": {
          "r": 231,
          "g": 250,
          "b": 243,
          "a": 1
        },
        "hsl": {
          "h": 157.89473684210526,
          "s": 0.6551724137931035,
          "l": 0.9431372549019608,
          "a": 1
        },
        "hsv": {
          "h": 157.89473684210526,
          "s": 0.07599999999999996,
          "v": 0.9803921568627451,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "10"
      ]
    },
    "green-20": {
      "value": "rgba(207, 245, 232, 1)",
      "$type": "color",
      "name": "color.green.20",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "20",
        "hex": "cff5e8",
        "rgb": {
          "r": 207,
          "g": 245,
          "b": 232,
          "a": 1
        },
        "hsl": {
          "h": 159.4736842105263,
          "s": 0.655172413793104,
          "l": 0.8862745098039215,
          "a": 1
        },
        "hsv": {
          "h": 159.4736842105263,
          "s": 0.15510204081632656,
          "v": 0.9607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "20"
      ]
    },
    "green-30": {
      "value": "rgba(183, 239, 221, 1)",
      "$type": "color",
      "name": "color.green.30",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "30",
        "hex": "b7efdd",
        "rgb": {
          "r": 183,
          "g": 239,
          "b": 221,
          "a": 1
        },
        "hsl": {
          "h": 160.71428571428572,
          "s": 0.6363636363636364,
          "l": 0.8274509803921568,
          "a": 1
        },
        "hsv": {
          "h": 160.71428571428572,
          "s": 0.23430962343096234,
          "v": 0.9372549019607843,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "30"
      ]
    },
    "green-40": {
      "value": "rgba(159, 234, 209, 1)",
      "$type": "color",
      "name": "color.green.40",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "40",
        "hex": "9fead1",
        "rgb": {
          "r": 159,
          "g": 234,
          "b": 209,
          "a": 1
        },
        "hsl": {
          "h": 160,
          "s": 0.6410256410256407,
          "l": 0.7705882352941176,
          "a": 1
        },
        "hsv": {
          "h": 160,
          "s": 0.3205128205128205,
          "v": 0.9176470588235294,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "40"
      ]
    },
    "green-50": {
      "value": "rgba(135, 229, 197, 1)",
      "$type": "color",
      "name": "color.green.50",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "50",
        "hex": "87e5c5",
        "rgb": {
          "r": 135,
          "g": 229,
          "b": 197,
          "a": 1
        },
        "hsl": {
          "h": 159.57446808510636,
          "s": 0.6438356164383561,
          "l": 0.7137254901960784,
          "a": 1
        },
        "hsv": {
          "h": 159.57446808510636,
          "s": 0.4104803493449782,
          "v": 0.8980392156862745,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "50"
      ]
    },
    "green-60": {
      "value": "rgba(111, 224, 186, 1)",
      "$type": "color",
      "name": "color.green.60",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "60",
        "hex": "6fe0ba",
        "rgb": {
          "r": 111,
          "g": 224,
          "b": 186,
          "a": 1
        },
        "hsl": {
          "h": 159.8230088495575,
          "s": 0.6457142857142857,
          "l": 0.6568627450980392,
          "a": 1
        },
        "hsv": {
          "h": 159.8230088495575,
          "s": 0.5044642857142857,
          "v": 0.8784313725490196,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "60"
      ]
    },
    "green-70": {
      "value": "rgba(87, 219, 175, 1)",
      "$type": "color",
      "name": "color.green.70",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "70",
        "hex": "57dbaf",
        "rgb": {
          "r": 87,
          "g": 219,
          "b": 175,
          "a": 1
        },
        "hsl": {
          "h": 160,
          "s": 0.6470588235294117,
          "l": 0.6,
          "a": 1
        },
        "hsv": {
          "h": 160,
          "s": 0.6027397260273972,
          "v": 0.8588235294117647,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "70"
      ]
    },
    "green-80": {
      "value": "rgba(63, 213, 163, 1)",
      "$type": "color",
      "name": "color.green.80",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "80",
        "hex": "3fd5a3",
        "rgb": {
          "r": 63,
          "g": 213,
          "b": 163,
          "a": 1
        },
        "hsl": {
          "h": 160,
          "s": 0.6410256410256412,
          "l": 0.5411764705882354,
          "a": 1
        },
        "hsv": {
          "h": 160,
          "s": 0.704225352112676,
          "v": 0.8352941176470589,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "80"
      ]
    },
    "green-90": {
      "value": "rgba(39, 208, 156, 1)",
      "$type": "color",
      "name": "color.green.90",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "90",
        "hex": "27d09c",
        "rgb": {
          "r": 39,
          "g": 208,
          "b": 156,
          "a": 1
        },
        "hsl": {
          "h": 161.53846153846155,
          "s": 0.6842105263157895,
          "l": 0.4843137254901961,
          "a": 1
        },
        "hsv": {
          "h": 161.53846153846155,
          "s": 0.8125,
          "v": 0.8156862745098039,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "90"
      ]
    },
    "green-100": {
      "value": "rgba(15, 203, 140, 1)",
      "$type": "color",
      "name": "color.green.100",
      "attributes": {
        "category": "color",
        "type": "green",
        "item": "100",
        "hex": "0fcb8c",
        "rgb": {
          "r": 15,
          "g": 203,
          "b": 140,
          "a": 1
        },
        "hsl": {
          "h": 159.8936170212766,
          "s": 0.8623853211009174,
          "l": 0.42745098039215684,
          "a": 1
        },
        "hsv": {
          "h": 159.8936170212766,
          "s": 0.9261083743842364,
          "v": 0.796078431372549,
          "a": 1
        }
      },
      "path": [
        "color",
        "green",
        "100"
      ]
    },
    "gray-10": {
      "value": "rgba(235, 235, 235, 1)",
      "$type": "color",
      "name": "color.gray.10",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "10",
        "hex": "ebebeb",
        "rgb": {
          "r": 235,
          "g": 235,
          "b": 235,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.9215686274509803,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.9215686274509803,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "10"
      ]
    },
    "gray-20": {
      "value": "rgba(214, 214, 214, 1)",
      "$type": "color",
      "name": "color.gray.20",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "20",
        "hex": "d6d6d6",
        "rgb": {
          "r": 214,
          "g": 214,
          "b": 214,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.8392156862745098,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.8392156862745098,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "20"
      ]
    },
    "gray-30": {
      "value": "rgba(194, 194, 194, 1)",
      "$type": "color",
      "name": "color.gray.30",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "30",
        "hex": "c2c2c2",
        "rgb": {
          "r": 194,
          "g": 194,
          "b": 194,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.7607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.7607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "30"
      ]
    },
    "gray-40": {
      "value": "rgba(174, 174, 174, 1)",
      "$type": "color",
      "name": "color.gray.40",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "40",
        "hex": "aeaeae",
        "rgb": {
          "r": 174,
          "g": 174,
          "b": 174,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.6823529411764706,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.6823529411764706,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "40"
      ]
    },
    "gray-50": {
      "value": "rgba(153, 153, 153, 1)",
      "$type": "color",
      "name": "color.gray.50",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "50",
        "hex": "999999",
        "rgb": {
          "r": 153,
          "g": 153,
          "b": 153,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.6,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.6,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "50"
      ]
    },
    "gray-60": {
      "value": "rgba(133, 133, 133, 1)",
      "$type": "color",
      "name": "color.gray.60",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "60",
        "hex": "858585",
        "rgb": {
          "r": 133,
          "g": 133,
          "b": 133,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.5215686274509804,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.5215686274509804,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "60"
      ]
    },
    "gray-70": {
      "value": "rgba(113, 113, 113, 1)",
      "$type": "color",
      "name": "color.gray.70",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "70",
        "hex": "717171",
        "rgb": {
          "r": 113,
          "g": 113,
          "b": 113,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.44313725490196076,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.44313725490196076,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "70"
      ]
    },
    "gray-80": {
      "value": "rgba(93, 93, 93, 1)",
      "$type": "color",
      "name": "color.gray.80",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "80",
        "hex": "5d5d5d",
        "rgb": {
          "r": 93,
          "g": 93,
          "b": 93,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.36470588235294116,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.36470588235294116,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "80"
      ]
    },
    "gray-90": {
      "value": "rgba(72, 72, 72, 1)",
      "$type": "color",
      "name": "color.gray.90",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "90",
        "hex": "484848",
        "rgb": {
          "r": 72,
          "g": 72,
          "b": 72,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.2823529411764706,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.2823529411764706,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "90"
      ]
    },
    "gray-100": {
      "value": "rgba(52, 52, 52, 1)",
      "$type": "color",
      "name": "color.gray.100",
      "attributes": {
        "category": "color",
        "type": "gray",
        "item": "100",
        "hex": "343434",
        "rgb": {
          "r": 52,
          "g": 52,
          "b": 52,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.20392156862745098,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.20392156862745098,
          "a": 1
        }
      },
      "path": [
        "color",
        "gray",
        "100"
      ]
    },
    "white": {
      "value": "rgba(255, 255, 255, 1)",
      "$type": "color",
      "name": "color.white",
      "attributes": {
        "category": "color",
        "type": "white",
        "hex": "ffffff",
        "rgb": {
          "r": 255,
          "g": 255,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 1,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "white"
      ]
    },
    "black": {
      "value": "rgba(0, 0, 0, 1)",
      "$type": "color",
      "name": "color.black",
      "attributes": {
        "category": "color",
        "type": "black",
        "hex": "000000",
        "rgb": {
          "r": 0,
          "g": 0,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0,
          "a": 1
        }
      },
      "path": [
        "color",
        "black"
      ]
    },
    "R1": {
      "value": "rgba(255, 242, 242, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "R1",
        "hex": "fff2f2",
        "rgb": {
          "r": 255,
          "g": 242,
          "b": 242,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 1,
          "l": 0.9745098039215686,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.050980392156862786,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.R1",
      "path": [
        "color",
        "R1"
      ]
    },
    "R2": {
      "value": "rgba(255, 225, 224, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "R2",
        "hex": "ffe1e0",
        "rgb": {
          "r": 255,
          "g": 225,
          "b": 224,
          "a": 1
        },
        "hsl": {
          "h": 1.9354838709677349,
          "s": 1,
          "l": 0.9392156862745098,
          "a": 1
        },
        "hsv": {
          "h": 1.9354838709677349,
          "s": 0.1215686274509804,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.R2",
      "path": [
        "color",
        "R2"
      ]
    },
    "R3": {
      "value": "rgba(253, 193, 192, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "R3",
        "hex": "fdc1c0",
        "rgb": {
          "r": 253,
          "g": 193,
          "b": 192,
          "a": 1
        },
        "hsl": {
          "h": 0.9836065573770454,
          "s": 0.9384615384615387,
          "l": 0.8725490196078431,
          "a": 1
        },
        "hsv": {
          "h": 0.9836065573770454,
          "s": 0.24110671936758896,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "name": "color.R3",
      "path": [
        "color",
        "R3"
      ]
    },
    "R4": {
      "value": "rgba(255, 159, 158, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "R4",
        "hex": "ff9f9e",
        "rgb": {
          "r": 255,
          "g": 159,
          "b": 158,
          "a": 1
        },
        "hsl": {
          "h": 0.6185567010309256,
          "s": 1,
          "l": 0.8098039215686275,
          "a": 1
        },
        "hsv": {
          "h": 0.6185567010309256,
          "s": 0.3803921568627451,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.R4",
      "path": [
        "color",
        "R4"
      ]
    },
    "R5": {
      "value": "rgba(249, 128, 127, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "R5",
        "hex": "f9807f",
        "rgb": {
          "r": 249,
          "g": 128,
          "b": 127,
          "a": 1
        },
        "hsl": {
          "h": 0.49180327868852286,
          "s": 0.9104477611940299,
          "l": 0.7372549019607844,
          "a": 1
        },
        "hsv": {
          "h": 0.49180327868852286,
          "s": 0.4899598393574297,
          "v": 0.9764705882352941,
          "a": 1
        }
      },
      "name": "color.R5",
      "path": [
        "color",
        "R5"
      ]
    },
    "R6": {
      "value": "rgba(247, 96, 95, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "R6",
        "hex": "f7605f",
        "rgb": {
          "r": 247,
          "g": 96,
          "b": 95,
          "a": 1
        },
        "hsl": {
          "h": 0.39473684210526183,
          "s": 0.9047619047619049,
          "l": 0.6705882352941177,
          "a": 1
        },
        "hsv": {
          "h": 0.39473684210526183,
          "s": 0.6153846153846154,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "name": "color.R6",
      "path": [
        "color",
        "R6"
      ]
    },
    "R7": {
      "value": "rgba(231, 80, 79, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "R7",
        "hex": "e7504f",
        "rgb": {
          "r": 231,
          "g": 80,
          "b": 79,
          "a": 1
        },
        "hsl": {
          "h": 0.39473684210526183,
          "s": 0.7599999999999999,
          "l": 0.607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 0.39473684210526183,
          "s": 0.658008658008658,
          "v": 0.9058823529411765,
          "a": 1
        }
      },
      "name": "color.R7",
      "path": [
        "color",
        "R7"
      ]
    },
    "R8": {
      "value": "rgba(214, 63, 63, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "R8",
        "hex": "d63f3f",
        "rgb": {
          "r": 214,
          "g": 63,
          "b": 63,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0.6480686695278971,
          "l": 0.5431372549019609,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.705607476635514,
          "v": 0.8392156862745098,
          "a": 1
        }
      },
      "name": "color.R8",
      "path": [
        "color",
        "R8"
      ]
    },
    "R9": {
      "value": "rgba(191, 45, 45, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "R9",
        "hex": "bf2d2d",
        "rgb": {
          "r": 191,
          "g": 45,
          "b": 45,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0.6186440677966101,
          "l": 0.4627450980392157,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.7643979057591622,
          "v": 0.7490196078431373,
          "a": 1
        }
      },
      "name": "color.R9",
      "path": [
        "color",
        "R9"
      ]
    },
    "R10": {
      "value": "rgba(147, 34, 34, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "R10",
        "hex": "932222",
        "rgb": {
          "r": 147,
          "g": 34,
          "b": 34,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0.6243093922651934,
          "l": 0.3549019607843137,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.7687074829931974,
          "v": 0.5764705882352941,
          "a": 1
        }
      },
      "name": "color.R10",
      "path": [
        "color",
        "R10"
      ]
    },
    "O1": {
      "value": "rgba(255, 249, 232, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "O1",
        "hex": "fff9e8",
        "rgb": {
          "r": 255,
          "g": 249,
          "b": 232,
          "a": 1
        },
        "hsl": {
          "h": 44.3478260869565,
          "s": 1,
          "l": 0.9549019607843137,
          "a": 1
        },
        "hsv": {
          "h": 44.3478260869565,
          "s": 0.09019607843137256,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O1",
      "path": [
        "color",
        "O1"
      ]
    },
    "O2": {
      "value": "rgba(255, 241, 196, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "O2",
        "hex": "fff1c4",
        "rgb": {
          "r": 255,
          "g": 241,
          "b": 196,
          "a": 1
        },
        "hsl": {
          "h": 45.76271186440677,
          "s": 1,
          "l": 0.884313725490196,
          "a": 1
        },
        "hsv": {
          "h": 45.76271186440677,
          "s": 0.2313725490196078,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O2",
      "path": [
        "color",
        "O2"
      ]
    },
    "O3": {
      "value": "rgba(255, 227, 148, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "O3",
        "hex": "ffe394",
        "rgb": {
          "r": 255,
          "g": 227,
          "b": 148,
          "a": 1
        },
        "hsl": {
          "h": 44.29906542056074,
          "s": 1,
          "l": 0.7901960784313726,
          "a": 1
        },
        "hsv": {
          "h": 44.29906542056074,
          "s": 0.41960784313725485,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O3",
      "path": [
        "color",
        "O3"
      ]
    },
    "O4": {
      "value": "rgba(255, 212, 93, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "O4",
        "hex": "ffd45d",
        "rgb": {
          "r": 255,
          "g": 212,
          "b": 93,
          "a": 1
        },
        "hsl": {
          "h": 44.07407407407408,
          "s": 1,
          "l": 0.6823529411764706,
          "a": 1
        },
        "hsv": {
          "h": 44.07407407407408,
          "s": 0.6352941176470588,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O4",
      "path": [
        "color",
        "O4"
      ]
    },
    "O5": {
      "value": "rgba(255, 199, 46, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "O5",
        "hex": "ffc72e",
        "rgb": {
          "r": 255,
          "g": 199,
          "b": 46,
          "a": 1
        },
        "hsl": {
          "h": 43.92344497607656,
          "s": 1,
          "l": 0.5901960784313726,
          "a": 1
        },
        "hsv": {
          "h": 43.92344497607656,
          "s": 0.8196078431372549,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O5",
      "path": [
        "color",
        "O5"
      ]
    },
    "O6": {
      "value": "rgba(255, 187, 0, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "O6",
        "hex": "ffbb00",
        "rgb": {
          "r": 255,
          "g": 187,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 44,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 44,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O6",
      "path": [
        "color",
        "O6"
      ]
    },
    "O7": {
      "value": "rgba(255, 162, 0, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "O7",
        "hex": "ffa200",
        "rgb": {
          "r": 255,
          "g": 162,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 38.11764705882353,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 38.11764705882353,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O7",
      "path": [
        "color",
        "O7"
      ]
    },
    "O8": {
      "value": "rgba(255, 145, 0, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "O8",
        "hex": "ff9100",
        "rgb": {
          "r": 255,
          "g": 145,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 34.11764705882353,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 34.11764705882353,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.O8",
      "path": [
        "color",
        "O8"
      ]
    },
    "O9": {
      "value": "rgba(221, 101, 2, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "O9",
        "hex": "dd6502",
        "rgb": {
          "r": 221,
          "g": 101,
          "b": 2,
          "a": 1
        },
        "hsl": {
          "h": 27.12328767123287,
          "s": 0.9820627802690584,
          "l": 0.4372549019607843,
          "a": 1
        },
        "hsv": {
          "h": 27.12328767123287,
          "s": 0.9909502262443439,
          "v": 0.8666666666666667,
          "a": 1
        }
      },
      "name": "color.O9",
      "path": [
        "color",
        "O9"
      ]
    },
    "O10": {
      "value": "rgba(169, 64, 6, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "O10",
        "hex": "a94006",
        "rgb": {
          "r": 169,
          "g": 64,
          "b": 6,
          "a": 1
        },
        "hsl": {
          "h": 21.349693251533743,
          "s": 0.9314285714285714,
          "l": 0.3431372549019608,
          "a": 1
        },
        "hsv": {
          "h": 21.349693251533743,
          "s": 0.9644970414201183,
          "v": 0.6627450980392157,
          "a": 1
        }
      },
      "name": "color.O10",
      "path": [
        "color",
        "O10"
      ]
    },
    "G1": {
      "value": "rgba(228, 255, 247, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "G1",
        "hex": "e4fff7",
        "rgb": {
          "r": 228,
          "g": 255,
          "b": 247,
          "a": 1
        },
        "hsl": {
          "h": 162.22222222222223,
          "s": 1,
          "l": 0.9470588235294117,
          "a": 1
        },
        "hsv": {
          "h": 162.22222222222223,
          "s": 0.10588235294117643,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.G1",
      "path": [
        "color",
        "G1"
      ]
    },
    "G2": {
      "value": "rgba(193, 247, 230, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "G2",
        "hex": "c1f7e6",
        "rgb": {
          "r": 193,
          "g": 247,
          "b": 230,
          "a": 1
        },
        "hsl": {
          "h": 161.11111111111111,
          "s": 0.7714285714285718,
          "l": 0.8627450980392157,
          "a": 1
        },
        "hsv": {
          "h": 161.11111111111111,
          "s": 0.21862348178137656,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "name": "color.G2",
      "path": [
        "color",
        "G2"
      ]
    },
    "G3": {
      "value": "rgba(152, 234, 208, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "G3",
        "hex": "98ead0",
        "rgb": {
          "r": 152,
          "g": 234,
          "b": 208,
          "a": 1
        },
        "hsl": {
          "h": 160.97560975609758,
          "s": 0.6612903225806449,
          "l": 0.7568627450980392,
          "a": 1
        },
        "hsv": {
          "h": 160.97560975609758,
          "s": 0.3504273504273504,
          "v": 0.9176470588235294,
          "a": 1
        }
      },
      "name": "color.G3",
      "path": [
        "color",
        "G3"
      ]
    },
    "G4": {
      "value": "rgba(112, 221, 186, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "G4",
        "hex": "70ddba",
        "rgb": {
          "r": 112,
          "g": 221,
          "b": 186,
          "a": 1
        },
        "hsl": {
          "h": 160.73394495412845,
          "s": 0.6158192090395481,
          "l": 0.6529411764705882,
          "a": 1
        },
        "hsv": {
          "h": 160.73394495412845,
          "s": 0.49321266968325794,
          "v": 0.8666666666666667,
          "a": 1
        }
      },
      "name": "color.G4",
      "path": [
        "color",
        "G4"
      ]
    },
    "G5": {
      "value": "rgba(71, 207, 164, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "G5",
        "hex": "47cfa4",
        "rgb": {
          "r": 71,
          "g": 207,
          "b": 164,
          "a": 1
        },
        "hsl": {
          "h": 161.02941176470588,
          "s": 0.5862068965517241,
          "l": 0.5450980392156863,
          "a": 1
        },
        "hsv": {
          "h": 161.02941176470588,
          "s": 0.6570048309178744,
          "v": 0.8117647058823529,
          "a": 1
        }
      },
      "name": "color.G5",
      "path": [
        "color",
        "G5"
      ]
    },
    "G6": {
      "value": "rgba(30, 194, 142, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "G6",
        "hex": "1ec28e",
        "rgb": {
          "r": 30,
          "g": 194,
          "b": 142,
          "a": 1
        },
        "hsl": {
          "h": 160.97560975609758,
          "s": 0.7321428571428571,
          "l": 0.4392156862745098,
          "a": 1
        },
        "hsv": {
          "h": 160.97560975609758,
          "s": 0.845360824742268,
          "v": 0.7607843137254902,
          "a": 1
        }
      },
      "name": "color.G6",
      "path": [
        "color",
        "G6"
      ]
    },
    "G7": {
      "value": "rgba(20, 168, 125, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "G7",
        "hex": "14a87d",
        "rgb": {
          "r": 20,
          "g": 168,
          "b": 125,
          "a": 1
        },
        "hsl": {
          "h": 162.56756756756758,
          "s": 0.7872340425531914,
          "l": 0.3686274509803922,
          "a": 1
        },
        "hsv": {
          "h": 162.56756756756758,
          "s": 0.8809523809523808,
          "v": 0.6588235294117647,
          "a": 1
        }
      },
      "name": "color.G7",
      "path": [
        "color",
        "G7"
      ]
    },
    "G8": {
      "value": "rgba(10, 143, 107, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "G8",
        "hex": "0a8f6b",
        "rgb": {
          "r": 10,
          "g": 143,
          "b": 107,
          "a": 1
        },
        "hsl": {
          "h": 163.75939849624058,
          "s": 0.8692810457516341,
          "l": 0.3,
          "a": 1
        },
        "hsv": {
          "h": 163.75939849624058,
          "s": 0.9300699300699301,
          "v": 0.5607843137254902,
          "a": 1
        }
      },
      "name": "color.G8",
      "path": [
        "color",
        "G8"
      ]
    },
    "G9": {
      "value": "rgba(0, 117, 90, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "G9",
        "hex": "00755a",
        "rgb": {
          "r": 0,
          "g": 117,
          "b": 90,
          "a": 1
        },
        "hsl": {
          "h": 166.15384615384613,
          "s": 1,
          "l": 0.22941176470588234,
          "a": 1
        },
        "hsv": {
          "h": 166.15384615384613,
          "s": 1,
          "v": 0.4588235294117647,
          "a": 1
        }
      },
      "name": "color.G9",
      "path": [
        "color",
        "G9"
      ]
    },
    "G10": {
      "value": "rgba(0, 83, 64, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "G10",
        "hex": "005340",
        "rgb": {
          "r": 0,
          "g": 83,
          "b": 64,
          "a": 1
        },
        "hsl": {
          "h": 166.26506024096383,
          "s": 1,
          "l": 0.1627450980392157,
          "a": 1
        },
        "hsv": {
          "h": 166.26506024096383,
          "s": 1,
          "v": 0.3254901960784314,
          "a": 1
        }
      },
      "name": "color.G10",
      "path": [
        "color",
        "G10"
      ]
    },
    "T1": {
      "value": "rgba(230, 253, 254, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "T1",
        "hex": "e6fdfe",
        "rgb": {
          "r": 230,
          "g": 253,
          "b": 254,
          "a": 1
        },
        "hsl": {
          "h": 182.5,
          "s": 0.9230769230769234,
          "l": 0.9490196078431372,
          "a": 1
        },
        "hsv": {
          "h": 182.5,
          "s": 0.09448818897637795,
          "v": 0.996078431372549,
          "a": 1
        }
      },
      "name": "color.T1",
      "path": [
        "color",
        "T1"
      ]
    },
    "T2": {
      "value": "rgba(198, 243, 246, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "T2",
        "hex": "c6f3f6",
        "rgb": {
          "r": 198,
          "g": 243,
          "b": 246,
          "a": 1
        },
        "hsl": {
          "h": 183.75000000000003,
          "s": 0.7272727272727276,
          "l": 0.8705882352941177,
          "a": 1
        },
        "hsv": {
          "h": 183.75000000000003,
          "s": 0.1951219512195122,
          "v": 0.9647058823529412,
          "a": 1
        }
      },
      "name": "color.T2",
      "path": [
        "color",
        "T2"
      ]
    },
    "T3": {
      "value": "rgba(154, 231, 236, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "T3",
        "hex": "9ae7ec",
        "rgb": {
          "r": 154,
          "g": 231,
          "b": 236,
          "a": 1
        },
        "hsl": {
          "h": 183.65853658536585,
          "s": 0.6833333333333335,
          "l": 0.7647058823529411,
          "a": 1
        },
        "hsv": {
          "h": 183.65853658536585,
          "s": 0.34745762711864414,
          "v": 0.9254901960784314,
          "a": 1
        }
      },
      "name": "color.T3",
      "path": [
        "color",
        "T3"
      ]
    },
    "T4": {
      "value": "rgba(110, 220, 227, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "T4",
        "hex": "6edce3",
        "rgb": {
          "r": 110,
          "g": 220,
          "b": 227,
          "a": 1
        },
        "hsl": {
          "h": 183.5897435897436,
          "s": 0.6763005780346819,
          "l": 0.6607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 183.5897435897436,
          "s": 0.5154185022026431,
          "v": 0.8901960784313725,
          "a": 1
        }
      },
      "name": "color.T4",
      "path": [
        "color",
        "T4"
      ]
    },
    "T5": {
      "value": "rgba(66, 208, 217, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "T5",
        "hex": "42d0d9",
        "rgb": {
          "r": 66,
          "g": 208,
          "b": 217,
          "a": 1
        },
        "hsl": {
          "h": 183.57615894039733,
          "s": 0.6651982378854625,
          "l": 0.5549019607843138,
          "a": 1
        },
        "hsv": {
          "h": 183.57615894039733,
          "s": 0.6958525345622119,
          "v": 0.8509803921568627,
          "a": 1
        }
      },
      "name": "color.T5",
      "path": [
        "color",
        "T5"
      ]
    },
    "T6": {
      "value": "rgba(22, 196, 207, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "T6",
        "hex": "16c4cf",
        "rgb": {
          "r": 22,
          "g": 196,
          "b": 207,
          "a": 1
        },
        "hsl": {
          "h": 183.56756756756758,
          "s": 0.8078602620087336,
          "l": 0.44901960784313727,
          "a": 1
        },
        "hsv": {
          "h": 183.56756756756758,
          "s": 0.893719806763285,
          "v": 0.8117647058823529,
          "a": 1
        }
      },
      "name": "color.T6",
      "path": [
        "color",
        "T6"
      ]
    },
    "T7": {
      "value": "rgba(15, 169, 182, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "T7",
        "hex": "0fa9b6",
        "rgb": {
          "r": 15,
          "g": 169,
          "b": 182,
          "a": 1
        },
        "hsl": {
          "h": 184.67065868263472,
          "s": 0.8477157360406091,
          "l": 0.3862745098039216,
          "a": 1
        },
        "hsv": {
          "h": 184.67065868263472,
          "s": 0.9175824175824175,
          "v": 0.7137254901960784,
          "a": 1
        }
      },
      "name": "color.T7",
      "path": [
        "color",
        "T7"
      ]
    },
    "T8": {
      "value": "rgba(7, 142, 158, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "T8",
        "hex": "078e9e",
        "rgb": {
          "r": 7,
          "g": 142,
          "b": 158,
          "a": 1
        },
        "hsl": {
          "h": 186.35761589403972,
          "s": 0.9151515151515152,
          "l": 0.3235294117647059,
          "a": 1
        },
        "hsv": {
          "h": 186.35761589403972,
          "s": 0.9556962025316456,
          "v": 0.6196078431372549,
          "a": 1
        }
      },
      "name": "color.T8",
      "path": [
        "color",
        "T8"
      ]
    },
    "T9": {
      "value": "rgba(0, 115, 133, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "T9",
        "hex": "007385",
        "rgb": {
          "r": 0,
          "g": 115,
          "b": 133,
          "a": 1
        },
        "hsl": {
          "h": 188.1203007518797,
          "s": 1,
          "l": 0.2607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 188.1203007518797,
          "s": 1,
          "v": 0.5215686274509804,
          "a": 1
        }
      },
      "name": "color.T9",
      "path": [
        "color",
        "T9"
      ]
    },
    "T10": {
      "value": "rgba(0, 71, 82, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "T10",
        "hex": "004752",
        "rgb": {
          "r": 0,
          "g": 71,
          "b": 82,
          "a": 1
        },
        "hsl": {
          "h": 188.04878048780486,
          "s": 1,
          "l": 0.1607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 188.04878048780486,
          "s": 1,
          "v": 0.3215686274509804,
          "a": 1
        }
      },
      "name": "color.T10",
      "path": [
        "color",
        "T10"
      ]
    },
    "A1": {
      "value": "rgba(242, 252, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "A1",
        "hex": "f2fcff",
        "rgb": {
          "r": 242,
          "g": 252,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 193.8461538461538,
          "s": 1,
          "l": 0.9745098039215686,
          "a": 1
        },
        "hsv": {
          "h": 193.8461538461538,
          "s": 0.050980392156862786,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.A1",
      "path": [
        "color",
        "A1"
      ]
    },
    "A2": {
      "value": "rgba(224, 249, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "A2",
        "hex": "e0f9ff",
        "rgb": {
          "r": 224,
          "g": 249,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 191.6129032258065,
          "s": 1,
          "l": 0.9392156862745098,
          "a": 1
        },
        "hsv": {
          "h": 191.6129032258065,
          "s": 0.1215686274509804,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.A2",
      "path": [
        "color",
        "A2"
      ]
    },
    "A3": {
      "value": "rgba(181, 240, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "A3",
        "hex": "b5f0ff",
        "rgb": {
          "r": 181,
          "g": 240,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 192.16216216216216,
          "s": 1,
          "l": 0.8549019607843138,
          "a": 1
        },
        "hsv": {
          "h": 192.16216216216216,
          "s": 0.2901960784313725,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.A3",
      "path": [
        "color",
        "A3"
      ]
    },
    "A4": {
      "value": "rgba(133, 233, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "A4",
        "hex": "85e9ff",
        "rgb": {
          "r": 133,
          "g": 233,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 190.81967213114754,
          "s": 1,
          "l": 0.7607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 190.81967213114754,
          "s": 0.4784313725490196,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.A4",
      "path": [
        "color",
        "A4"
      ]
    },
    "A5": {
      "value": "rgba(77, 225, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "A5",
        "hex": "4de1ff",
        "rgb": {
          "r": 77,
          "g": 225,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 190.11235955056182,
          "s": 1,
          "l": 0.6509803921568628,
          "a": 1
        },
        "hsv": {
          "h": 190.11235955056182,
          "s": 0.6980392156862745,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.A5",
      "path": [
        "color",
        "A5"
      ]
    },
    "A6": {
      "value": "rgba(0, 213, 255, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "A6",
        "hex": "00d5ff",
        "rgb": {
          "r": 0,
          "g": 213,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 189.88235294117646,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 189.88235294117646,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.A6",
      "path": [
        "color",
        "A6"
      ]
    },
    "A7": {
      "value": "rgba(0, 193, 232, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "A7",
        "hex": "00c1e8",
        "rgb": {
          "r": 0,
          "g": 193,
          "b": 232,
          "a": 1
        },
        "hsl": {
          "h": 190.08620689655174,
          "s": 1,
          "l": 0.4549019607843137,
          "a": 1
        },
        "hsv": {
          "h": 190.08620689655174,
          "s": 1,
          "v": 0.9098039215686274,
          "a": 1
        }
      },
      "name": "color.A7",
      "path": [
        "color",
        "A7"
      ]
    },
    "A8": {
      "value": "rgba(1, 162, 202, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "A8",
        "hex": "01a2ca",
        "rgb": {
          "r": 1,
          "g": 162,
          "b": 202,
          "a": 1
        },
        "hsl": {
          "h": 191.94029850746267,
          "s": 0.9901477832512315,
          "l": 0.3980392156862745,
          "a": 1
        },
        "hsv": {
          "h": 191.94029850746267,
          "s": 0.995049504950495,
          "v": 0.792156862745098,
          "a": 1
        }
      },
      "name": "color.A8",
      "path": [
        "color",
        "A8"
      ]
    },
    "A9": {
      "value": "rgba(2, 120, 167, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "A9",
        "hex": "0278a7",
        "rgb": {
          "r": 2,
          "g": 120,
          "b": 167,
          "a": 1
        },
        "hsl": {
          "h": 197.09090909090912,
          "s": 0.9763313609467457,
          "l": 0.33137254901960783,
          "a": 1
        },
        "hsv": {
          "h": 197.09090909090912,
          "s": 0.9880239520958084,
          "v": 0.6549019607843137,
          "a": 1
        }
      },
      "name": "color.A9",
      "path": [
        "color",
        "A9"
      ]
    },
    "A10": {
      "value": "rgba(5, 73, 118, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "A10",
        "hex": "054976",
        "rgb": {
          "r": 5,
          "g": 73,
          "b": 118,
          "a": 1
        },
        "hsl": {
          "h": 203.89380530973452,
          "s": 0.91869918699187,
          "l": 0.2411764705882353,
          "a": 1
        },
        "hsv": {
          "h": 203.89380530973452,
          "s": 0.9576271186440678,
          "v": 0.4627450980392157,
          "a": 1
        }
      },
      "name": "color.A10",
      "path": [
        "color",
        "A10"
      ]
    },
    "B1": {
      "value": "rgba(240, 247, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "B1",
        "hex": "f0f7ff",
        "rgb": {
          "r": 240,
          "g": 247,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 212,
          "s": 1,
          "l": 0.9705882352941176,
          "a": 1
        },
        "hsv": {
          "h": 212,
          "s": 0.05882352941176472,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.B1",
      "path": [
        "color",
        "B1"
      ]
    },
    "B2": {
      "value": "rgba(213, 234, 254, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "B2",
        "hex": "d5eafe",
        "rgb": {
          "r": 213,
          "g": 234,
          "b": 254,
          "a": 1
        },
        "hsl": {
          "h": 209.26829268292687,
          "s": 0.9534883720930234,
          "l": 0.915686274509804,
          "a": 1
        },
        "hsv": {
          "h": 209.26829268292687,
          "s": 0.16141732283464566,
          "v": 0.996078431372549,
          "a": 1
        }
      },
      "name": "color.B2",
      "path": [
        "color",
        "B2"
      ]
    },
    "B3": {
      "value": "rgba(128, 189, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "B3",
        "hex": "80bdff",
        "rgb": {
          "r": 128,
          "g": 189,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 211.1811023622047,
          "s": 1,
          "l": 0.7509803921568627,
          "a": 1
        },
        "hsv": {
          "h": 211.1811023622047,
          "s": 0.4980392156862745,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.B3",
      "path": [
        "color",
        "B3"
      ]
    },
    "B4": {
      "value": "rgba(47, 133, 247, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "B4",
        "hex": "2f85f7",
        "rgb": {
          "r": 47,
          "g": 133,
          "b": 247,
          "a": 1
        },
        "hsl": {
          "h": 214.2,
          "s": 0.925925925925926,
          "l": 0.5764705882352941,
          "a": 1
        },
        "hsv": {
          "h": 214.2,
          "s": 0.8097165991902834,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "name": "color.B4",
      "path": [
        "color",
        "B4"
      ]
    },
    "B5": {
      "value": "rgba(35, 118, 229, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "B5",
        "hex": "2376e5",
        "rgb": {
          "r": 35,
          "g": 118,
          "b": 229,
          "a": 1
        },
        "hsl": {
          "h": 214.3298969072165,
          "s": 0.7886178861788616,
          "l": 0.5176470588235295,
          "a": 1
        },
        "hsv": {
          "h": 214.3298969072165,
          "s": 0.8471615720524017,
          "v": 0.8980392156862745,
          "a": 1
        }
      },
      "name": "color.B5",
      "path": [
        "color",
        "B5"
      ]
    },
    "B6": {
      "value": "rgba(38, 94, 212, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "B6",
        "hex": "265ed4",
        "rgb": {
          "r": 38,
          "g": 94,
          "b": 212,
          "a": 1
        },
        "hsl": {
          "h": 220.68965517241378,
          "s": 0.696,
          "l": 0.4901960784313726,
          "a": 1
        },
        "hsv": {
          "h": 220.68965517241378,
          "s": 0.820754716981132,
          "v": 0.8313725490196079,
          "a": 1
        }
      },
      "name": "color.B6",
      "path": [
        "color",
        "B6"
      ]
    },
    "B7": {
      "value": "rgba(26, 76, 174, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "B7",
        "hex": "1a4cae",
        "rgb": {
          "r": 26,
          "g": 76,
          "b": 174,
          "a": 1
        },
        "hsl": {
          "h": 219.7297297297297,
          "s": 0.7400000000000001,
          "l": 0.39215686274509803,
          "a": 1
        },
        "hsv": {
          "h": 219.7297297297297,
          "s": 0.8505747126436782,
          "v": 0.6823529411764706,
          "a": 1
        }
      },
      "name": "color.B7",
      "path": [
        "color",
        "B7"
      ]
    },
    "B8": {
      "value": "rgba(27, 69, 150, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "B8",
        "hex": "1b4596",
        "rgb": {
          "r": 27,
          "g": 69,
          "b": 150,
          "a": 1
        },
        "hsl": {
          "h": 219.5121951219512,
          "s": 0.6949152542372882,
          "l": 0.34705882352941175,
          "a": 1
        },
        "hsv": {
          "h": 219.5121951219512,
          "s": 0.82,
          "v": 0.5882352941176471,
          "a": 1
        }
      },
      "name": "color.B8",
      "path": [
        "color",
        "B8"
      ]
    },
    "B9": {
      "value": "rgba(29, 61, 126, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "B9",
        "hex": "1d3d7e",
        "rgb": {
          "r": 29,
          "g": 61,
          "b": 126,
          "a": 1
        },
        "hsl": {
          "h": 220.20618556701032,
          "s": 0.6258064516129033,
          "l": 0.303921568627451,
          "a": 1
        },
        "hsv": {
          "h": 220.20618556701032,
          "s": 0.7698412698412699,
          "v": 0.49411764705882355,
          "a": 1
        }
      },
      "name": "color.B9",
      "path": [
        "color",
        "B9"
      ]
    },
    "B10": {
      "value": "rgba(34, 51, 103, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "B10",
        "hex": "223367",
        "rgb": {
          "r": 34,
          "g": 51,
          "b": 103,
          "a": 1
        },
        "hsl": {
          "h": 225.2173913043478,
          "s": 0.5036496350364964,
          "l": 0.26862745098039215,
          "a": 1
        },
        "hsv": {
          "h": 225.2173913043478,
          "s": 0.6699029126213593,
          "v": 0.403921568627451,
          "a": 1
        }
      },
      "name": "color.B10",
      "path": [
        "color",
        "B10"
      ]
    },
    "P1": {
      "value": "rgba(248, 247, 253, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "P1",
        "hex": "f8f7fd",
        "rgb": {
          "r": 248,
          "g": 247,
          "b": 253,
          "a": 1
        },
        "hsl": {
          "h": 249.99999999999994,
          "s": 0.6000000000000011,
          "l": 0.9803921568627452,
          "a": 1
        },
        "hsv": {
          "h": 249.99999999999994,
          "s": 0.023715415019762872,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "name": "color.P1",
      "path": [
        "color",
        "P1"
      ]
    },
    "P2": {
      "value": "rgba(230, 227, 247, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "P2",
        "hex": "e6e3f7",
        "rgb": {
          "r": 230,
          "g": 227,
          "b": 247,
          "a": 1
        },
        "hsl": {
          "h": 249.00000000000003,
          "s": 0.5555555555555561,
          "l": 0.9294117647058824,
          "a": 1
        },
        "hsv": {
          "h": 249.00000000000003,
          "s": 0.0809716599190284,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "name": "color.P2",
      "path": [
        "color",
        "P2"
      ]
    },
    "P3": {
      "value": "rgba(207, 201, 241, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "P3",
        "hex": "cfc9f1",
        "rgb": {
          "r": 207,
          "g": 201,
          "b": 241,
          "a": 1
        },
        "hsl": {
          "h": 249.00000000000003,
          "s": 0.588235294117647,
          "l": 0.8666666666666667,
          "a": 1
        },
        "hsv": {
          "h": 249.00000000000003,
          "s": 0.16597510373443983,
          "v": 0.9450980392156862,
          "a": 1
        }
      },
      "name": "color.P3",
      "path": [
        "color",
        "P3"
      ]
    },
    "P4": {
      "value": "rgba(183, 175, 235, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "P4",
        "hex": "b7afeb",
        "rgb": {
          "r": 183,
          "g": 175,
          "b": 235,
          "a": 1
        },
        "hsl": {
          "h": 248.00000000000003,
          "s": 0.5999999999999996,
          "l": 0.803921568627451,
          "a": 1
        },
        "hsv": {
          "h": 248.00000000000003,
          "s": 0.25531914893617014,
          "v": 0.9215686274509803,
          "a": 1
        }
      },
      "name": "color.P4",
      "path": [
        "color",
        "P4"
      ]
    },
    "P5": {
      "value": "rgba(160, 148, 228, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "P5",
        "hex": "a094e4",
        "rgb": {
          "r": 160,
          "g": 148,
          "b": 228,
          "a": 1
        },
        "hsl": {
          "h": 248.99999999999994,
          "s": 0.5970149253731343,
          "l": 0.7372549019607844,
          "a": 1
        },
        "hsv": {
          "h": 248.99999999999994,
          "s": 0.3508771929824561,
          "v": 0.8941176470588236,
          "a": 1
        }
      },
      "name": "color.P5",
      "path": [
        "color",
        "P5"
      ]
    },
    "P6": {
      "value": "rgba(136, 122, 222, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "P6",
        "hex": "887ade",
        "rgb": {
          "r": 136,
          "g": 122,
          "b": 222,
          "a": 1
        },
        "hsl": {
          "h": 248.39999999999998,
          "s": 0.6024096385542169,
          "l": 0.6745098039215687,
          "a": 1
        },
        "hsv": {
          "h": 248.39999999999998,
          "s": 0.45045045045045046,
          "v": 0.8705882352941177,
          "a": 1
        }
      },
      "name": "color.P6",
      "path": [
        "color",
        "P6"
      ]
    },
    "P7": {
      "value": "rgba(114, 101, 193, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "P7",
        "hex": "7265c1",
        "rgb": {
          "r": 114,
          "g": 101,
          "b": 193,
          "a": 1
        },
        "hsl": {
          "h": 248.4782608695652,
          "s": 0.4259259259259259,
          "l": 0.5764705882352941,
          "a": 1
        },
        "hsv": {
          "h": 248.4782608695652,
          "s": 0.4766839378238342,
          "v": 0.7568627450980392,
          "a": 1
        }
      },
      "name": "color.P7",
      "path": [
        "color",
        "P7"
      ]
    },
    "P8": {
      "value": "rgba(92, 80, 164, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "P8",
        "hex": "5c50a4",
        "rgb": {
          "r": 92,
          "g": 80,
          "b": 164,
          "a": 1
        },
        "hsl": {
          "h": 248.5714285714286,
          "s": 0.34426229508196726,
          "l": 0.47843137254901963,
          "a": 1
        },
        "hsv": {
          "h": 248.5714285714286,
          "s": 0.5121951219512195,
          "v": 0.6431372549019608,
          "a": 1
        }
      },
      "name": "color.P8",
      "path": [
        "color",
        "P8"
      ]
    },
    "P9": {
      "value": "rgba(70, 59, 135, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "P9",
        "hex": "463b87",
        "rgb": {
          "r": 70,
          "g": 59,
          "b": 135,
          "a": 1
        },
        "hsl": {
          "h": 248.68421052631578,
          "s": 0.3917525773195876,
          "l": 0.3803921568627451,
          "a": 1
        },
        "hsv": {
          "h": 248.68421052631578,
          "s": 0.562962962962963,
          "v": 0.5294117647058824,
          "a": 1
        }
      },
      "name": "color.P9",
      "path": [
        "color",
        "P9"
      ]
    },
    "P10": {
      "value": "rgba(39, 31, 85, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "P10",
        "hex": "271f55",
        "rgb": {
          "r": 39,
          "g": 31,
          "b": 85,
          "a": 1
        },
        "hsl": {
          "h": 248.88888888888889,
          "s": 0.4655172413793103,
          "l": 0.22745098039215686,
          "a": 1
        },
        "hsv": {
          "h": 248.88888888888889,
          "s": 0.6352941176470588,
          "v": 0.3333333333333333,
          "a": 1
        }
      },
      "name": "color.P10",
      "path": [
        "color",
        "P10"
      ]
    },
    "M1": {
      "value": "rgba(253, 247, 252, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "M1",
        "hex": "fdf7fc",
        "rgb": {
          "r": 253,
          "g": 247,
          "b": 252,
          "a": 1
        },
        "hsl": {
          "h": 310,
          "s": 0.6000000000000011,
          "l": 0.9803921568627452,
          "a": 1
        },
        "hsv": {
          "h": 310,
          "s": 0.023715415019762872,
          "v": 0.9921568627450981,
          "a": 1
        }
      },
      "name": "color.M1",
      "path": [
        "color",
        "M1"
      ]
    },
    "M2": {
      "value": "rgba(246, 223, 243, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "M2",
        "hex": "f6dff3",
        "rgb": {
          "r": 246,
          "g": 223,
          "b": 243,
          "a": 1
        },
        "hsl": {
          "h": 307.8260869565218,
          "s": 0.5609756097560981,
          "l": 0.919607843137255,
          "a": 1
        },
        "hsv": {
          "h": 307.8260869565218,
          "s": 0.0934959349593496,
          "v": 0.9647058823529412,
          "a": 1
        }
      },
      "name": "color.M2",
      "path": [
        "color",
        "M2"
      ]
    },
    "M3": {
      "value": "rgba(239, 192, 232, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "M3",
        "hex": "efc0e8",
        "rgb": {
          "r": 239,
          "g": 192,
          "b": 232,
          "a": 1
        },
        "hsl": {
          "h": 308.93617021276594,
          "s": 0.5949367088607596,
          "l": 0.8450980392156863,
          "a": 1
        },
        "hsv": {
          "h": 308.93617021276594,
          "s": 0.19665271966527198,
          "v": 0.9372549019607843,
          "a": 1
        }
      },
      "name": "color.M3",
      "path": [
        "color",
        "M3"
      ]
    },
    "M4": {
      "value": "rgba(231, 162, 222, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "M4",
        "hex": "e7a2de",
        "rgb": {
          "r": 231,
          "g": 162,
          "b": 222,
          "a": 1
        },
        "hsl": {
          "h": 307.8260869565217,
          "s": 0.5897435897435896,
          "l": 0.7705882352941176,
          "a": 1
        },
        "hsv": {
          "h": 307.8260869565217,
          "s": 0.29870129870129875,
          "v": 0.9058823529411765,
          "a": 1
        }
      },
      "name": "color.M4",
      "path": [
        "color",
        "M4"
      ]
    },
    "M5": {
      "value": "rgba(224, 131, 211, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "M5",
        "hex": "e083d3",
        "rgb": {
          "r": 224,
          "g": 131,
          "b": 211,
          "a": 1
        },
        "hsl": {
          "h": 308.38709677419354,
          "s": 0.6,
          "l": 0.696078431372549,
          "a": 1
        },
        "hsv": {
          "h": 308.38709677419354,
          "s": 0.41517857142857145,
          "v": 0.8784313725490196,
          "a": 1
        }
      },
      "name": "color.M5",
      "path": [
        "color",
        "M5"
      ]
    },
    "M6": {
      "value": "rgba(216, 100, 201, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "M6",
        "hex": "d864c9",
        "rgb": {
          "r": 216,
          "g": 100,
          "b": 201,
          "a": 1
        },
        "hsl": {
          "h": 307.7586206896552,
          "s": 0.5979381443298969,
          "l": 0.6196078431372549,
          "a": 1
        },
        "hsv": {
          "h": 307.7586206896552,
          "s": 0.5370370370370371,
          "v": 0.8470588235294118,
          "a": 1
        }
      },
      "name": "color.M6",
      "path": [
        "color",
        "M6"
      ]
    },
    "M7": {
      "value": "rgba(190, 84, 176, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "M7",
        "hex": "be54b0",
        "rgb": {
          "r": 190,
          "g": 84,
          "b": 176,
          "a": 1
        },
        "hsl": {
          "h": 307.92452830188677,
          "s": 0.4491525423728814,
          "l": 0.5372549019607843,
          "a": 1
        },
        "hsv": {
          "h": 307.92452830188677,
          "s": 0.5578947368421053,
          "v": 0.7450980392156863,
          "a": 1
        }
      },
      "name": "color.M7",
      "path": [
        "color",
        "M7"
      ]
    },
    "M8": {
      "value": "rgba(164, 67, 151, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "M8",
        "hex": "a44397",
        "rgb": {
          "r": 164,
          "g": 67,
          "b": 151,
          "a": 1
        },
        "hsl": {
          "h": 308.0412371134021,
          "s": 0.4199134199134199,
          "l": 0.4529411764705883,
          "a": 1
        },
        "hsv": {
          "h": 308.0412371134021,
          "s": 0.5914634146341463,
          "v": 0.6431372549019608,
          "a": 1
        }
      },
      "name": "color.M8",
      "path": [
        "color",
        "M8"
      ]
    },
    "M9": {
      "value": "rgba(138, 51, 126, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "M9",
        "hex": "8a337e",
        "rgb": {
          "r": 138,
          "g": 51,
          "b": 126,
          "a": 1
        },
        "hsl": {
          "h": 308.27586206896547,
          "s": 0.46031746031746024,
          "l": 0.37058823529411766,
          "a": 1
        },
        "hsv": {
          "h": 308.27586206896547,
          "s": 0.6304347826086956,
          "v": 0.5411764705882353,
          "a": 1
        }
      },
      "name": "color.M9",
      "path": [
        "color",
        "M9"
      ]
    },
    "M10": {
      "value": "rgba(101, 41, 93, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "M10",
        "hex": "65295d",
        "rgb": {
          "r": 101,
          "g": 41,
          "b": 93,
          "a": 1
        },
        "hsl": {
          "h": 308,
          "s": 0.42253521126760557,
          "l": 0.2784313725490196,
          "a": 1
        },
        "hsv": {
          "h": 308,
          "s": 0.594059405940594,
          "v": 0.396078431372549,
          "a": 1
        }
      },
      "name": "color.M10",
      "path": [
        "color",
        "M10"
      ]
    },
    "N1": {
      "value": "rgba(255, 255, 255, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "N1",
        "hex": "ffffff",
        "rgb": {
          "r": 255,
          "g": 255,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 1,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 1,
          "a": 1
        }
      },
      "name": "color.N1",
      "path": [
        "color",
        "N1"
      ]
    },
    "N2": {
      "value": "rgba(248, 249, 250, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "N2",
        "hex": "f8f9fa",
        "rgb": {
          "r": 248,
          "g": 249,
          "b": 250,
          "a": 1
        },
        "hsl": {
          "h": 210,
          "s": 0.16666666666666627,
          "l": 0.9764705882352941,
          "a": 1
        },
        "hsv": {
          "h": 210,
          "s": 0.007999999999999972,
          "v": 0.9803921568627451,
          "a": 1
        }
      },
      "name": "color.N2",
      "path": [
        "color",
        "N2"
      ]
    },
    "N3": {
      "value": "rgba(240, 244, 247, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "N3",
        "hex": "f0f4f7",
        "rgb": {
          "r": 240,
          "g": 244,
          "b": 247,
          "a": 1
        },
        "hsl": {
          "h": 205.7142857142856,
          "s": 0.304347826086957,
          "l": 0.9549019607843137,
          "a": 1
        },
        "hsv": {
          "h": 205.7142857142856,
          "s": 0.028340080971659933,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "name": "color.N3",
      "path": [
        "color",
        "N3"
      ]
    },
    "N4": {
      "value": "rgba(232, 236, 242, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "N4",
        "hex": "e8ecf2",
        "rgb": {
          "r": 232,
          "g": 236,
          "b": 242,
          "a": 1
        },
        "hsl": {
          "h": 215.99999999999991,
          "s": 0.27777777777777746,
          "l": 0.9294117647058824,
          "a": 1
        },
        "hsv": {
          "h": 215.99999999999991,
          "s": 0.04132231404958675,
          "v": 0.9490196078431372,
          "a": 1
        }
      },
      "name": "color.N4",
      "path": [
        "color",
        "N4"
      ]
    },
    "N5": {
      "value": "rgba(213, 218, 227, 1)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "N5",
        "hex": "d5dae3",
        "rgb": {
          "r": 213,
          "g": 218,
          "b": 227,
          "a": 1
        },
        "hsl": {
          "h": 218.57142857142864,
          "s": 0.19999999999999968,
          "l": 0.8627450980392157,
          "a": 1
        },
        "hsv": {
          "h": 218.57142857142864,
          "s": 0.0616740088105726,
          "v": 0.8901960784313725,
          "a": 1
        }
      },
      "name": "color.N5",
      "path": [
        "color",
        "N5"
      ]
    },
    "N6": {
      "value": "rgba(174, 183, 196, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "N6",
        "hex": "aeb7c4",
        "rgb": {
          "r": 174,
          "g": 183,
          "b": 196,
          "a": 1
        },
        "hsl": {
          "h": 215.45454545454547,
          "s": 0.15714285714285717,
          "l": 0.7254901960784315,
          "a": 1
        },
        "hsv": {
          "h": 215.45454545454547,
          "s": 0.1122448979591837,
          "v": 0.7686274509803922,
          "a": 1
        }
      },
      "name": "color.N6",
      "path": [
        "color",
        "N6"
      ]
    },
    "N7": {
      "value": "rgba(140, 150, 163, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "N7",
        "hex": "8c96a3",
        "rgb": {
          "r": 140,
          "g": 150,
          "b": 163,
          "a": 1
        },
        "hsl": {
          "h": 213.91304347826087,
          "s": 0.111111111111111,
          "l": 0.5941176470588235,
          "a": 1
        },
        "hsv": {
          "h": 213.91304347826087,
          "s": 0.14110429447852746,
          "v": 0.6392156862745098,
          "a": 1
        }
      },
      "name": "color.N7",
      "path": [
        "color",
        "N7"
      ]
    },
    "N8": {
      "value": "rgba(97, 108, 122, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "N8",
        "hex": "616c7a",
        "rgb": {
          "r": 97,
          "g": 108,
          "b": 122,
          "a": 1
        },
        "hsl": {
          "h": 213.60000000000002,
          "s": 0.11415525114155255,
          "l": 0.4294117647058824,
          "a": 1
        },
        "hsv": {
          "h": 213.60000000000002,
          "s": 0.20491803278688533,
          "v": 0.47843137254901963,
          "a": 1
        }
      },
      "name": "color.N8",
      "path": [
        "color",
        "N8"
      ]
    },
    "N9": {
      "value": "rgba(71, 81, 92, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "N9",
        "hex": "47515c",
        "rgb": {
          "r": 71,
          "g": 81,
          "b": 92,
          "a": 1
        },
        "hsl": {
          "h": 211.42857142857144,
          "s": 0.12883435582822084,
          "l": 0.3196078431372549,
          "a": 1
        },
        "hsv": {
          "h": 211.42857142857144,
          "s": 0.22826086956521735,
          "v": 0.3607843137254902,
          "a": 1
        }
      },
      "name": "color.N9",
      "path": [
        "color",
        "N9"
      ]
    },
    "N10": {
      "value": "rgba(48, 57, 69, 1)",
      "$type": "color",
      "attributes": {
        "useContrastText": true,
        "category": "color",
        "deprecated": true,
        "type": "N10",
        "hex": "303945",
        "rgb": {
          "r": 48,
          "g": 57,
          "b": 69,
          "a": 1
        },
        "hsl": {
          "h": 214.28571428571428,
          "s": 0.17948717948717946,
          "l": 0.22941176470588234,
          "a": 1
        },
        "hsv": {
          "h": 214.28571428571428,
          "s": 0.3043478260869565,
          "v": 0.27058823529411763,
          "a": 1
        }
      },
      "name": "color.N10",
      "path": [
        "color",
        "N10"
      ]
    },
    "transparent": {
      "value": "rgba(0, 0, 0, 0)",
      "$type": "color",
      "attributes": {
        "category": "color",
        "deprecated": true,
        "type": "transparent",
        "hex": "000000",
        "rgb": {
          "r": 0,
          "g": 0,
          "b": 0,
          "a": 0
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0,
          "a": 0
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0,
          "a": 0
        }
      },
      "name": "color.transparent",
      "path": [
        "color",
        "transparent"
      ]
    }
  },
  "font": {
    "family": {
      "body": {
        "value": "Manrope, Arial, sans-serif",
        "$type": "fontFamily",
        "name": "font.family.body",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "body"
        },
        "path": [
          "font",
          "family",
          "body"
        ]
      },
      "heading": {
        "value": "Telegraf, Manrope, Arial, sans-serif",
        "$type": "fontFamily",
        "name": "font.family.heading",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "heading"
        },
        "path": [
          "font",
          "family",
          "heading"
        ]
      },
      "monospace": {
        "value": "'Space Mono', monospace",
        "$type": "fontFamily",
        "name": "font.family.monospace",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "monospace"
        },
        "path": [
          "font",
          "family",
          "monospace"
        ]
      }
    },
    "size": {
      "4xl": {
        "value": "2.25rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "4xl"
        },
        "$type": "dimension",
        "name": "font.size.4xl",
        "path": [
          "font",
          "size",
          "4xl"
        ]
      },
      "3xl": {
        "value": "2rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "3xl"
        },
        "$type": "dimension",
        "name": "font.size.3xl",
        "path": [
          "font",
          "size",
          "3xl"
        ]
      },
      "2xl": {
        "value": "1.5rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "2xl"
        },
        "$type": "dimension",
        "name": "font.size.2xl",
        "path": [
          "font",
          "size",
          "2xl"
        ]
      },
      "xl": {
        "value": "1.25rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "xl"
        },
        "$type": "dimension",
        "name": "font.size.xl",
        "path": [
          "font",
          "size",
          "xl"
        ]
      },
      "lg": {
        "value": "1.125rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "lg"
        },
        "$type": "dimension",
        "name": "font.size.lg",
        "path": [
          "font",
          "size",
          "lg"
        ]
      },
      "md": {
        "value": "1rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "md"
        },
        "$type": "dimension",
        "name": "font.size.md",
        "path": [
          "font",
          "size",
          "md"
        ]
      },
      "sm": {
        "value": "0.875rem",
        "attributes": {
          "category": "size",
          "type": "size",
          "item": "sm"
        },
        "$type": "dimension",
        "name": "font.size.sm",
        "path": [
          "font",
          "size",
          "sm"
        ]
      }
    },
    "weight": {
      "light": {
        "value": 300,
        "$type": "fontWeight",
        "name": "font.weight.light",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "light"
        },
        "path": [
          "font",
          "weight",
          "light"
        ]
      },
      "regular": {
        "value": 400,
        "$type": "fontWeight",
        "name": "font.weight.regular",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "regular"
        },
        "path": [
          "font",
          "weight",
          "regular"
        ]
      },
      "medium": {
        "value": 500,
        "$type": "fontWeight",
        "name": "font.weight.medium",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "medium"
        },
        "path": [
          "font",
          "weight",
          "medium"
        ]
      },
      "semiBold": {
        "value": 600,
        "$type": "fontWeight",
        "name": "font.weight.semiBold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "semiBold"
        },
        "path": [
          "font",
          "weight",
          "semiBold"
        ]
      },
      "bold": {
        "value": 700,
        "$type": "fontWeight",
        "name": "font.weight.bold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "bold"
        },
        "path": [
          "font",
          "weight",
          "bold"
        ]
      }
    }
  },
  "palette": {
    "light": {
      "background": {
        "default": {
          "value": "rgba(250, 242, 233, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "background",
            "subitem": "default",
            "hex": "faf2e9",
            "rgb": {
              "r": 250,
              "g": 242,
              "b": 233,
              "a": 1
            },
            "hsl": {
              "h": 31.764705882352935,
              "s": 0.6296296296296298,
              "l": 0.9470588235294117,
              "a": 1
            },
            "hsv": {
              "h": 31.764705882352935,
              "s": 0.06799999999999999,
              "v": 0.9803921568627451,
              "a": 1
            }
          },
          "name": "palette.light.background.default",
          "path": [
            "palette",
            "light",
            "background",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(3, 38, 41, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "background",
            "subitem": "reversed",
            "hex": "032629",
            "rgb": {
              "r": 3,
              "g": 38,
              "b": 41,
              "a": 1
            },
            "hsl": {
              "h": 184.73684210526318,
              "s": 0.8636363636363638,
              "l": 0.08627450980392157,
              "a": 1
            },
            "hsv": {
              "h": 184.73684210526318,
              "s": 0.926829268292683,
              "v": 0.1607843137254902,
              "a": 1
            }
          },
          "name": "palette.light.background.reversed",
          "path": [
            "palette",
            "light",
            "background",
            "reversed"
          ]
        },
        "opacity-100": {
          "default": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.background.opacity100.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "background",
              "subitem": "opacity-100",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "background",
              "opacity-100",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.background.opacity100.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "background",
              "subitem": "opacity-100",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "background",
              "opacity-100",
              "reversed"
            ]
          }
        },
        "opacity-10": {
          "reversed": {
            "value": "rgba(3, 38, 41, 0.1)",
            "name": "palette.light.background.opacity10.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "background",
              "subitem": "opacity-10",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "background",
              "opacity-10",
              "reversed"
            ]
          },
          "default": {
            "value": "rgba(250, 242, 233, 0.1)",
            "name": "palette.light.background.opacity10.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "background",
              "subitem": "opacity-10",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "background",
              "opacity-10",
              "default"
            ]
          }
        },
        "opacity-4": {
          "default": {
            "value": "rgba(250, 242, 233, 0.04)",
            "name": "palette.light.background.opacity4.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "background",
              "subitem": "opacity-4",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "background",
              "opacity-4",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(3, 38, 41, 0.04)",
            "name": "palette.light.background.opacity4.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "background",
              "subitem": "opacity-4",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "background",
              "opacity-4",
              "reversed"
            ]
          }
        }
      },
      "text": {
        "default": {
          "value": "rgba(10, 50, 53, 1)",
          "name": "palette.light.text.default",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "text",
            "subitem": "default"
          },
          "path": [
            "palette",
            "light",
            "text",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(250, 242, 233, 1)",
          "name": "palette.light.text.reversed",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "text",
            "subitem": "reversed"
          },
          "path": [
            "palette",
            "light",
            "text",
            "reversed"
          ]
        },
        "placeholder": {
          "value": "rgba(133, 133, 133, 1)",
          "name": "palette.light.text.placeholder",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "text",
            "subitem": "placeholder"
          },
          "path": [
            "palette",
            "light",
            "text",
            "placeholder"
          ]
        },
        "disabled": {
          "value": "rgba(133, 133, 133, 1)",
          "name": "palette.light.text.disabled",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "text",
            "subitem": "disabled"
          },
          "path": [
            "palette",
            "light",
            "text",
            "disabled"
          ]
        },
        "error": {
          "value": "rgba(253, 70, 69, 1)",
          "name": "palette.light.text.error",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "text",
            "subitem": "error"
          },
          "path": [
            "palette",
            "light",
            "text",
            "error"
          ]
        },
        "primary": {
          "default": {
            "value": "rgba(10, 50, 53, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "light",
              "item": "text",
              "subitem": "primary",
              "state": "default",
              "hex": "0a3235",
              "rgb": {
                "r": 10,
                "g": 50,
                "b": 53,
                "a": 1
              },
              "hsl": {
                "h": 184.18604651162792,
                "s": 0.6825396825396826,
                "l": 0.12352941176470589,
                "a": 1
              },
              "hsv": {
                "h": 184.18604651162792,
                "s": 0.8113207547169812,
                "v": 0.20784313725490197,
                "a": 1
              }
            },
            "name": "palette.light.text.primary.default",
            "path": [
              "palette",
              "light",
              "text",
              "primary",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "light",
              "item": "text",
              "subitem": "primary",
              "state": "reversed",
              "hex": "faf2e9",
              "rgb": {
                "r": 250,
                "g": 242,
                "b": 233,
                "a": 1
              },
              "hsl": {
                "h": 31.764705882352935,
                "s": 0.6296296296296298,
                "l": 0.9470588235294117,
                "a": 1
              },
              "hsv": {
                "h": 31.764705882352935,
                "s": 0.06799999999999999,
                "v": 0.9803921568627451,
                "a": 1
              }
            },
            "name": "palette.light.text.primary.reversed",
            "path": [
              "palette",
              "light",
              "text",
              "primary",
              "reversed"
            ]
          }
        },
        "secondary": {
          "default": {
            "value": "rgba(3, 38, 41, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "light",
              "item": "text",
              "subitem": "secondary",
              "state": "default",
              "hex": "032629",
              "rgb": {
                "r": 3,
                "g": 38,
                "b": 41,
                "a": 1
              },
              "hsl": {
                "h": 184.73684210526318,
                "s": 0.8636363636363638,
                "l": 0.08627450980392157,
                "a": 1
              },
              "hsv": {
                "h": 184.73684210526318,
                "s": 0.926829268292683,
                "v": 0.1607843137254902,
                "a": 1
              }
            },
            "name": "palette.light.text.secondary.default",
            "path": [
              "palette",
              "light",
              "text",
              "secondary",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "light",
              "item": "text",
              "subitem": "secondary",
              "state": "reversed",
              "hex": "faf2e9",
              "rgb": {
                "r": 250,
                "g": 242,
                "b": 233,
                "a": 1
              },
              "hsl": {
                "h": 31.764705882352935,
                "s": 0.6296296296296298,
                "l": 0.9470588235294117,
                "a": 1
              },
              "hsv": {
                "h": 31.764705882352935,
                "s": 0.06799999999999999,
                "v": 0.9803921568627451,
                "a": 1
              }
            },
            "name": "palette.light.text.secondary.reversed",
            "path": [
              "palette",
              "light",
              "text",
              "secondary",
              "reversed"
            ]
          }
        }
      },
      "elements": {
        "primary": {
          "fill": {
            "value": "rgba(240, 82, 156, 1)",
            "name": "palette.light.elements.primary.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "primary",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "primary",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(243, 117, 176, 1)",
              "name": "palette.light.elements.primary.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "primary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "primary",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(240, 82, 156, 1)",
              "name": "palette.light.elements.primary.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "primary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "primary",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.primary.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "primary",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "primary",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(246, 151, 196, 1)",
            "name": "palette.light.elements.primary.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "primary",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "primary",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.primary.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "primary",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "primary",
              "contrast-reversed"
            ]
          }
        },
        "secondary": {
          "fill": {
            "value": "rgba(15, 203, 140, 1)",
            "name": "palette.light.elements.secondary.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "secondary",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "secondary",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(63, 213, 163, 1)",
              "name": "palette.light.elements.secondary.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "secondary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "secondary",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(15, 203, 140, 1)",
              "name": "palette.light.elements.secondary.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "secondary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "secondary",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.secondary.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "secondary",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "secondary",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(159, 234, 209, 1)",
            "name": "palette.light.elements.secondary.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "secondary",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "secondary",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.secondary.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "secondary",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "secondary",
              "contrast-reversed"
            ]
          }
        },
        "tertiary": {
          "fill": {
            "value": "rgba(177, 27, 232, 1)",
            "name": "palette.light.elements.tertiary.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "tertiary",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "tertiary",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(193, 73, 237, 1)",
              "name": "palette.light.elements.tertiary.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "tertiary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "tertiary",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(177, 27, 232, 1)",
              "name": "palette.light.elements.tertiary.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "tertiary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "tertiary",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.tertiary.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "tertiary",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "tertiary",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(208, 118, 241, 1)",
            "name": "palette.light.elements.tertiary.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "tertiary",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "tertiary",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.tertiary.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "tertiary",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "tertiary",
              "contrast-reversed"
            ]
          }
        },
        "error": {
          "fill": {
            "value": "rgba(253, 70, 69, 1)",
            "name": "palette.light.elements.error.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "error",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "error",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(253, 107, 106, 1)",
              "name": "palette.light.elements.error.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "error",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "error",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(253, 70, 69, 1)",
              "name": "palette.light.elements.error.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "error",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "error",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.error.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "error",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "error",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(254, 144, 143, 1)",
            "name": "palette.light.elements.error.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "error",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "error",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.error.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "error",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "error",
              "contrast-reversed"
            ]
          }
        },
        "warning": {
          "fill": {
            "value": "rgba(255, 163, 28, 1)",
            "name": "palette.light.elements.warning.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "warning",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "warning",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(255, 181, 73, 1)",
              "name": "palette.light.elements.warning.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "warning",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "warning",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(255, 163, 28, 1)",
              "name": "palette.light.elements.warning.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "warning",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "warning",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.warning.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "warning",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "warning",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(255, 200, 119, 1)",
            "name": "palette.light.elements.warning.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "warning",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "warning",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.warning.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "warning",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "warning",
              "contrast-reversed"
            ]
          }
        },
        "confirmation": {
          "fill": {
            "value": "rgba(84, 156, 53, 1)",
            "name": "palette.light.elements.confirmation.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "confirmation",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "confirmation",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(118, 176, 93, 1)",
              "name": "palette.light.elements.confirmation.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "confirmation",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "confirmation",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(84, 156, 53, 1)",
              "name": "palette.light.elements.confirmation.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "confirmation",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "confirmation",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.confirmation.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "confirmation",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "confirmation",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(152, 196, 134, 1)",
            "name": "palette.light.elements.confirmation.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "confirmation",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "confirmation",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.confirmation.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "confirmation",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "confirmation",
              "contrast-reversed"
            ]
          }
        },
        "information": {
          "fill": {
            "value": "rgba(42, 136, 239, 1)",
            "name": "palette.light.elements.information.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "information",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "information",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(85, 160, 242, 1)",
              "name": "palette.light.elements.information.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "information",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "information",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(42, 136, 239, 1)",
              "name": "palette.light.elements.information.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "information",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "information",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.information.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "information",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "information",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(127, 184, 245, 1)",
            "name": "palette.light.elements.information.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "information",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "information",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.information.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "information",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "information",
              "contrast-reversed"
            ]
          }
        },
        "overrides": {
          "form": {
            "checkbox": {
              "stroke": {
                "value": "rgba(16, 82, 87, 0.8)",
                "name": "palette.light.elements.overrides.form.checkbox.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "checkbox",
                  "stroke"
                ]
              },
              "hovered": {
                "stroke": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "hovered",
                    "stroke"
                  ]
                },
                "fill": {
                  "value": "rgba(250, 242, 233, 0.2)",
                  "name": "palette.light.elements.overrides.form.checkbox.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "hovered",
                    "fill"
                  ]
                }
              },
              "fill": {
                "value": "rgba(250, 242, 233, 0.1)",
                "name": "palette.light.elements.overrides.form.checkbox.fill",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "checkbox",
                  "fill"
                ]
              },
              "error": {
                "fill": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "error",
                    "contrast"
                  ]
                }
              },
              "checked": {
                "fill": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.checked.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "checked",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.checked.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "checked",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "disabled",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "disabled",
                    "contrast"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.light.elements.overrides.form.checkbox.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "disabled",
                    "stroke"
                  ]
                }
              }
            },
            "input": {
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.light.elements.overrides.form.input.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "disabled",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(133, 133, 133, 1)",
                  "name": "palette.light.elements.overrides.form.input.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "disabled",
                    "contrast"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.light.elements.overrides.form.input.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "disabled",
                    "stroke"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(255, 255, 255, 1)",
                  "name": "palette.light.elements.overrides.form.input.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 107, 106, 1)",
                  "name": "palette.light.elements.overrides.form.input.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.input.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "error",
                    "contrast"
                  ]
                }
              },
              "fill": {
                "value": "rgba(255, 255, 255, 1)",
                "name": "palette.light.elements.overrides.form.input.fill",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "input",
                  "fill"
                ]
              },
              "stroke": {
                "value": "rgba(3, 38, 41, 0.2)",
                "name": "palette.light.elements.overrides.form.input.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "input",
                  "stroke"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(255, 255, 255, 1)",
                  "name": "palette.light.elements.overrides.form.input.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "hovered",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(15, 203, 140, 1)",
                  "name": "palette.light.elements.overrides.form.input.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "contrast": {
                "value": "rgba(240, 82, 156, 1)",
                "name": "palette.light.elements.overrides.form.input.contrast",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "input",
                  "contrast"
                ]
              }
            },
            "radio": {
              "fill": {
                "value": "rgba(250, 242, 233, 0.1)",
                "name": "palette.light.elements.overrides.form.radio.fill",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "radio",
                  "fill"
                ]
              },
              "stroke": {
                "value": "rgba(16, 82, 87, 0.8)",
                "name": "palette.light.elements.overrides.form.radio.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "radio",
                  "stroke"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(250, 242, 233, 0.2)",
                  "name": "palette.light.elements.overrides.form.radio.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "hovered",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.radio.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.radio.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.radio.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.light.elements.overrides.form.radio.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "error",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.light.elements.overrides.form.radio.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "disabled",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.light.elements.overrides.form.radio.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "disabled",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.light.elements.overrides.form.radio.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "disabled",
                    "contrast"
                  ]
                }
              },
              "checked": {
                "fill": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.radio.checked.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "checked",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.light.elements.overrides.form.radio.checked.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "checked",
                    "contrast"
                  ]
                }
              }
            },
            "switch": {
              "fill": {
                "value": "rgba(250, 242, 233, 0.1)",
                "name": "palette.light.elements.overrides.form.switch.fill",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "switch",
                  "fill"
                ]
              },
              "stroke": {
                "value": "rgba(16, 82, 87, 0.8)",
                "name": "palette.light.elements.overrides.form.switch.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "switch",
                  "stroke"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(250, 242, 233, 0.2)",
                  "name": "palette.light.elements.overrides.form.switch.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "hovered",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.switch.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "checked": {
                "fill": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.switch.checked.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "checked",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.light.elements.overrides.form.switch.checked.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "checked",
                    "contrast"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.switch.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.switch.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.light.elements.overrides.form.switch.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "error",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.light.elements.overrides.form.switch.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "disabled",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.light.elements.overrides.form.switch.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "disabled",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.light.elements.overrides.form.switch.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "disabled",
                    "contrast"
                  ]
                }
              }
            },
            "slider": {
              "fill": {
                "value": "rgba(3, 38, 41, 0.1)",
                "name": "palette.light.elements.overrides.form.slider.fill",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "slider",
                  "fill"
                ]
              },
              "contrast": {
                "value": "rgba(16, 82, 87, 0.8)",
                "name": "palette.light.elements.overrides.form.slider.contrast",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "slider",
                  "contrast"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(250, 242, 233, 0.4)",
                  "name": "palette.light.elements.overrides.form.slider.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "hovered",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.slider.hovered.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "hovered",
                    "contrast"
                  ]
                },
                "stroke": {
                  "value": "rgba(16, 82, 87, 1)",
                  "name": "palette.light.elements.overrides.form.slider.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(3, 38, 41, 0.1)",
                  "name": "palette.light.elements.overrides.form.slider.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.slider.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.light.elements.overrides.form.slider.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "error",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.light.elements.overrides.form.slider.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "disabled",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.light.elements.overrides.form.slider.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "disabled",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.light.elements.overrides.form.slider.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "disabled",
                    "contrast"
                  ]
                }
              },
              "stroke": {
                "value": "rgba(16, 82, 87, 1)",
                "name": "palette.light.elements.overrides.form.slider.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "light",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "light",
                  "elements",
                  "overrides",
                  "form",
                  "slider",
                  "stroke"
                ]
              }
            }
          },
          "button": {
            "text": {
              "hovered": {
                "fill": {
                  "value": "rgba(3, 38, 41, 0.04)",
                  "name": "palette.light.elements.overrides.button.text.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "light",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "button"
                  },
                  "path": [
                    "palette",
                    "light",
                    "elements",
                    "overrides",
                    "button",
                    "text",
                    "hovered",
                    "fill"
                  ]
                }
              }
            }
          }
        },
        "disabled": {
          "fill": {
            "value": "rgba(174, 174, 174, 1)",
            "name": "palette.light.elements.disabled.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "disabled",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "disabled",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(174, 174, 174, 1)",
              "name": "palette.light.elements.disabled.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "disabled",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "disabled",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(174, 174, 174, 1)",
              "name": "palette.light.elements.disabled.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "disabled",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "disabled",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.disabled.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "disabled",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "disabled",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(174, 174, 174, 1)",
            "name": "palette.light.elements.disabled.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "disabled",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "disabled",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(93, 93, 93, 1)",
            "name": "palette.light.elements.disabled.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "disabled",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "disabled",
              "contrast-reversed"
            ]
          }
        },
        "default": {
          "fill": {
            "value": "rgba(16, 82, 87, 1)",
            "name": "palette.light.elements.default.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "default",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "default",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(16, 82, 87, 0.8)",
              "name": "palette.light.elements.default.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "default",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "default",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(16, 82, 87, 1)",
              "name": "palette.light.elements.default.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "default",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "default",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.default.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "default",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "default",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(16, 82, 87, 0.4)",
            "name": "palette.light.elements.default.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "default",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "default",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.default.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "default",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "default",
              "contrast-reversed"
            ]
          }
        },
        "faded": {
          "fill": {
            "value": "rgba(3, 38, 41, 0.1)",
            "name": "palette.light.elements.faded.fill",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "faded",
              "state": "fill"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "faded",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(3, 38, 41, 0.4)",
              "name": "palette.light.elements.faded.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "faded",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "faded",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(3, 38, 41, 0.4)",
              "name": "palette.light.elements.faded.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "elements",
                "subitem": "faded",
                "state": "hovered"
              },
              "path": [
                "palette",
                "light",
                "elements",
                "faded",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.elements.faded.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "faded",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "faded",
              "contrast-reversed"
            ]
          },
          "stroke": {
            "value": "rgba(3, 38, 41, 0.2)",
            "name": "palette.light.elements.faded.stroke",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "faded",
              "state": "stroke"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "faded",
              "stroke"
            ]
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.elements.faded.contrast",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "elements",
              "subitem": "faded",
              "state": "contrast"
            },
            "path": [
              "palette",
              "light",
              "elements",
              "faded",
              "contrast"
            ]
          }
        }
      },
      "links": {
        "default": {
          "value": "rgba(240, 82, 156, 1)",
          "name": "palette.light.links.default",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "links",
            "subitem": "default"
          },
          "path": [
            "palette",
            "light",
            "links",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(15, 203, 140, 1)",
          "name": "palette.light.links.reversed",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "links",
            "subitem": "reversed"
          },
          "path": [
            "palette",
            "light",
            "links",
            "reversed"
          ]
        },
        "hovered": {
          "default": {
            "value": "rgba(243, 117, 176, 1)",
            "name": "palette.light.links.hovered.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "links",
              "subitem": "hovered",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "links",
              "hovered",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(63, 213, 163, 1)",
            "name": "palette.light.links.hovered.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "links",
              "subitem": "hovered",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "links",
              "hovered",
              "reversed"
            ]
          }
        }
      },
      "stroke": {
        "opacity-10": {
          "default": {
            "value": "rgba(3, 38, 41, 0.1)",
            "name": "palette.light.stroke.opacity10.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "opacity-10",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "opacity-10",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(250, 242, 233, 0.1)",
            "name": "palette.light.stroke.opacity10.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "opacity-10",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "opacity-10",
              "reversed"
            ]
          }
        },
        "opacity-100": {
          "default": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.light.stroke.opacity100.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "opacity-100",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "opacity-100",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.light.stroke.opacity100.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "opacity-100",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "opacity-100",
              "reversed"
            ]
          }
        },
        "disabled": {
          "default": {
            "value": "rgba(174, 174, 174, 1)",
            "name": "palette.light.stroke.disabled.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "disabled",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "disabled",
              "default"
            ]
          }
        },
        "opacity-4": {
          "reversed": {
            "value": "rgba(250, 242, 233, 0.04)",
            "name": "palette.light.stroke.opacity4.reversed",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "opacity-4",
              "state": "reversed"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "opacity-4",
              "reversed"
            ]
          },
          "default": {
            "value": "rgba(3, 38, 41, 0.04)",
            "name": "palette.light.stroke.opacity4.default",
            "attributes": {
              "category": "palette",
              "type": "light",
              "item": "stroke",
              "subitem": "opacity-4",
              "state": "default"
            },
            "path": [
              "palette",
              "light",
              "stroke",
              "opacity-4",
              "default"
            ]
          }
        }
      },
      "icon": {
        "default": {
          "value": "rgba(10, 50, 53, 1)",
          "name": "palette.light.icon.default",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "icon",
            "subitem": "default"
          },
          "path": [
            "palette",
            "light",
            "icon",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(250, 242, 233, 1)",
          "name": "palette.light.icon.reversed",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "icon",
            "subitem": "reversed"
          },
          "path": [
            "palette",
            "light",
            "icon",
            "reversed"
          ]
        },
        "disabled": {
          "value": "rgba(133, 133, 133, 1)",
          "name": "palette.light.icon.disabled",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "icon",
            "subitem": "disabled"
          },
          "path": [
            "palette",
            "light",
            "icon",
            "disabled"
          ]
        },
        "error": {
          "value": "rgba(253, 70, 69, 1)",
          "name": "palette.light.icon.error",
          "attributes": {
            "category": "palette",
            "type": "light",
            "item": "icon",
            "subitem": "error"
          },
          "path": [
            "palette",
            "light",
            "icon",
            "error"
          ]
        }
      },
      "charting": {
        "categorical": {
          "dark": {
            "Red": {
              "value": "rgba(255, 159, 158, 1)",
              "name": "palette.light.charting.categorical.dark.Red",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Red"
              ]
            },
            "Orange": {
              "value": "rgba(255, 212, 93, 1)",
              "name": "palette.light.charting.categorical.dark.Orange",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Orange"
              ]
            },
            "Aqua": {
              "value": "rgba(181, 240, 255, 1)",
              "name": "palette.light.charting.categorical.dark.Aqua",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Aqua"
              ]
            },
            "Teal": {
              "value": "rgba(154, 231, 236, 1)",
              "name": "palette.light.charting.categorical.dark.Teal",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Teal"
              ]
            },
            "Blue": {
              "value": "rgba(128, 189, 255, 1)",
              "name": "palette.light.charting.categorical.dark.Blue",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Blue"
              ]
            },
            "Magenta": {
              "value": "rgba(231, 162, 222, 1)",
              "name": "palette.light.charting.categorical.dark.Magenta",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Magenta"
              ]
            },
            "Purple": {
              "value": "rgba(183, 175, 235, 1)",
              "name": "palette.light.charting.categorical.dark.Purple",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Purple"
              ]
            },
            "Green": {
              "value": "rgba(112, 221, 186, 1)",
              "name": "palette.light.charting.categorical.dark.Green",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "dark",
                "Green"
              ]
            }
          },
          "light": {
            "Red": {
              "value": "rgba(147, 34, 34, 1)",
              "name": "palette.light.charting.categorical.light.Red",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Red"
              ]
            },
            "Orange": {
              "value": "rgba(221, 101, 2, 1)",
              "name": "palette.light.charting.categorical.light.Orange",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Orange"
              ]
            },
            "Aqua": {
              "value": "rgba(5, 73, 118, 1)",
              "name": "palette.light.charting.categorical.light.Aqua",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Aqua"
              ]
            },
            "Teal": {
              "value": "rgba(7, 142, 158, 1)",
              "name": "palette.light.charting.categorical.light.Teal",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Teal"
              ]
            },
            "Blue": {
              "value": "rgba(38, 94, 212, 1)",
              "name": "palette.light.charting.categorical.light.Blue",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Blue"
              ]
            },
            "Magenta": {
              "value": "rgba(101, 41, 93, 1)",
              "name": "palette.light.charting.categorical.light.Magenta",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Magenta"
              ]
            },
            "Purple": {
              "value": "rgba(92, 80, 164, 1)",
              "name": "palette.light.charting.categorical.light.Purple",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Purple"
              ]
            },
            "Green": {
              "value": "rgba(0, 83, 64, 1)",
              "name": "palette.light.charting.categorical.light.Green",
              "attributes": {
                "category": "palette",
                "type": "light",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "light",
                "charting",
                "categorical",
                "light",
                "Green"
              ]
            }
          }
        }
      },
      "accent": {
        "primary": {
          "value": "rgba(240, 82, 156, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "accent",
            "subitem": "primary",
            "hex": "f0529c",
            "rgb": {
              "r": 240,
              "g": 82,
              "b": 156,
              "a": 1
            },
            "hsl": {
              "h": 331.8987341772152,
              "s": 0.8404255319148936,
              "l": 0.6313725490196078,
              "a": 1
            },
            "hsv": {
              "h": 331.8987341772152,
              "s": 0.6583333333333333,
              "v": 0.9411764705882353,
              "a": 1
            }
          },
          "name": "palette.light.accent.primary",
          "path": [
            "palette",
            "light",
            "accent",
            "primary"
          ]
        },
        "secondary": {
          "value": "rgba(15, 203, 140, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "accent",
            "subitem": "secondary",
            "hex": "0fcb8c",
            "rgb": {
              "r": 15,
              "g": 203,
              "b": 140,
              "a": 1
            },
            "hsl": {
              "h": 159.8936170212766,
              "s": 0.8623853211009174,
              "l": 0.42745098039215684,
              "a": 1
            },
            "hsv": {
              "h": 159.8936170212766,
              "s": 0.9261083743842364,
              "v": 0.796078431372549,
              "a": 1
            }
          },
          "name": "palette.light.accent.secondary",
          "path": [
            "palette",
            "light",
            "accent",
            "secondary"
          ]
        },
        "tertiary": {
          "value": "rgba(177, 27, 232, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "accent",
            "subitem": "tertiary",
            "hex": "b11be8",
            "rgb": {
              "r": 177,
              "g": 27,
              "b": 232,
              "a": 1
            },
            "hsl": {
              "h": 283.9024390243902,
              "s": 0.8167330677290836,
              "l": 0.5078431372549019,
              "a": 1
            },
            "hsv": {
              "h": 283.9024390243902,
              "s": 0.8836206896551725,
              "v": 0.9098039215686274,
              "a": 1
            }
          },
          "name": "palette.light.accent.tertiary",
          "path": [
            "palette",
            "light",
            "accent",
            "tertiary"
          ]
        }
      },
      "alert": {
        "error": {
          "value": "rgba(253, 70, 69, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "alert",
            "subitem": "error",
            "hex": "fd4645",
            "rgb": {
              "r": 253,
              "g": 70,
              "b": 69,
              "a": 1
            },
            "hsl": {
              "h": 0.3260869565217426,
              "s": 0.9787234042553192,
              "l": 0.6313725490196078,
              "a": 1
            },
            "hsv": {
              "h": 0.3260869565217426,
              "s": 0.7272727272727274,
              "v": 0.9921568627450981,
              "a": 1
            }
          },
          "name": "palette.light.alert.error",
          "path": [
            "palette",
            "light",
            "alert",
            "error"
          ]
        },
        "warning": {
          "value": "rgba(255, 163, 28, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "alert",
            "subitem": "warning",
            "hex": "ffa31c",
            "rgb": {
              "r": 255,
              "g": 163,
              "b": 28,
              "a": 1
            },
            "hsl": {
              "h": 35.68281938325991,
              "s": 1,
              "l": 0.5549019607843138,
              "a": 1
            },
            "hsv": {
              "h": 35.68281938325991,
              "s": 0.8901960784313725,
              "v": 1,
              "a": 1
            }
          },
          "name": "palette.light.alert.warning",
          "path": [
            "palette",
            "light",
            "alert",
            "warning"
          ]
        },
        "confirmation": {
          "value": "rgba(84, 156, 53, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "alert",
            "subitem": "confirmation",
            "hex": "549c35",
            "rgb": {
              "r": 84,
              "g": 156,
              "b": 53,
              "a": 1
            },
            "hsl": {
              "h": 101.94174757281554,
              "s": 0.492822966507177,
              "l": 0.4098039215686275,
              "a": 1
            },
            "hsv": {
              "h": 101.94174757281554,
              "s": 0.6602564102564102,
              "v": 0.611764705882353,
              "a": 1
            }
          },
          "name": "palette.light.alert.confirmation",
          "path": [
            "palette",
            "light",
            "alert",
            "confirmation"
          ]
        },
        "information": {
          "value": "rgba(42, 136, 239, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "light",
            "item": "alert",
            "subitem": "information",
            "hex": "2a88ef",
            "rgb": {
              "r": 42,
              "g": 136,
              "b": 239,
              "a": 1
            },
            "hsl": {
              "h": 211.37055837563452,
              "s": 0.8602620087336245,
              "l": 0.5509803921568628,
              "a": 1
            },
            "hsv": {
              "h": 211.37055837563452,
              "s": 0.8242677824267782,
              "v": 0.9372549019607843,
              "a": 1
            }
          },
          "name": "palette.light.alert.information",
          "path": [
            "palette",
            "light",
            "alert",
            "information"
          ]
        }
      }
    },
    "dark": {
      "background": {
        "opacity-100": {
          "default": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.background.opacity100.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "background",
              "subitem": "opacity-100",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "background",
              "opacity-100",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.background.opacity100.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "background",
              "subitem": "opacity-100",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "background",
              "opacity-100",
              "reversed"
            ]
          }
        },
        "opacity-10": {
          "reversed": {
            "value": "rgba(250, 242, 233, 0.1)",
            "name": "palette.dark.background.opacity10.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "background",
              "subitem": "opacity-10",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "background",
              "opacity-10",
              "reversed"
            ]
          },
          "default": {
            "value": "rgba(3, 38, 41, 0.1)",
            "name": "palette.dark.background.opacity10.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "background",
              "subitem": "opacity-10",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "background",
              "opacity-10",
              "default"
            ]
          }
        },
        "opacity-4": {
          "default": {
            "value": "rgba(3, 38, 41, 0.04)",
            "name": "palette.dark.background.opacity4.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "background",
              "subitem": "opacity-4",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "background",
              "opacity-4",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(250, 242, 233, 0.04)",
            "name": "palette.dark.background.opacity4.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "background",
              "subitem": "opacity-4",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "background",
              "opacity-4",
              "reversed"
            ]
          }
        },
        "default": {
          "value": "rgba(3, 38, 41, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "background",
            "subitem": "default",
            "hex": "032629",
            "rgb": {
              "r": 3,
              "g": 38,
              "b": 41,
              "a": 1
            },
            "hsl": {
              "h": 184.73684210526318,
              "s": 0.8636363636363638,
              "l": 0.08627450980392157,
              "a": 1
            },
            "hsv": {
              "h": 184.73684210526318,
              "s": 0.926829268292683,
              "v": 0.1607843137254902,
              "a": 1
            }
          },
          "name": "palette.dark.background.default",
          "path": [
            "palette",
            "dark",
            "background",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(250, 242, 233, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "background",
            "subitem": "reversed",
            "hex": "faf2e9",
            "rgb": {
              "r": 250,
              "g": 242,
              "b": 233,
              "a": 1
            },
            "hsl": {
              "h": 31.764705882352935,
              "s": 0.6296296296296298,
              "l": 0.9470588235294117,
              "a": 1
            },
            "hsv": {
              "h": 31.764705882352935,
              "s": 0.06799999999999999,
              "v": 0.9803921568627451,
              "a": 1
            }
          },
          "name": "palette.dark.background.reversed",
          "path": [
            "palette",
            "dark",
            "background",
            "reversed"
          ]
        }
      },
      "text": {
        "default": {
          "value": "rgba(250, 242, 233, 1)",
          "name": "palette.dark.text.default",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "text",
            "subitem": "default"
          },
          "path": [
            "palette",
            "dark",
            "text",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(10, 50, 53, 1)",
          "name": "palette.dark.text.reversed",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "text",
            "subitem": "reversed"
          },
          "path": [
            "palette",
            "dark",
            "text",
            "reversed"
          ]
        },
        "placeholder": {
          "value": "rgba(250, 242, 233, 0.5)",
          "name": "palette.dark.text.placeholder",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "text",
            "subitem": "placeholder"
          },
          "path": [
            "palette",
            "dark",
            "text",
            "placeholder"
          ]
        },
        "disabled": {
          "value": "rgba(133, 133, 133, 1)",
          "name": "palette.dark.text.disabled",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "text",
            "subitem": "disabled"
          },
          "path": [
            "palette",
            "dark",
            "text",
            "disabled"
          ]
        },
        "error": {
          "value": "rgba(254, 144, 143, 1)",
          "name": "palette.dark.text.error",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "text",
            "subitem": "error"
          },
          "path": [
            "palette",
            "dark",
            "text",
            "error"
          ]
        },
        "primary": {
          "default": {
            "value": "rgba(250, 242, 233, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "dark",
              "item": "text",
              "subitem": "primary",
              "state": "default",
              "hex": "faf2e9",
              "rgb": {
                "r": 250,
                "g": 242,
                "b": 233,
                "a": 1
              },
              "hsl": {
                "h": 31.764705882352935,
                "s": 0.6296296296296298,
                "l": 0.9470588235294117,
                "a": 1
              },
              "hsv": {
                "h": 31.764705882352935,
                "s": 0.06799999999999999,
                "v": 0.9803921568627451,
                "a": 1
              }
            },
            "name": "palette.dark.text.primary.default",
            "path": [
              "palette",
              "dark",
              "text",
              "primary",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(10, 50, 53, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "dark",
              "item": "text",
              "subitem": "primary",
              "state": "reversed",
              "hex": "0a3235",
              "rgb": {
                "r": 10,
                "g": 50,
                "b": 53,
                "a": 1
              },
              "hsl": {
                "h": 184.18604651162792,
                "s": 0.6825396825396826,
                "l": 0.12352941176470589,
                "a": 1
              },
              "hsv": {
                "h": 184.18604651162792,
                "s": 0.8113207547169812,
                "v": 0.20784313725490197,
                "a": 1
              }
            },
            "name": "palette.dark.text.primary.reversed",
            "path": [
              "palette",
              "dark",
              "text",
              "primary",
              "reversed"
            ]
          }
        },
        "secondary": {
          "default": {
            "value": "rgba(235, 235, 235, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "dark",
              "item": "text",
              "subitem": "secondary",
              "state": "default",
              "hex": "ebebeb",
              "rgb": {
                "r": 235,
                "g": 235,
                "b": 235,
                "a": 1
              },
              "hsl": {
                "h": 0,
                "s": 0,
                "l": 0.9215686274509803,
                "a": 1
              },
              "hsv": {
                "h": 0,
                "s": 0,
                "v": 0.9215686274509803,
                "a": 1
              }
            },
            "name": "palette.dark.text.secondary.default",
            "path": [
              "palette",
              "dark",
              "text",
              "secondary",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(93, 93, 93, 1)",
            "attributes": {
              "category": "color",
              "deprecated": true,
              "type": "dark",
              "item": "text",
              "subitem": "secondary",
              "state": "reversed",
              "hex": "5d5d5d",
              "rgb": {
                "r": 93,
                "g": 93,
                "b": 93,
                "a": 1
              },
              "hsl": {
                "h": 0,
                "s": 0,
                "l": 0.36470588235294116,
                "a": 1
              },
              "hsv": {
                "h": 0,
                "s": 0,
                "v": 0.36470588235294116,
                "a": 1
              }
            },
            "name": "palette.dark.text.secondary.reversed",
            "path": [
              "palette",
              "dark",
              "text",
              "secondary",
              "reversed"
            ]
          }
        }
      },
      "elements": {
        "primary": {
          "fill": {
            "value": "rgba(240, 82, 156, 1)",
            "name": "palette.dark.elements.primary.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "primary",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "primary",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(243, 117, 176, 1)",
              "name": "palette.dark.elements.primary.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "primary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "primary",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(240, 82, 156, 1)",
              "name": "palette.dark.elements.primary.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "primary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "primary",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.primary.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "primary",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "primary",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(246, 151, 196, 1)",
            "name": "palette.dark.elements.primary.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "primary",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "primary",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.primary.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "primary",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "primary",
              "contrast-reversed"
            ]
          }
        },
        "secondary": {
          "fill": {
            "value": "rgba(15, 203, 140, 1)",
            "name": "palette.dark.elements.secondary.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "secondary",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "secondary",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(63, 213, 163, 1)",
              "name": "palette.dark.elements.secondary.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "secondary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "secondary",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(15, 203, 140, 1)",
              "name": "palette.dark.elements.secondary.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "secondary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "secondary",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.secondary.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "secondary",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "secondary",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(159, 234, 209, 1)",
            "name": "palette.dark.elements.secondary.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "secondary",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "secondary",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.secondary.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "secondary",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "secondary",
              "contrast-reversed"
            ]
          }
        },
        "tertiary": {
          "fill": {
            "value": "rgba(177, 27, 232, 1)",
            "name": "palette.dark.elements.tertiary.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "tertiary",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "tertiary",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(193, 73, 237, 1)",
              "name": "palette.dark.elements.tertiary.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "tertiary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "tertiary",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(177, 27, 232, 1)",
              "name": "palette.dark.elements.tertiary.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "tertiary",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "tertiary",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.tertiary.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "tertiary",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "tertiary",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(208, 118, 241, 1)",
            "name": "palette.dark.elements.tertiary.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "tertiary",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "tertiary",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.tertiary.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "tertiary",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "tertiary",
              "contrast-reversed"
            ]
          }
        },
        "error": {
          "fill": {
            "value": "rgba(253, 70, 69, 1)",
            "name": "palette.dark.elements.error.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "error",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "error",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(253, 107, 106, 1)",
              "name": "palette.dark.elements.error.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "error",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "error",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(253, 70, 69, 1)",
              "name": "palette.dark.elements.error.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "error",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "error",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.error.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "error",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "error",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(254, 144, 143, 1)",
            "name": "palette.dark.elements.error.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "error",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "error",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.error.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "error",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "error",
              "contrast-reversed"
            ]
          }
        },
        "warning": {
          "fill": {
            "value": "rgba(255, 163, 28, 1)",
            "name": "palette.dark.elements.warning.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "warning",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "warning",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(255, 181, 73, 1)",
              "name": "palette.dark.elements.warning.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "warning",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "warning",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(255, 163, 28, 1)",
              "name": "palette.dark.elements.warning.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "warning",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "warning",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.warning.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "warning",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "warning",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(255, 200, 119, 1)",
            "name": "palette.dark.elements.warning.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "warning",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "warning",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.warning.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "warning",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "warning",
              "contrast-reversed"
            ]
          }
        },
        "confirmation": {
          "fill": {
            "value": "rgba(84, 156, 53, 1)",
            "name": "palette.dark.elements.confirmation.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "confirmation",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "confirmation",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(118, 176, 93, 1)",
              "name": "palette.dark.elements.confirmation.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "confirmation",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "confirmation",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(84, 156, 53, 1)",
              "name": "palette.dark.elements.confirmation.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "confirmation",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "confirmation",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.confirmation.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "confirmation",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "confirmation",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(152, 196, 134, 1)",
            "name": "palette.dark.elements.confirmation.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "confirmation",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "confirmation",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.confirmation.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "confirmation",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "confirmation",
              "contrast-reversed"
            ]
          }
        },
        "information": {
          "fill": {
            "value": "rgba(42, 136, 239, 1)",
            "name": "palette.dark.elements.information.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "information",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "information",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(85, 160, 242, 1)",
              "name": "palette.dark.elements.information.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "information",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "information",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(42, 136, 239, 1)",
              "name": "palette.dark.elements.information.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "information",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "information",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.information.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "information",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "information",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(127, 184, 245, 1)",
            "name": "palette.dark.elements.information.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "information",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "information",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.information.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "information",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "information",
              "contrast-reversed"
            ]
          }
        },
        "overrides": {
          "form": {
            "checkbox": {
              "stroke": {
                "value": "rgba(250, 242, 233, 0.8)",
                "name": "palette.dark.elements.overrides.form.checkbox.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "checkbox",
                  "stroke"
                ]
              },
              "hovered": {
                "stroke": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "hovered",
                    "stroke"
                  ]
                },
                "fill": {
                  "value": "rgba(3, 38, 41, 0.2)",
                  "name": "palette.dark.elements.overrides.form.checkbox.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "hovered",
                    "fill"
                  ]
                }
              },
              "fill": {
                "value": "rgba(3, 38, 41, 0.1)",
                "name": "palette.dark.elements.overrides.form.checkbox.fill",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "checkbox",
                  "fill"
                ]
              },
              "error": {
                "fill": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "error",
                    "contrast"
                  ]
                }
              },
              "checked": {
                "fill": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.checked.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "checked",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(3, 38, 41, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.checked.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "checked",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "disabled",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "disabled",
                    "contrast"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.dark.elements.overrides.form.checkbox.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "checkbox",
                    "disabled",
                    "stroke"
                  ]
                }
              }
            },
            "input": {
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.dark.elements.overrides.form.input.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "disabled",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(133, 133, 133, 1)",
                  "name": "palette.dark.elements.overrides.form.input.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "disabled",
                    "contrast"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.dark.elements.overrides.form.input.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "disabled",
                    "stroke"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(10, 50, 53, 1)",
                  "name": "palette.dark.elements.overrides.form.input.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 107, 106, 1)",
                  "name": "palette.dark.elements.overrides.form.input.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.input.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "error",
                    "contrast"
                  ]
                }
              },
              "fill": {
                "value": "rgba(10, 50, 53, 1)",
                "name": "palette.dark.elements.overrides.form.input.fill",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "input",
                  "fill"
                ]
              },
              "stroke": {
                "value": "rgba(250, 242, 233, 0.1)",
                "name": "palette.dark.elements.overrides.form.input.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "input",
                  "stroke"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(10, 50, 53, 1)",
                  "name": "palette.dark.elements.overrides.form.input.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "hovered",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(15, 203, 140, 1)",
                  "name": "palette.dark.elements.overrides.form.input.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "input",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "contrast": {
                "value": "rgba(15, 203, 140, 1)",
                "name": "palette.dark.elements.overrides.form.input.contrast",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "input",
                  "contrast"
                ]
              }
            },
            "radio": {
              "fill": {
                "value": "rgba(3, 38, 41, 0.1)",
                "name": "palette.dark.elements.overrides.form.radio.fill",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "radio",
                  "fill"
                ]
              },
              "stroke": {
                "value": "rgba(250, 242, 233, 0.8)",
                "name": "palette.dark.elements.overrides.form.radio.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "radio",
                  "stroke"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(3, 38, 41, 0.2)",
                  "name": "palette.dark.elements.overrides.form.radio.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "hovered",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "error",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "disabled",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "disabled",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "disabled",
                    "contrast"
                  ]
                }
              },
              "checked": {
                "fill": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.checked.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "checked",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(3, 38, 41, 1)",
                  "name": "palette.dark.elements.overrides.form.radio.checked.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "radio",
                    "checked",
                    "contrast"
                  ]
                }
              }
            },
            "switch": {
              "fill": {
                "value": "rgba(3, 38, 41, 0.1)",
                "name": "palette.dark.elements.overrides.form.switch.fill",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "switch",
                  "fill"
                ]
              },
              "stroke": {
                "value": "rgba(250, 242, 233, 0.8)",
                "name": "palette.dark.elements.overrides.form.switch.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "switch",
                  "stroke"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(3, 38, 41, 0.2)",
                  "name": "palette.dark.elements.overrides.form.switch.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "hovered",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "checked": {
                "fill": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.checked.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "checked",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(3, 38, 41, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.checked.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "checked",
                    "contrast"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "error",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "disabled",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(174, 174, 174, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "disabled",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.dark.elements.overrides.form.switch.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "switch",
                    "disabled",
                    "contrast"
                  ]
                }
              }
            },
            "slider": {
              "fill": {
                "value": "rgba(250, 242, 233, 0.1)",
                "name": "palette.dark.elements.overrides.form.slider.fill",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "slider",
                  "fill"
                ]
              },
              "contrast": {
                "value": "rgba(250, 242, 233, 0.8)",
                "name": "palette.dark.elements.overrides.form.slider.contrast",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "slider",
                  "contrast"
                ]
              },
              "hovered": {
                "fill": {
                  "value": "rgba(3, 38, 41, 0.4)",
                  "name": "palette.dark.elements.overrides.form.slider.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "hovered",
                    "fill"
                  ]
                },
                "contrast": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.hovered.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "hovered",
                    "contrast"
                  ]
                },
                "stroke": {
                  "value": "rgba(250, 242, 233, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.hovered.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "hovered",
                    "stroke"
                  ]
                }
              },
              "error": {
                "fill": {
                  "value": "rgba(250, 242, 233, 0.1)",
                  "name": "palette.dark.elements.overrides.form.slider.error.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "error",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.error.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "error",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(253, 70, 69, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.error.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "error",
                    "contrast"
                  ]
                }
              },
              "disabled": {
                "fill": {
                  "value": "rgba(214, 214, 214, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.disabled.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "disabled",
                    "fill"
                  ]
                },
                "stroke": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.disabled.stroke",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "disabled",
                    "stroke"
                  ]
                },
                "contrast": {
                  "value": "rgba(93, 93, 93, 1)",
                  "name": "palette.dark.elements.overrides.form.slider.disabled.contrast",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "form"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "form",
                    "slider",
                    "disabled",
                    "contrast"
                  ]
                }
              },
              "stroke": {
                "value": "rgba(250, 242, 233, 1)",
                "name": "palette.dark.elements.overrides.form.slider.stroke",
                "attributes": {
                  "category": "palette",
                  "type": "dark",
                  "item": "elements",
                  "subitem": "overrides",
                  "state": "form"
                },
                "path": [
                  "palette",
                  "dark",
                  "elements",
                  "overrides",
                  "form",
                  "slider",
                  "stroke"
                ]
              }
            }
          },
          "button": {
            "text": {
              "hovered": {
                "fill": {
                  "value": "rgba(250, 242, 233, 0.04)",
                  "name": "palette.dark.elements.overrides.button.text.hovered.fill",
                  "attributes": {
                    "category": "palette",
                    "type": "dark",
                    "item": "elements",
                    "subitem": "overrides",
                    "state": "button"
                  },
                  "path": [
                    "palette",
                    "dark",
                    "elements",
                    "overrides",
                    "button",
                    "text",
                    "hovered",
                    "fill"
                  ]
                }
              }
            }
          }
        },
        "disabled": {
          "fill": {
            "value": "rgba(174, 174, 174, 1)",
            "name": "palette.dark.elements.disabled.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "disabled",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "disabled",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(174, 174, 174, 1)",
              "name": "palette.dark.elements.disabled.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "disabled",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "disabled",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(174, 174, 174, 1)",
              "name": "palette.dark.elements.disabled.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "disabled",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "disabled",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.disabled.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "disabled",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "disabled",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(174, 174, 174, 1)",
            "name": "palette.dark.elements.disabled.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "disabled",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "disabled",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(93, 93, 93, 1)",
            "name": "palette.dark.elements.disabled.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "disabled",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "disabled",
              "contrast-reversed"
            ]
          }
        },
        "default": {
          "fill": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.default.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "default",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "default",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(250, 242, 233, 0.8)",
              "name": "palette.dark.elements.default.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "default",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "default",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(250, 242, 233, 1)",
              "name": "palette.dark.elements.default.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "default",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "default",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.default.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "default",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "default",
              "contrast"
            ]
          },
          "stroke": {
            "value": "rgba(250, 242, 233, 0.4)",
            "name": "palette.dark.elements.default.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "default",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "default",
              "stroke"
            ]
          },
          "contrast-reversed": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.default.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "default",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "default",
              "contrast-reversed"
            ]
          }
        },
        "faded": {
          "fill": {
            "value": "rgba(250, 242, 233, 0.1)",
            "name": "palette.dark.elements.faded.fill",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "faded",
              "state": "fill"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "faded",
              "fill"
            ]
          },
          "hovered": {
            "fill": {
              "value": "rgba(250, 242, 233, 0.4)",
              "name": "palette.dark.elements.faded.hovered.fill",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "faded",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "faded",
                "hovered",
                "fill"
              ]
            },
            "stroke": {
              "value": "rgba(250, 242, 233, 0.4)",
              "name": "palette.dark.elements.faded.hovered.stroke",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "elements",
                "subitem": "faded",
                "state": "hovered"
              },
              "path": [
                "palette",
                "dark",
                "elements",
                "faded",
                "hovered",
                "stroke"
              ]
            }
          },
          "contrast-reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.elements.faded.contrastReversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "faded",
              "state": "contrast-reversed"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "faded",
              "contrast-reversed"
            ]
          },
          "stroke": {
            "value": "rgba(250, 242, 233, 0.2)",
            "name": "palette.dark.elements.faded.stroke",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "faded",
              "state": "stroke"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "faded",
              "stroke"
            ]
          },
          "contrast": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.elements.faded.contrast",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "elements",
              "subitem": "faded",
              "state": "contrast"
            },
            "path": [
              "palette",
              "dark",
              "elements",
              "faded",
              "contrast"
            ]
          }
        }
      },
      "links": {
        "default": {
          "value": "rgba(15, 203, 140, 1)",
          "name": "palette.dark.links.default",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "links",
            "subitem": "default"
          },
          "path": [
            "palette",
            "dark",
            "links",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(240, 82, 156, 1)",
          "name": "palette.dark.links.reversed",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "links",
            "subitem": "reversed"
          },
          "path": [
            "palette",
            "dark",
            "links",
            "reversed"
          ]
        },
        "hovered": {
          "default": {
            "value": "rgba(63, 213, 163, 1)",
            "name": "palette.dark.links.hovered.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "links",
              "subitem": "hovered",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "links",
              "hovered",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(243, 117, 176, 1)",
            "name": "palette.dark.links.hovered.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "links",
              "subitem": "hovered",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "links",
              "hovered",
              "reversed"
            ]
          }
        }
      },
      "stroke": {
        "opacity-10": {
          "default": {
            "value": "rgba(250, 242, 233, 0.1)",
            "name": "palette.dark.stroke.opacity10.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "opacity-10",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "opacity-10",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(3, 38, 41, 0.1)",
            "name": "palette.dark.stroke.opacity10.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "opacity-10",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "opacity-10",
              "reversed"
            ]
          }
        },
        "opacity-100": {
          "default": {
            "value": "rgba(250, 242, 233, 1)",
            "name": "palette.dark.stroke.opacity100.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "opacity-100",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "opacity-100",
              "default"
            ]
          },
          "reversed": {
            "value": "rgba(3, 38, 41, 1)",
            "name": "palette.dark.stroke.opacity100.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "opacity-100",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "opacity-100",
              "reversed"
            ]
          }
        },
        "disabled": {
          "default": {
            "value": "rgba(174, 174, 174, 1)",
            "name": "palette.dark.stroke.disabled.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "disabled",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "disabled",
              "default"
            ]
          }
        },
        "opacity-4": {
          "reversed": {
            "value": "rgba(3, 38, 41, 0.04)",
            "name": "palette.dark.stroke.opacity4.reversed",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "opacity-4",
              "state": "reversed"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "opacity-4",
              "reversed"
            ]
          },
          "default": {
            "value": "rgba(250, 242, 233, 0.04)",
            "name": "palette.dark.stroke.opacity4.default",
            "attributes": {
              "category": "palette",
              "type": "dark",
              "item": "stroke",
              "subitem": "opacity-4",
              "state": "default"
            },
            "path": [
              "palette",
              "dark",
              "stroke",
              "opacity-4",
              "default"
            ]
          }
        }
      },
      "icon": {
        "default": {
          "value": "rgba(250, 242, 233, 1)",
          "name": "palette.dark.icon.default",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "icon",
            "subitem": "default"
          },
          "path": [
            "palette",
            "dark",
            "icon",
            "default"
          ]
        },
        "reversed": {
          "value": "rgba(10, 50, 53, 1)",
          "name": "palette.dark.icon.reversed",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "icon",
            "subitem": "reversed"
          },
          "path": [
            "palette",
            "dark",
            "icon",
            "reversed"
          ]
        },
        "disabled": {
          "value": "rgba(133, 133, 133, 1)",
          "name": "palette.dark.icon.disabled",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "icon",
            "subitem": "disabled"
          },
          "path": [
            "palette",
            "dark",
            "icon",
            "disabled"
          ]
        },
        "error": {
          "value": "rgba(254, 144, 143, 1)",
          "name": "palette.dark.icon.error",
          "attributes": {
            "category": "palette",
            "type": "dark",
            "item": "icon",
            "subitem": "error"
          },
          "path": [
            "palette",
            "dark",
            "icon",
            "error"
          ]
        }
      },
      "charting": {
        "categorical": {
          "dark": {
            "Red": {
              "value": "rgba(255, 159, 158, 1)",
              "name": "palette.dark.charting.categorical.dark.Red",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Red"
              ]
            },
            "Orange": {
              "value": "rgba(255, 212, 93, 1)",
              "name": "palette.dark.charting.categorical.dark.Orange",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Orange"
              ]
            },
            "Aqua": {
              "value": "rgba(181, 240, 255, 1)",
              "name": "palette.dark.charting.categorical.dark.Aqua",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Aqua"
              ]
            },
            "Teal": {
              "value": "rgba(154, 231, 236, 1)",
              "name": "palette.dark.charting.categorical.dark.Teal",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Teal"
              ]
            },
            "Blue": {
              "value": "rgba(128, 189, 255, 1)",
              "name": "palette.dark.charting.categorical.dark.Blue",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Blue"
              ]
            },
            "Magenta": {
              "value": "rgba(231, 162, 222, 1)",
              "name": "palette.dark.charting.categorical.dark.Magenta",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Magenta"
              ]
            },
            "Purple": {
              "value": "rgba(183, 175, 235, 1)",
              "name": "palette.dark.charting.categorical.dark.Purple",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Purple"
              ]
            },
            "Green": {
              "value": "rgba(112, 221, 186, 1)",
              "name": "palette.dark.charting.categorical.dark.Green",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "dark"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "dark",
                "Green"
              ]
            }
          },
          "light": {
            "Red": {
              "value": "rgba(147, 34, 34, 1)",
              "name": "palette.dark.charting.categorical.light.Red",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Red"
              ]
            },
            "Orange": {
              "value": "rgba(221, 101, 2, 1)",
              "name": "palette.dark.charting.categorical.light.Orange",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Orange"
              ]
            },
            "Aqua": {
              "value": "rgba(5, 73, 118, 1)",
              "name": "palette.dark.charting.categorical.light.Aqua",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Aqua"
              ]
            },
            "Teal": {
              "value": "rgba(7, 142, 158, 1)",
              "name": "palette.dark.charting.categorical.light.Teal",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Teal"
              ]
            },
            "Blue": {
              "value": "rgba(38, 94, 212, 1)",
              "name": "palette.dark.charting.categorical.light.Blue",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Blue"
              ]
            },
            "Magenta": {
              "value": "rgba(101, 41, 93, 1)",
              "name": "palette.dark.charting.categorical.light.Magenta",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Magenta"
              ]
            },
            "Purple": {
              "value": "rgba(92, 80, 164, 1)",
              "name": "palette.dark.charting.categorical.light.Purple",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Purple"
              ]
            },
            "Green": {
              "value": "rgba(0, 83, 64, 1)",
              "name": "palette.dark.charting.categorical.light.Green",
              "attributes": {
                "category": "palette",
                "type": "dark",
                "item": "charting",
                "subitem": "categorical",
                "state": "light"
              },
              "path": [
                "palette",
                "dark",
                "charting",
                "categorical",
                "light",
                "Green"
              ]
            }
          }
        }
      },
      "accent": {
        "primary": {
          "value": "rgba(240, 82, 156, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "accent",
            "subitem": "primary",
            "hex": "f0529c",
            "rgb": {
              "r": 240,
              "g": 82,
              "b": 156,
              "a": 1
            },
            "hsl": {
              "h": 331.8987341772152,
              "s": 0.8404255319148936,
              "l": 0.6313725490196078,
              "a": 1
            },
            "hsv": {
              "h": 331.8987341772152,
              "s": 0.6583333333333333,
              "v": 0.9411764705882353,
              "a": 1
            }
          },
          "name": "palette.dark.accent.primary",
          "path": [
            "palette",
            "dark",
            "accent",
            "primary"
          ]
        },
        "secondary": {
          "value": "rgba(15, 203, 140, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "accent",
            "subitem": "secondary",
            "hex": "0fcb8c",
            "rgb": {
              "r": 15,
              "g": 203,
              "b": 140,
              "a": 1
            },
            "hsl": {
              "h": 159.8936170212766,
              "s": 0.8623853211009174,
              "l": 0.42745098039215684,
              "a": 1
            },
            "hsv": {
              "h": 159.8936170212766,
              "s": 0.9261083743842364,
              "v": 0.796078431372549,
              "a": 1
            }
          },
          "name": "palette.dark.accent.secondary",
          "path": [
            "palette",
            "dark",
            "accent",
            "secondary"
          ]
        },
        "tertiary": {
          "value": "rgba(177, 27, 232, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "accent",
            "subitem": "tertiary",
            "hex": "b11be8",
            "rgb": {
              "r": 177,
              "g": 27,
              "b": 232,
              "a": 1
            },
            "hsl": {
              "h": 283.9024390243902,
              "s": 0.8167330677290836,
              "l": 0.5078431372549019,
              "a": 1
            },
            "hsv": {
              "h": 283.9024390243902,
              "s": 0.8836206896551725,
              "v": 0.9098039215686274,
              "a": 1
            }
          },
          "name": "palette.dark.accent.tertiary",
          "path": [
            "palette",
            "dark",
            "accent",
            "tertiary"
          ]
        }
      },
      "alert": {
        "error": {
          "value": "rgba(253, 70, 69, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "alert",
            "subitem": "error",
            "hex": "fd4645",
            "rgb": {
              "r": 253,
              "g": 70,
              "b": 69,
              "a": 1
            },
            "hsl": {
              "h": 0.3260869565217426,
              "s": 0.9787234042553192,
              "l": 0.6313725490196078,
              "a": 1
            },
            "hsv": {
              "h": 0.3260869565217426,
              "s": 0.7272727272727274,
              "v": 0.9921568627450981,
              "a": 1
            }
          },
          "name": "palette.dark.alert.error",
          "path": [
            "palette",
            "dark",
            "alert",
            "error"
          ]
        },
        "warning": {
          "value": "rgba(255, 163, 28, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "alert",
            "subitem": "warning",
            "hex": "ffa31c",
            "rgb": {
              "r": 255,
              "g": 163,
              "b": 28,
              "a": 1
            },
            "hsl": {
              "h": 35.68281938325991,
              "s": 1,
              "l": 0.5549019607843138,
              "a": 1
            },
            "hsv": {
              "h": 35.68281938325991,
              "s": 0.8901960784313725,
              "v": 1,
              "a": 1
            }
          },
          "name": "palette.dark.alert.warning",
          "path": [
            "palette",
            "dark",
            "alert",
            "warning"
          ]
        },
        "confirmation": {
          "value": "rgba(84, 156, 53, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "alert",
            "subitem": "confirmation",
            "hex": "549c35",
            "rgb": {
              "r": 84,
              "g": 156,
              "b": 53,
              "a": 1
            },
            "hsl": {
              "h": 101.94174757281554,
              "s": 0.492822966507177,
              "l": 0.4098039215686275,
              "a": 1
            },
            "hsv": {
              "h": 101.94174757281554,
              "s": 0.6602564102564102,
              "v": 0.611764705882353,
              "a": 1
            }
          },
          "name": "palette.dark.alert.confirmation",
          "path": [
            "palette",
            "dark",
            "alert",
            "confirmation"
          ]
        },
        "information": {
          "value": "rgba(42, 136, 239, 1)",
          "attributes": {
            "category": "color",
            "deprecated": true,
            "type": "dark",
            "item": "alert",
            "subitem": "information",
            "hex": "2a88ef",
            "rgb": {
              "r": 42,
              "g": 136,
              "b": 239,
              "a": 1
            },
            "hsl": {
              "h": 211.37055837563452,
              "s": 0.8602620087336245,
              "l": 0.5509803921568628,
              "a": 1
            },
            "hsv": {
              "h": 211.37055837563452,
              "s": 0.8242677824267782,
              "v": 0.9372549019607843,
              "a": 1
            }
          },
          "name": "palette.dark.alert.information",
          "path": [
            "palette",
            "dark",
            "alert",
            "information"
          ]
        }
      }
    }
  },
  "radii": {
    "xs": {
      "value": "2px",
      "name": "radii.xs",
      "attributes": {
        "category": "radii",
        "type": "xs"
      },
      "path": [
        "radii",
        "xs"
      ]
    },
    "sm": {
      "value": "4px",
      "name": "radii.sm",
      "attributes": {
        "category": "radii",
        "type": "sm"
      },
      "path": [
        "radii",
        "sm"
      ]
    },
    "md": {
      "value": "8px",
      "name": "radii.md",
      "attributes": {
        "category": "radii",
        "type": "md"
      },
      "path": [
        "radii",
        "md"
      ]
    },
    "lg": {
      "value": "12px",
      "name": "radii.lg",
      "attributes": {
        "category": "radii",
        "type": "lg"
      },
      "path": [
        "radii",
        "lg"
      ]
    },
    "xl": {
      "value": "16px",
      "name": "radii.xl",
      "attributes": {
        "category": "radii",
        "type": "xl"
      },
      "path": [
        "radii",
        "xl"
      ]
    },
    "full": {
      "value": "9999px",
      "name": "radii.full",
      "attributes": {
        "category": "radii",
        "type": "full"
      },
      "path": [
        "radii",
        "full"
      ]
    }
  },
  "sizes": {
    "icon": {
      "xs": {
        "value": "1rem",
        "$type": "dimension",
        "name": "sizes.icon.xs",
        "attributes": {
          "category": "sizes",
          "type": "icon",
          "item": "xs"
        },
        "path": [
          "sizes",
          "icon",
          "xs"
        ]
      },
      "sm": {
        "value": "1.5rem",
        "$type": "dimension",
        "name": "sizes.icon.sm",
        "attributes": {
          "category": "sizes",
          "type": "icon",
          "item": "sm"
        },
        "path": [
          "sizes",
          "icon",
          "sm"
        ]
      },
      "md": {
        "value": "1.75rem",
        "$type": "dimension",
        "name": "sizes.icon.md",
        "attributes": {
          "category": "sizes",
          "type": "icon",
          "item": "md"
        },
        "path": [
          "sizes",
          "icon",
          "md"
        ]
      },
      "lg": {
        "value": "2rem",
        "$type": "dimension",
        "name": "sizes.icon.lg",
        "attributes": {
          "category": "sizes",
          "type": "icon",
          "item": "lg"
        },
        "path": [
          "sizes",
          "icon",
          "lg"
        ]
      },
      "xl": {
        "value": "3rem",
        "$type": "dimension",
        "name": "sizes.icon.xl",
        "attributes": {
          "category": "sizes",
          "type": "icon",
          "item": "xl"
        },
        "path": [
          "sizes",
          "icon",
          "xl"
        ]
      }
    }
  },
  "spacing": {
    "0": {
      "value": "0px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "0"
      },
      "name": "spacing.0",
      "path": [
        "spacing",
        "0"
      ]
    },
    "1": {
      "value": "8px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "1"
      },
      "name": "spacing.1",
      "path": [
        "spacing",
        "1"
      ]
    },
    "2": {
      "value": "16px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "2"
      },
      "name": "spacing.2",
      "path": [
        "spacing",
        "2"
      ]
    },
    "3": {
      "value": "24px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "3"
      },
      "name": "spacing.3",
      "path": [
        "spacing",
        "3"
      ]
    },
    "4": {
      "value": "32px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "4"
      },
      "name": "spacing.4",
      "path": [
        "spacing",
        "4"
      ]
    },
    "5": {
      "value": "40px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "5"
      },
      "name": "spacing.5",
      "path": [
        "spacing",
        "5"
      ]
    },
    "6": {
      "value": "48px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "6"
      },
      "name": "spacing.6",
      "path": [
        "spacing",
        "6"
      ]
    },
    "7": {
      "value": "56px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "7"
      },
      "name": "spacing.7",
      "path": [
        "spacing",
        "7"
      ]
    },
    "8": {
      "value": "64px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "8"
      },
      "name": "spacing.8",
      "path": [
        "spacing",
        "8"
      ]
    },
    "10": {
      "value": "80px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "10"
      },
      "name": "spacing.10",
      "path": [
        "spacing",
        "10"
      ]
    },
    "11": {
      "value": "88px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "11"
      },
      "name": "spacing.11",
      "path": [
        "spacing",
        "11"
      ]
    },
    "12": {
      "value": "96px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "12"
      },
      "name": "spacing.12",
      "path": [
        "spacing",
        "12"
      ]
    },
    "13": {
      "value": "104px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "13"
      },
      "name": "spacing.13",
      "path": [
        "spacing",
        "13"
      ]
    },
    "14": {
      "value": "112px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "14"
      },
      "name": "spacing.14",
      "path": [
        "spacing",
        "14"
      ]
    },
    "15": {
      "value": "120px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "15"
      },
      "name": "spacing.15",
      "path": [
        "spacing",
        "15"
      ]
    },
    "16": {
      "value": "128px",
      "$type": "dimension",
      "attributes": {
        "forcePx": true,
        "category": "spacing",
        "type": "16"
      },
      "name": "spacing.16",
      "path": [
        "spacing",
        "16"
      ]
    },
    "baseSpacingValue": {
      "value": 8,
      "name": "spacing.baseSpacingValue",
      "attributes": {
        "category": "spacing",
        "type": "baseSpacingValue"
      },
      "path": [
        "spacing",
        "baseSpacingValue"
      ]
    }
  },
  "typography": {
    "h1": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.2,
        "fontSize": "2.25rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "h1"
      },
      "name": "typography.h1",
      "path": [
        "typography",
        "h1"
      ]
    },
    "h2": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.2,
        "fontSize": "2rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "h2"
      },
      "name": "typography.h2",
      "path": [
        "typography",
        "h2"
      ]
    },
    "h3": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.2,
        "fontSize": "1.5rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "h3"
      },
      "name": "typography.h3",
      "path": [
        "typography",
        "h3"
      ]
    },
    "h4": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.2,
        "fontSize": "1.25rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "h4"
      },
      "name": "typography.h4",
      "path": [
        "typography",
        "h4"
      ]
    },
    "h5": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.2,
        "fontSize": "1.125rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "h5"
      },
      "name": "typography.h5",
      "path": [
        "typography",
        "h5"
      ]
    },
    "h6": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.3,
        "fontSize": "1rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "h6"
      },
      "name": "typography.h6",
      "path": [
        "typography",
        "h6"
      ]
    },
    "body1": {
      "value": {
        "fontFamily": "Manrope, Arial, sans-serif",
        "fontWeight": 400,
        "lineHeight": 1.5,
        "fontSize": "1rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "body1"
      },
      "name": "typography.body1",
      "path": [
        "typography",
        "body1"
      ]
    },
    "body2": {
      "value": {
        "fontFamily": "Manrope, Arial, sans-serif",
        "fontWeight": 400,
        "lineHeight": 1.5,
        "fontSize": "0.875rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "body2"
      },
      "name": "typography.body2",
      "path": [
        "typography",
        "body2"
      ]
    },
    "subtitle1": {
      "value": {
        "fontFamily": "Manrope, Arial, sans-serif",
        "fontWeight": 400,
        "lineHeight": 1.2,
        "fontSize": "1rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "subtitle1"
      },
      "name": "typography.subtitle1",
      "path": [
        "typography",
        "subtitle1"
      ]
    },
    "subtitle2": {
      "value": {
        "fontFamily": "Manrope, Arial, sans-serif",
        "fontWeight": 500,
        "lineHeight": 1.2,
        "fontSize": "0.875rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "subtitle2"
      },
      "name": "typography.subtitle2",
      "path": [
        "typography",
        "subtitle2"
      ]
    },
    "overline": {
      "value": {
        "fontFamily": "Telegraf, Manrope, Arial, sans-serif",
        "fontWeight": 400,
        "lineHeight": 1.2,
        "fontSize": "0.875rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "overline"
      },
      "name": "typography.overline",
      "path": [
        "typography",
        "overline"
      ]
    },
    "monospace": {
      "value": {
        "fontFamily": "'Space Mono', monospace",
        "fontWeight": 400,
        "lineHeight": 1.5,
        "fontSize": "1rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "monospace"
      },
      "name": "typography.monospace",
      "path": [
        "typography",
        "monospace"
      ]
    },
    "caption": {
      "value": {
        "fontFamily": "Manrope, Arial, sans-serif",
        "fontWeight": 400,
        "lineHeight": 1.2,
        "fontSize": "0.875rem",
        "letterSpacing": "0rem"
      },
      "$type": "typography",
      "attributes": {
        "designedFor": [
          "mui"
        ],
        "category": "typography",
        "type": "caption"
      },
      "name": "typography.caption",
      "path": [
        "typography",
        "caption"
      ]
    }
  },
  "letterSpacing": {
    "0": {
      "value": "0rem",
      "$type": "dimension",
      "name": "letterSpacing.0",
      "attributes": {
        "category": "letterSpacing",
        "type": "0"
      },
      "path": [
        "letterSpacing",
        "0"
      ]
    }
  },
  "lineHeight": {
    "1": {
      "value": 1.2,
      "$type": "number",
      "name": "lineHeight.1",
      "attributes": {
        "category": "lineHeight",
        "type": "1"
      },
      "path": [
        "lineHeight",
        "1"
      ]
    },
    "2": {
      "value": 1.3,
      "$type": "number",
      "name": "lineHeight.2",
      "attributes": {
        "category": "lineHeight",
        "type": "2"
      },
      "path": [
        "lineHeight",
        "2"
      ]
    },
    "3": {
      "value": 1.4,
      "$type": "number",
      "name": "lineHeight.3",
      "attributes": {
        "category": "lineHeight",
        "type": "3"
      },
      "path": [
        "lineHeight",
        "3"
      ]
    },
    "4": {
      "value": 1.5,
      "$type": "number",
      "name": "lineHeight.4",
      "attributes": {
        "category": "lineHeight",
        "type": "4"
      },
      "path": [
        "lineHeight",
        "4"
      ]
    }
  }
};
var varnish_tokens_mui_default = varnishTokens;

export { varnish_tokens_mui_default as default };
