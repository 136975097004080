
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Disabled items'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A MenuItem can be disabled with the isDisabled prop. Disabled items are not focusable or keyboard navigable, and do not trigger onAction or onSelectionChange.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button label="Actions">Actions</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuItem>Copy</varnishUi.MenuItem>
                <varnishUi.MenuItem>Cut</varnishUi.MenuItem>
                <varnishUi.MenuItem isDisabled>Paste</varnishUi.MenuItem>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;