
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Sizes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The IconButton offers three sizes: small, medium, large which just give a default size for the Icon and set the padding`}</p>
    <p>{`Icons, can specify their own size too.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="column" spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="default" size="small">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" size="small">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" size="small">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="default" size="medium">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" size="medium">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" size="medium">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="default" size="large">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="outlined" size="large">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" size="large">
                <varnishUi.Icons.Add />
            </varnishUi.IconButton>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.IconButton variant="contained" size="small">
                <varnishUi.Icons.Add size="large" />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" size="medium">
                <varnishUi.Icons.Add size="small" />
            </varnishUi.IconButton>
            <varnishUi.IconButton variant="contained" size="large">
                <varnishUi.Icons.Add size="medium" />
            </varnishUi.IconButton>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;