import { Button, type ButtonProps } from './Button';

type IconButtonProps = Omit<
    ButtonProps,
    'startIcon' | 'endIcon' | 'startIconClassName' | 'endIconClassName'
>;

const IconButton = (props: IconButtonProps) => <Button iconOnly {...props} />;

export { IconButton, IconButton as default };
export type { IconButtonProps };
