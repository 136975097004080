
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Image'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Example of a card using an image to reinforce the content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Card>
        <varnishUi.CardHeader>
            <p>This is a dog</p>
        </varnishUi.CardHeader>
        <varnishUi.CardMedia>
            <img
                src="https://picsum.photos/id/237/200/300"
                style={{ width: '100%', height: 'auto' }}
            />
        </varnishUi.CardMedia>
        <varnishUi.CardBody>
            <p>
                The dog is a domesticated descendant of the gray wolf. Also called the domestic dog,
                it was selectively bred from an extinct population of wolves during the Late
                Pleistocene by hunter-gatherers. The dog was the first species to be domesticated by
                humans, over 14,000 years ago and before the development of agriculture.
            </p>
        </varnishUi.CardBody>
        <varnishUi.CardFooter>
            <varnishUi.Button>Learn More</varnishUi.Button>
        </varnishUi.CardFooter>
    </varnishUi.Card>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;