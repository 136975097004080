import { ColorKeys } from '@allenai/varnish-theme/varnish-tokens';
import { color } from '@allenai/varnish2/theme';

import varnishTokens from '@allenai/varnish-theme/panda-theme-beta';

import { ChartingGrid, ColorGroupDict, ColorValues } from './ColorValues';

const createColorShades = (colorName: string, indexes: string[]): ColorGroupDict => {
    const colorArray = indexes.map((num) => ({
        ...color[`${colorName}-${num}` as ColorKeys],
        displayName: `${colorName}-${num}`,
    }));
    return { [`${colorName} Shades`]: colorArray };
};

type ColorWithShades = { name: string; shades: ColorGroupDict };

// Grab color shades from varnishPandaTheme color tokens
const colorsWithShades: ColorWithShades[] = Object.entries(varnishTokens.tokens.colors).flatMap(
    ([name, colorIndex]) => {
        // Skip our old colors, like A10
        if (name.match(/[A-Z]\d+/)) {
            return [];
        }

        // If this is a color without shades (like white), just get the color
        if ('value' in colorIndex) {
            return {
                name,
                shades: {
                    [`${name} Shades`]: [
                        { ...color[name as keyof typeof color], displayName: name },
                    ],
                },
            } satisfies ColorWithShades;
        }

        // Otherwise get all the shades
        return {
            name,
            shades: createColorShades(name, Object.keys(colorIndex)),
        } satisfies ColorWithShades;
    }
);

export function ColorShades() {
    return (
        <>
            {colorsWithShades.map(({ name, shades }) => (
                <ChartingGrid key={name}>
                    <ColorValues colorGroupDict={shades} themeProp={name} />
                </ChartingGrid>
            ))}
        </>
    );
}
