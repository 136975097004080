import {
    MenuTrigger as AriaMenuTrigger,
    type MenuTriggerProps as AriaMenuTriggerProps,
} from 'react-aria-components';
import { PropsWithChildren, ReactNode } from 'react';

type MenuTriggerProps = {
    children?: ReactNode;
} & AriaMenuTriggerProps;

const MenuTrigger = ({ children, ...rest }: PropsWithChildren<MenuTriggerProps>) => {
    return <AriaMenuTrigger {...rest}>{children}</AriaMenuTrigger>;
};

export { MenuTrigger };
export type { MenuTriggerProps };
