
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Section-level selection'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Each section in a menu may have independent selection states. This works the same way as described above for the entire menu, but operates at the section level instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from "react";
import type { Selection } from "react-aria-components";
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
  const [style, setStyle] = React.useState<Selection>(new Set(["bold"]));
  const [align, setAlign] = React.useState<Selection>(new Set(["left"]));

  return (
    <varnishUi.MenuTrigger>
      <varnishUi.Button aria-label="Edit">Edit</varnishUi.Button>
      <varnishUi.Popover>
        <varnishUi.AriaMenu>
          <varnishUi.MenuSection>
            <varnishUi.MenuHeader>Actions</varnishUi.MenuHeader>
            <varnishUi.MenuItem>Cut</varnishUi.MenuItem>
            <varnishUi.MenuItem>Copy</varnishUi.MenuItem>
            <varnishUi.MenuItem>Paste</varnishUi.MenuItem>
          </varnishUi.MenuSection>
          <varnishUi.MenuSection
            selectionMode="multiple"
            selectedKeys={style}
            onSelectionChange={setStyle}
          >
            <varnishUi.MenuHeader>Text style</varnishUi.MenuHeader>
            <varnishUi.MenuItem id="bold">Bold</varnishUi.MenuItem>
            <varnishUi.MenuItem id="italic">Italic</varnishUi.MenuItem>
            <varnishUi.MenuItem id="underline">Underline</varnishUi.MenuItem>
          </varnishUi.MenuSection>
          <varnishUi.MenuSection
            selectionMode="single"
            selectedKeys={align}
            onSelectionChange={setAlign}
          >
            <varnishUi.MenuHeader>Text alignment</varnishUi.MenuHeader>
            <varnishUi.MenuItem id="left">Left</varnishUi.MenuItem>
            <varnishUi.MenuItem id="center">Center</varnishUi.MenuItem>
            <varnishUi.MenuItem id="right">Right</varnishUi.MenuItem>
          </varnishUi.MenuSection>
        </varnishUi.AriaMenu>
      </varnishUi.Popover>
    </varnishUi.MenuTrigger>
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;