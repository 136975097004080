import { Header as AriaHeader } from 'react-aria-components';

import { PropsWithChildren, ReactNode } from 'react';

import { menuRecipe, MenuRecipeProps } from './menu.styles';
import { cx } from '@/utils/cx';

type MenuHeaderProps = {
    className?: string;
    children?: ReactNode;
} & MenuRecipeProps;

const MenuHeader = ({ className, children, ...rest }: PropsWithChildren<MenuHeaderProps>) => {
    const [variantProps] = menuRecipe.splitVariantProps(rest);
    const recipeClassNames = menuRecipe(variantProps);

    return <AriaHeader className={cx(recipeClassNames.header, className)}>{children}</AriaHeader>;
};

export { MenuHeader };
export type { MenuHeaderProps };
