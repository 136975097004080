
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.`}</p>
    <p><inlineCode parentName="p">{`color`}</inlineCode>{` controls the fill color and `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` controls the size.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const VarnishCopyIcon = varnishUi.varnishIcon(ContentCopyIcon);

const className = emotionCss.css\`
    border: 1px solid red;
\`;

const menuItemClass = emotionCss.css\`
    font-size: 32px;
    color: orange;
\`;

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Menu">☰</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu className={className}>
                <varnishUi.MenuItem className={menuItemClass}>Open</varnishUi.MenuItem>
                <varnishUi.MenuItem className={menuItemClass}>Rename…</varnishUi.MenuItem>
                <varnishUi.MenuItem className={menuItemClass}>Duplicate</varnishUi.MenuItem>
                <varnishUi.MenuItem className={menuItemClass}>Share…</varnishUi.MenuItem>
                <varnishUi.MenuItem className={menuItemClass}>Delete…</varnishUi.MenuItem>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;