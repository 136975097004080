
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic Usage'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A menu trigger consists of a button or other trigger element combined with a menu displayed in a popover, with a list of menu items or sections inside. Users can click, touch, or use the keyboard on the button to open the menu.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.MenuTrigger>
        <varnishUi.Button aria-label="Menu">☰</varnishUi.Button>
        <varnishUi.Popover>
            <varnishUi.AriaMenu>
                <varnishUi.MenuItem onAction={() => alert('open')}>Open</varnishUi.MenuItem>
                <varnishUi.MenuItem onAction={() => alert('rename')}>Rename…</varnishUi.MenuItem>
                <varnishUi.MenuItem onAction={() => alert('duplicate')}>
                    Duplicate
                </varnishUi.MenuItem>
                <varnishUi.MenuItem onAction={() => alert('share')}>Share…</varnishUi.MenuItem>
                <varnishUi.MenuItem onAction={() => alert('delete')}>Delete…</varnishUi.MenuItem>
            </varnishUi.AriaMenu>
        </varnishUi.Popover>
    </varnishUi.MenuTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;