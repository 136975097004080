import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const textAreaRecipe = sva({
    slots: [
        'root',
        'shared',
        'displayContainer',
        'growContainer',
        'textArea',
        'label',
        'description',
        'error',
    ],
    base: {
        displayContainer: {
            fontFamily: 'body',

            borderRadius: 'sm',
            border: '[1px solid]',
            // light
            backgroundColor: '[white]',
            borderColor: '[white]',
            color: 'text.primary',
            _dark: {
                backgroundColor: 'background',
                borderColor: 'background',
                _disabled: {
                    backgroundColor: 'gray.20',
                    borderColor: 'gray.40',
                },
            },

            // not sure about these either
            _hover: {
                '&:not(:has([data-disabled]))': {
                    borderColor: 'green.100',
                },
            },
            _groupDisabled: {
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
                cursor: 'not-allowed',
            },
            _groupInvalid: {
                borderColor: 'error-red.100',
            },
        },
        shared: {
            textStyle: 'inherit',
            boxSizing: 'border-box',
            scrollbarGutter: 'stable',
            overflowWrap: 'anywhere',
        },
        growContainer: {
            display: 'grid',
            // this ::after pseudo-element will contain an example string that is used to calculate height.
            // its important that it has the same font-size, border, padding, etc as the textArea so that height is calculated correctly.
            _after: {
                content: 'attr(data-replicated-value) " "',
                whiteSpace: 'pre-wrap',
                visibility: 'hidden',
                gridArea: '1/1/2/2',
                overflowY: 'scroll',
            },
        },
        textArea: {
            gridArea: '1/1/2/2',
            // remove unneeded textArea styles
            border: '0',
            padding: '0',
            appearance: 'none',
            resize: 'none', // prevents resizing via corner clicking element
            outline: '0',
            height: '[unset]',
            maxWidth: '[100%]', // ??

            // inherit from container
            backgroundColor: '[inherit]',
            color: '[inherit]',

            // Scroll bars:
            // hide scrollbar until hover or focus
            scrollbarColor: '[transparent transparent]',
            _hoverOrFocus: {
                scrollbarColor:
                    '[color-mix(in srgb, {colors.dark-teal.100} 50%, white) transparent]',
                _dark: {
                    scrollbarColor: '[color-mix(in srgb, background 50%, white) transparent]',
                },
            },
            _invalid: {
                _placeholder: {
                    color: 'error-red.100',
                },
                _dark: {
                    _placeholder: {
                        color: 'error-red.60',
                    },
                },
            },
            _disabled: {
                cursor: 'not-allowed',
            },
        },
        label: {},
        description: {},
        error: {},
    },
    variants: {
        variant: {
            contained: {}, // default
            outlined: {
                displayContainer: {
                    borderColor: 'dark-teal.20',
                    _dark: {
                        borderColor:
                            '[color-mix(in srgb, {colors.cream.100} 20%, {colors.background})]',
                        color: 'text.primary',
                    },
                },
            },
        },
        size: {
            small: {
                root: {
                    fontSize: '[12px]',
                    lineHeight: '[1rem]',
                },
                displayContainer: {
                    padding: '[12px 12px]',
                },
            },
            medium: {
                root: {
                    fontSize: 'sm',
                    lineHeight: '[1.375rem]',
                },
                displayContainer: {
                    padding: '[8px 12px]',
                },
            },
            large: {
                root: {
                    fontSize: 'lg',
                    lineHeight: '1.5rem',
                },
                displayContainer: {
                    padding: '[16px 16px]',
                },
            },
        },
        shape: {
            box: {}, // default
            rounded: {
                displayContainer: {
                    borderRadius: '[28px]',
                    borderWidth: '[2px]',
                },
            },
        },
        fullWidth: {
            false: {},
            true: {
                root: {
                    width: '[100%]',
                },
                shared: {
                    width: '[100%]',
                },
            },
        },
    },
    compoundVariants: [
        {
            size: ['small', 'medium', 'large'],
            shape: 'rounded',
            css: {
                displayContainer: {
                    padding: '[12px 24px]',
                },
            },
        },
    ],
    defaultVariants: {
        variant: 'outlined',
        shape: 'box',
        size: 'medium',
        fullWidth: false,
    },
});

type TextAreaRecipeProps = RecipeVariantProps<typeof textAreaRecipe>;

export { textAreaRecipe, textAreaRecipe as default };
export type { TextAreaRecipeProps };
