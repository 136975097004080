import { PropsWithChildren, ReactNode } from 'react';

import cardRecipe, { CardRecipeProps } from './card.styles';
import { cx } from '@/utils/cx';

type CardProps = {
    className?: string;
    children?: ReactNode;
    ariaLabel?: string;
} & CardRecipeProps;

const Card = ({ className, children, ariaLabel, ...rest }: PropsWithChildren<CardProps>) => {
    const [variantProps, localProps] = cardRecipe.splitVariantProps(rest);
    const recipeClassNames = cardRecipe(variantProps);

    return (
        <div
            className={cx(recipeClassNames.root, className)}
            aria-label={ariaLabel}
            {...localProps}>
            {children}
        </div>
    );
};

export { Card };
export type { CardProps };
