const varnishTokens = {
  "asset": {
    "icon": {
      "arrow": "icons/arrow.svg",
      "chat": "icons/chat.svg",
      "close": "icons/close.svg",
      "download": "icons/download.svg",
      "plus": "icons/plus.svg",
      "search": "icons/search.svg",
      "share": "icons/share.svg",
      "spark": "icons/spark.svg",
      "time": "icons/time.svg"
    }
  },
  "breakpoint": {
    "xs": 0,
    "sm": 600,
    "md": 900,
    "lg": 1200,
    "xl": 1536
  },
  "color": {
    "extra-dark-teal-4": "#0326290a",
    "extra-dark-teal-10": "#0326291a",
    "extra-dark-teal-20": "#03262933",
    "extra-dark-teal-30": "#0326294d",
    "extra-dark-teal-40": "#03262966",
    "extra-dark-teal-50": "#03262980",
    "extra-dark-teal-60": "#03262999",
    "extra-dark-teal-70": "#032629b3",
    "extra-dark-teal-80": "#032629cc",
    "extra-dark-teal-90": "#032629e6",
    "extra-dark-teal-100": "#032629",
    "dark-teal-10": "#0a32351a",
    "dark-teal-20": "#0a323533",
    "dark-teal-30": "#0a32354d",
    "dark-teal-40": "#0a323566",
    "dark-teal-50": "#0a323580",
    "dark-teal-60": "#0a323599",
    "dark-teal-70": "#0a3235b3",
    "dark-teal-80": "#0a3235cc",
    "dark-teal-90": "#0a3235e6",
    "dark-teal-100": "#0a3235",
    "cream-4": "#faf2e90a",
    "cream-10": "#faf2e91a",
    "cream-20": "#faf2e933",
    "cream-30": "#faf2e94d",
    "cream-40": "#faf2e966",
    "cream-50": "#faf2e980",
    "cream-60": "#faf2e999",
    "cream-70": "#faf2e9b3",
    "cream-80": "#faf2e9cc",
    "cream-90": "#faf2e9e6",
    "cream-100": "#faf2e9",
    "info-blue-20": "#d4e7fc",
    "info-blue-40": "#aacff9",
    "info-blue-60": "#7fb8f5",
    "info-blue-80": "#55a0f2",
    "info-blue-100": "#2a88ef",
    "error-red-20": "#ffdada",
    "error-red-40": "#feb5b5",
    "error-red-60": "#fe908f",
    "error-red-80": "#fd6b6a",
    "error-red-100": "#fd4645",
    "success-green-20": "#ddebd7",
    "success-green-40": "#bbd7ae",
    "success-green-60": "#98c486",
    "success-green-80": "#76b05d",
    "success-green-100": "#549c35",
    "warning-orange-20": "#ffedd2",
    "warning-orange-40": "#ffdaa4",
    "warning-orange-60": "#ffc877",
    "warning-orange-80": "#ffb549",
    "warning-orange-100": "#ffa31c",
    "teal-10": "#1052571a",
    "teal-20": "#10525733",
    "teal-30": "#1052574d",
    "teal-40": "#10525766",
    "teal-50": "#10525780",
    "teal-60": "#10525799",
    "teal-70": "#105257b3",
    "teal-80": "#105257cc",
    "teal-90": "#105257e6",
    "teal-100": "#105257",
    "pink-10": "#fdeef5",
    "pink-20": "#fcdceb",
    "pink-30": "#fbcbe1",
    "pink-40": "#f9bad7",
    "pink-50": "#f7a8cd",
    "pink-60": "#f697c4",
    "pink-70": "#f586ba",
    "pink-80": "#f375b0",
    "pink-90": "#f263a6",
    "pink-100": "#f0529c",
    "purple-10": "#f7e8fd",
    "purple-20": "#efd1fa",
    "purple-30": "#e8bbf8",
    "purple-40": "#e0a4f6",
    "purple-50": "#d88df3",
    "purple-60": "#d076f1",
    "purple-70": "#c85fef",
    "purple-80": "#c149ed",
    "purple-90": "#b932ea",
    "purple-100": "#b11be8",
    "green-10": "#e7faf3",
    "green-20": "#cff5e8",
    "green-30": "#b7efdd",
    "green-40": "#9fead1",
    "green-50": "#87e5c5",
    "green-60": "#6fe0ba",
    "green-70": "#57dbaf",
    "green-80": "#3fd5a3",
    "green-90": "#27d09c",
    "green-100": "#0fcb8c",
    "gray-10": "#ebebeb",
    "gray-20": "#d6d6d6",
    "gray-30": "#c2c2c2",
    "gray-40": "#aeaeae",
    "gray-50": "#999999",
    "gray-60": "#858585",
    "gray-70": "#717171",
    "gray-80": "#5d5d5d",
    "gray-90": "#484848",
    "gray-100": "#343434",
    "white": "#ffffff",
    "black": "#000000",
    "R1": "#fff2f2",
    "R2": "#ffe1e0",
    "R3": "#fdc1c0",
    "R4": "#ff9f9e",
    "R5": "#f9807f",
    "R6": "#f7605f",
    "R7": "#e7504f",
    "R8": "#d63f3f",
    "R9": "#bf2d2d",
    "R10": "#932222",
    "O1": "#fff9e8",
    "O2": "#fff1c4",
    "O3": "#ffe394",
    "O4": "#ffd45d",
    "O5": "#ffc72e",
    "O6": "#ffbb00",
    "O7": "#ffa200",
    "O8": "#ff9100",
    "O9": "#dd6502",
    "O10": "#a94006",
    "G1": "#e4fff7",
    "G2": "#c1f7e6",
    "G3": "#98ead0",
    "G4": "#70ddba",
    "G5": "#47cfa4",
    "G6": "#1ec28e",
    "G7": "#14a87d",
    "G8": "#0a8f6b",
    "G9": "#00755a",
    "G10": "#005340",
    "T1": "#e6fdfe",
    "T2": "#c6f3f6",
    "T3": "#9ae7ec",
    "T4": "#6edce3",
    "T5": "#42d0d9",
    "T6": "#16c4cf",
    "T7": "#0fa9b6",
    "T8": "#078e9e",
    "T9": "#007385",
    "T10": "#004752",
    "A1": "#f2fcff",
    "A2": "#e0f9ff",
    "A3": "#b5f0ff",
    "A4": "#85e9ff",
    "A5": "#4de1ff",
    "A6": "#00d5ff",
    "A7": "#00c1e8",
    "A8": "#01a2ca",
    "A9": "#0278a7",
    "A10": "#054976",
    "B1": "#f0f7ff",
    "B2": "#d5eafe",
    "B3": "#80bdff",
    "B4": "#2f85f7",
    "B5": "#2376e5",
    "B6": "#265ed4",
    "B7": "#1a4cae",
    "B8": "#1b4596",
    "B9": "#1d3d7e",
    "B10": "#223367",
    "P1": "#f8f7fd",
    "P2": "#e6e3f7",
    "P3": "#cfc9f1",
    "P4": "#b7afeb",
    "P5": "#a094e4",
    "P6": "#887ade",
    "P7": "#7265c1",
    "P8": "#5c50a4",
    "P9": "#463b87",
    "P10": "#271f55",
    "M1": "#fdf7fc",
    "M2": "#f6dff3",
    "M3": "#efc0e8",
    "M4": "#e7a2de",
    "M5": "#e083d3",
    "M6": "#d864c9",
    "M7": "#be54b0",
    "M8": "#a44397",
    "M9": "#8a337e",
    "M10": "#65295d",
    "N1": "#ffffff",
    "N2": "#f8f9fa",
    "N3": "#f0f4f7",
    "N4": "#e8ecf2",
    "N5": "#d5dae3",
    "N6": "#aeb7c4",
    "N7": "#8c96a3",
    "N8": "#616c7a",
    "N9": "#47515c",
    "N10": "#303945",
    "transparent": "#00000000"
  },
  "font": {
    "family": {
      "body": "Manrope, Arial, sans-serif",
      "heading": "Telegraf, Manrope, Arial, sans-serif",
      "monospace": "'Space Mono', monospace"
    },
    "size": {
      "4xl": 36,
      "3xl": 32,
      "2xl": 24,
      "xl": 20,
      "lg": 18,
      "md": 16,
      "sm": 14
    },
    "weight": {
      "light": 300,
      "regular": 400,
      "medium": 500,
      "semiBold": 600,
      "bold": 700
    }
  },
  "palette": {
    "light": {
      "background": {
        "default": "#faf2e9",
        "reversed": "#032629",
        "opacity-100": {
          "default": "#faf2e9",
          "reversed": "#032629"
        },
        "opacity-10": {
          "reversed": "#0326291a",
          "default": "#faf2e91a"
        },
        "opacity-4": {
          "default": "#faf2e90a",
          "reversed": "#0326290a"
        }
      },
      "text": {
        "default": "#0a3235",
        "reversed": "#faf2e9",
        "placeholder": "#858585",
        "disabled": "#858585",
        "error": "#fd4645",
        "primary": {
          "default": "#0a3235",
          "reversed": "#faf2e9"
        },
        "secondary": {
          "default": "#032629",
          "reversed": "#faf2e9"
        }
      },
      "elements": {
        "primary": {
          "fill": "#f0529c",
          "hovered": {
            "fill": "#f375b0",
            "stroke": "#f0529c"
          },
          "contrast": "#032629",
          "stroke": "#f697c4",
          "contrast-reversed": "#032629"
        },
        "secondary": {
          "fill": "#0fcb8c",
          "hovered": {
            "fill": "#3fd5a3",
            "stroke": "#0fcb8c"
          },
          "contrast": "#032629",
          "stroke": "#9fead1",
          "contrast-reversed": "#032629"
        },
        "tertiary": {
          "fill": "#b11be8",
          "hovered": {
            "fill": "#c149ed",
            "stroke": "#b11be8"
          },
          "contrast": "#faf2e9",
          "stroke": "#d076f1",
          "contrast-reversed": "#faf2e9"
        },
        "error": {
          "fill": "#fd4645",
          "hovered": {
            "fill": "#fd6b6a",
            "stroke": "#fd4645"
          },
          "contrast": "#faf2e9",
          "stroke": "#fe908f",
          "contrast-reversed": "#faf2e9"
        },
        "warning": {
          "fill": "#ffa31c",
          "hovered": {
            "fill": "#ffb549",
            "stroke": "#ffa31c"
          },
          "contrast": "#032629",
          "stroke": "#ffc877",
          "contrast-reversed": "#032629"
        },
        "confirmation": {
          "fill": "#549c35",
          "hovered": {
            "fill": "#76b05d",
            "stroke": "#549c35"
          },
          "contrast": "#faf2e9",
          "stroke": "#98c486",
          "contrast-reversed": "#faf2e9"
        },
        "information": {
          "fill": "#2a88ef",
          "hovered": {
            "fill": "#55a0f2",
            "stroke": "#2a88ef"
          },
          "contrast": "#032629",
          "stroke": "#7fb8f5",
          "contrast-reversed": "#032629"
        },
        "overrides": {
          "form": {
            "checkbox": {
              "stroke": "#105257cc",
              "hovered": {
                "stroke": "#105257",
                "fill": "#faf2e933"
              },
              "fill": "#faf2e91a",
              "error": {
                "fill": "#fd4645",
                "stroke": "#fd4645",
                "contrast": "#faf2e9"
              },
              "checked": {
                "fill": "#105257",
                "contrast": "#faf2e9"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "contrast": "#5d5d5d",
                "stroke": "#aeaeae"
              }
            },
            "input": {
              "disabled": {
                "fill": "#d6d6d6",
                "contrast": "#858585",
                "stroke": "#aeaeae"
              },
              "error": {
                "fill": "#ffffff",
                "stroke": "#fd6b6a",
                "contrast": "#fd4645"
              },
              "fill": "#ffffff",
              "stroke": "#03262933",
              "hovered": {
                "fill": "#ffffff",
                "stroke": "#0fcb8c"
              },
              "contrast": "#f0529c"
            },
            "radio": {
              "fill": "#faf2e91a",
              "stroke": "#105257cc",
              "hovered": {
                "fill": "#faf2e933",
                "stroke": "#105257"
              },
              "error": {
                "fill": "#fd4645",
                "stroke": "#fd4645",
                "contrast": "#faf2e9"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "stroke": "#aeaeae",
                "contrast": "#5d5d5d"
              },
              "checked": {
                "fill": "#105257",
                "contrast": "#faf2e9"
              }
            },
            "switch": {
              "fill": "#faf2e91a",
              "stroke": "#105257cc",
              "hovered": {
                "fill": "#faf2e933",
                "stroke": "#105257"
              },
              "checked": {
                "fill": "#105257",
                "contrast": "#faf2e9"
              },
              "error": {
                "fill": "#fd4645",
                "stroke": "#fd4645",
                "contrast": "#faf2e9"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "stroke": "#aeaeae",
                "contrast": "#5d5d5d"
              }
            },
            "slider": {
              "fill": "#0326291a",
              "contrast": "#105257cc",
              "hovered": {
                "fill": "#faf2e966",
                "contrast": "#105257",
                "stroke": "#105257"
              },
              "error": {
                "fill": "#0326291a",
                "stroke": "#fd4645",
                "contrast": "#fd4645"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "stroke": "#5d5d5d",
                "contrast": "#5d5d5d"
              },
              "stroke": "#105257"
            }
          },
          "button": {
            "text": {
              "hovered": {
                "fill": "#0326290a"
              }
            }
          }
        },
        "disabled": {
          "fill": "#aeaeae",
          "hovered": {
            "fill": "#aeaeae",
            "stroke": "#aeaeae"
          },
          "contrast": "#faf2e9",
          "stroke": "#aeaeae",
          "contrast-reversed": "#5d5d5d"
        },
        "default": {
          "fill": "#105257",
          "hovered": {
            "fill": "#105257cc",
            "stroke": "#105257"
          },
          "contrast": "#faf2e9",
          "stroke": "#10525766",
          "contrast-reversed": "#032629"
        },
        "faded": {
          "fill": "#0326291a",
          "hovered": {
            "fill": "#03262966",
            "stroke": "#03262966"
          },
          "contrast-reversed": "#faf2e9",
          "stroke": "#03262933",
          "contrast": "#032629"
        }
      },
      "links": {
        "default": "#f0529c",
        "reversed": "#0fcb8c",
        "hovered": {
          "default": "#f375b0",
          "reversed": "#3fd5a3"
        }
      },
      "stroke": {
        "opacity-10": {
          "default": "#0326291a",
          "reversed": "#faf2e91a"
        },
        "opacity-100": {
          "default": "#032629",
          "reversed": "#faf2e9"
        },
        "disabled": {
          "default": "#aeaeae"
        },
        "opacity-4": {
          "reversed": "#faf2e90a",
          "default": "#0326290a"
        }
      },
      "icon": {
        "default": "#0a3235",
        "reversed": "#faf2e9",
        "disabled": "#858585",
        "error": "#fd4645"
      },
      "charting": {
        "categorical": {
          "dark": {
            "Red": "#ff9f9e",
            "Orange": "#ffd45d",
            "Aqua": "#b5f0ff",
            "Teal": "#9ae7ec",
            "Blue": "#80bdff",
            "Magenta": "#e7a2de",
            "Purple": "#b7afeb",
            "Green": "#70ddba"
          },
          "light": {
            "Red": "#932222",
            "Orange": "#dd6502",
            "Aqua": "#054976",
            "Teal": "#078e9e",
            "Blue": "#265ed4",
            "Magenta": "#65295d",
            "Purple": "#5c50a4",
            "Green": "#005340"
          }
        }
      },
      "accent": {
        "primary": "#f0529c",
        "secondary": "#0fcb8c",
        "tertiary": "#b11be8"
      },
      "alert": {
        "error": "#fd4645",
        "warning": "#ffa31c",
        "confirmation": "#549c35",
        "information": "#2a88ef"
      }
    },
    "dark": {
      "background": {
        "opacity-100": {
          "default": "#032629",
          "reversed": "#faf2e9"
        },
        "opacity-10": {
          "reversed": "#faf2e91a",
          "default": "#0326291a"
        },
        "opacity-4": {
          "default": "#0326290a",
          "reversed": "#faf2e90a"
        },
        "default": "#032629",
        "reversed": "#faf2e9"
      },
      "text": {
        "default": "#faf2e9",
        "reversed": "#0a3235",
        "placeholder": "#faf2e980",
        "disabled": "#858585",
        "error": "#fe908f",
        "primary": {
          "default": "#faf2e9",
          "reversed": "#0a3235"
        },
        "secondary": {
          "default": "#ebebeb",
          "reversed": "#5d5d5d"
        }
      },
      "elements": {
        "primary": {
          "fill": "#f0529c",
          "hovered": {
            "fill": "#f375b0",
            "stroke": "#f0529c"
          },
          "contrast": "#032629",
          "stroke": "#f697c4",
          "contrast-reversed": "#032629"
        },
        "secondary": {
          "fill": "#0fcb8c",
          "hovered": {
            "fill": "#3fd5a3",
            "stroke": "#0fcb8c"
          },
          "contrast": "#032629",
          "stroke": "#9fead1",
          "contrast-reversed": "#032629"
        },
        "tertiary": {
          "fill": "#b11be8",
          "hovered": {
            "fill": "#c149ed",
            "stroke": "#b11be8"
          },
          "contrast": "#faf2e9",
          "stroke": "#d076f1",
          "contrast-reversed": "#faf2e9"
        },
        "error": {
          "fill": "#fd4645",
          "hovered": {
            "fill": "#fd6b6a",
            "stroke": "#fd4645"
          },
          "contrast": "#faf2e9",
          "stroke": "#fe908f",
          "contrast-reversed": "#faf2e9"
        },
        "warning": {
          "fill": "#ffa31c",
          "hovered": {
            "fill": "#ffb549",
            "stroke": "#ffa31c"
          },
          "contrast": "#032629",
          "stroke": "#ffc877",
          "contrast-reversed": "#032629"
        },
        "confirmation": {
          "fill": "#549c35",
          "hovered": {
            "fill": "#76b05d",
            "stroke": "#549c35"
          },
          "contrast": "#faf2e9",
          "stroke": "#98c486",
          "contrast-reversed": "#faf2e9"
        },
        "information": {
          "fill": "#2a88ef",
          "hovered": {
            "fill": "#55a0f2",
            "stroke": "#2a88ef"
          },
          "contrast": "#032629",
          "stroke": "#7fb8f5",
          "contrast-reversed": "#032629"
        },
        "overrides": {
          "form": {
            "checkbox": {
              "stroke": "#faf2e9cc",
              "hovered": {
                "stroke": "#faf2e9",
                "fill": "#03262933"
              },
              "fill": "#0326291a",
              "error": {
                "fill": "#fd4645",
                "stroke": "#fd4645",
                "contrast": "#faf2e9"
              },
              "checked": {
                "fill": "#faf2e9",
                "contrast": "#032629"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "contrast": "#5d5d5d",
                "stroke": "#aeaeae"
              }
            },
            "input": {
              "disabled": {
                "fill": "#d6d6d6",
                "contrast": "#858585",
                "stroke": "#aeaeae"
              },
              "error": {
                "fill": "#0a3235",
                "stroke": "#fd6b6a",
                "contrast": "#fd4645"
              },
              "fill": "#0a3235",
              "stroke": "#faf2e91a",
              "hovered": {
                "fill": "#0a3235",
                "stroke": "#0fcb8c"
              },
              "contrast": "#0fcb8c"
            },
            "radio": {
              "fill": "#0326291a",
              "stroke": "#faf2e9cc",
              "hovered": {
                "fill": "#03262933",
                "stroke": "#faf2e9"
              },
              "error": {
                "fill": "#fd4645",
                "stroke": "#fd4645",
                "contrast": "#faf2e9"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "stroke": "#aeaeae",
                "contrast": "#5d5d5d"
              },
              "checked": {
                "fill": "#faf2e9",
                "contrast": "#032629"
              }
            },
            "switch": {
              "fill": "#0326291a",
              "stroke": "#faf2e9cc",
              "hovered": {
                "fill": "#03262933",
                "stroke": "#faf2e9"
              },
              "checked": {
                "fill": "#faf2e9",
                "contrast": "#032629"
              },
              "error": {
                "fill": "#fd4645",
                "stroke": "#fd4645",
                "contrast": "#faf2e9"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "stroke": "#aeaeae",
                "contrast": "#5d5d5d"
              }
            },
            "slider": {
              "fill": "#faf2e91a",
              "contrast": "#faf2e9cc",
              "hovered": {
                "fill": "#03262966",
                "contrast": "#faf2e9",
                "stroke": "#faf2e9"
              },
              "error": {
                "fill": "#faf2e91a",
                "stroke": "#fd4645",
                "contrast": "#fd4645"
              },
              "disabled": {
                "fill": "#d6d6d6",
                "stroke": "#5d5d5d",
                "contrast": "#5d5d5d"
              },
              "stroke": "#faf2e9"
            }
          },
          "button": {
            "text": {
              "hovered": {
                "fill": "#faf2e90a"
              }
            }
          }
        },
        "disabled": {
          "fill": "#aeaeae",
          "hovered": {
            "fill": "#aeaeae",
            "stroke": "#aeaeae"
          },
          "contrast": "#faf2e9",
          "stroke": "#aeaeae",
          "contrast-reversed": "#5d5d5d"
        },
        "default": {
          "fill": "#faf2e9",
          "hovered": {
            "fill": "#faf2e9cc",
            "stroke": "#faf2e9"
          },
          "contrast": "#032629",
          "stroke": "#faf2e966",
          "contrast-reversed": "#faf2e9"
        },
        "faded": {
          "fill": "#faf2e91a",
          "hovered": {
            "fill": "#faf2e966",
            "stroke": "#faf2e966"
          },
          "contrast-reversed": "#032629",
          "stroke": "#faf2e933",
          "contrast": "#faf2e9"
        }
      },
      "links": {
        "default": "#0fcb8c",
        "reversed": "#f0529c",
        "hovered": {
          "default": "#3fd5a3",
          "reversed": "#f375b0"
        }
      },
      "stroke": {
        "opacity-10": {
          "default": "#faf2e91a",
          "reversed": "#0326291a"
        },
        "opacity-100": {
          "default": "#faf2e9",
          "reversed": "#032629"
        },
        "disabled": {
          "default": "#aeaeae"
        },
        "opacity-4": {
          "reversed": "#0326290a",
          "default": "#faf2e90a"
        }
      },
      "icon": {
        "default": "#faf2e9",
        "reversed": "#0a3235",
        "disabled": "#858585",
        "error": "#fe908f"
      },
      "charting": {
        "categorical": {
          "dark": {
            "Red": "#ff9f9e",
            "Orange": "#ffd45d",
            "Aqua": "#b5f0ff",
            "Teal": "#9ae7ec",
            "Blue": "#80bdff",
            "Magenta": "#e7a2de",
            "Purple": "#b7afeb",
            "Green": "#70ddba"
          },
          "light": {
            "Red": "#932222",
            "Orange": "#dd6502",
            "Aqua": "#054976",
            "Teal": "#078e9e",
            "Blue": "#265ed4",
            "Magenta": "#65295d",
            "Purple": "#5c50a4",
            "Green": "#005340"
          }
        }
      },
      "accent": {
        "primary": "#f0529c",
        "secondary": "#0fcb8c",
        "tertiary": "#b11be8"
      },
      "alert": {
        "error": "#fd4645",
        "warning": "#ffa31c",
        "confirmation": "#549c35",
        "information": "#2a88ef"
      }
    }
  },
  "radii": {
    "xs": "2px",
    "sm": "4px",
    "md": "8px",
    "lg": "12px",
    "xl": "16px",
    "full": "9999px"
  },
  "sizes": {
    "icon": {
      "xs": 16,
      "sm": 24,
      "md": 28,
      "lg": 32,
      "xl": 48
    }
  },
  "spacing": {
    "0": 0,
    "1": 8,
    "2": 16,
    "3": 24,
    "4": 32,
    "5": 40,
    "6": 48,
    "7": 56,
    "8": 64,
    "10": 80,
    "11": 88,
    "12": 96,
    "13": 104,
    "14": 112,
    "15": 120,
    "16": 128,
    "baseSpacingValue": 8
  },
  "typography": {
    "h1": "500 36/1.2 Telegraf, Manrope, Arial, sans-serif",
    "h2": "500 32/1.2 Telegraf, Manrope, Arial, sans-serif",
    "h3": "500 24/1.2 Telegraf, Manrope, Arial, sans-serif",
    "h4": "500 20/1.2 Telegraf, Manrope, Arial, sans-serif",
    "h5": "500 18/1.2 Telegraf, Manrope, Arial, sans-serif",
    "h6": "500 16/1.3 Telegraf, Manrope, Arial, sans-serif",
    "body1": "400 16/1.5 Manrope, Arial, sans-serif",
    "body2": "400 14/1.5 Manrope, Arial, sans-serif",
    "subtitle1": "400 16/1.2 Manrope, Arial, sans-serif",
    "subtitle2": "500 14/1.2 Manrope, Arial, sans-serif",
    "overline": "400 14/1.2 Telegraf, Manrope, Arial, sans-serif",
    "monospace": "400 16/1.5 'Space Mono', monospace",
    "caption": "400 14/1.2 Manrope, Arial, sans-serif"
  },
  "letterSpacing": {
    "0": 0
  },
  "lineHeight": {
    "1": 1.2,
    "2": 1.3,
    "3": 1.4,
    "4": 1.5
  }
};
var varnish_tokens_simple_hex_unitless_default = varnishTokens;

export { varnish_tokens_simple_hex_unitless_default as default };
