import {
    Separator as AriaSeparator,
    type SeparatorProps as AriaSeparatorProps,
} from 'react-aria-components';
import { PropsWithChildren } from 'react';

import { menuRecipe } from './menu.styles';
import { cx } from '@/utils/cx';

type SeparatorProps = {
    className?: string;
} & AriaSeparatorProps;

const Separator = ({ className, ...rest }: PropsWithChildren<SeparatorProps>) => {
    const [variantProps, localProps] = menuRecipe.splitVariantProps(rest);
    const recipeClassNames = menuRecipe(variantProps);
    return <AriaSeparator className={cx(recipeClassNames.separator, className)} {...localProps} />;
};

export { Separator };
export type { SeparatorProps };
