
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Icon'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Button allows to display icon either start or end position. You can also display icon alone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
render(
    <varnishUi.Stack direction="column" spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="outlined" startIcon={<varnishUi.Icons.Add />}>
                Outlined
            </varnishUi.Button>
            <varnishUi.Button variant="contained" startIcon={<varnishUi.Icons.Add />}>
                Contained
            </varnishUi.Button>
            <varnishUi.Button variant="contained" startIcon={<varnishUi.Icons.Add />}>
                Text
            </varnishUi.Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="outlined" endIcon={<varnishUi.Icons.Check />}>
                Outlined
            </varnishUi.Button>
            <varnishUi.Button variant="contained" endIcon={<varnishUi.Icons.Check />}>
                Contained
            </varnishUi.Button>
            <varnishUi.Button variant="text" endIcon={<varnishUi.Icons.Check />}>
                Text
            </varnishUi.Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button
                variant="outlined"
                startIcon={<varnishUi.Icons.Add />}
                endIcon={<varnishUi.Icons.Remove />}>
                Outlined
            </varnishUi.Button>
            <varnishUi.Button
                variant="contained"
                startIcon={<varnishUi.Icons.Add />}
                endIcon={<varnishUi.Icons.Remove />}>
                Contained
            </varnishUi.Button>
            <varnishUi.Button
                variant="text"
                startIcon={<varnishUi.Icons.Add />}
                endIcon={<varnishUi.Icons.Remove />}>
                Text
            </varnishUi.Button>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;