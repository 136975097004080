
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const exampleCardHeader = 'myCardHeader';
const exampleCardBody = 'myCardBody';
const exampleCardFooter = 'myCardFooter';

const CustomCard = mui.styled(varnishUi.Card)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};

    .\${exampleCardHeader} {
        border: 2px dashed orange;
    }
    .\${exampleCardBody} {
        border: 2px dashed yellow;
    }
    .\${exampleCardFooter} {
        border: 2px dashed blue;
    }
\`;

render(
    <CustomCard>
        <varnishUi.CardHeader className={exampleCardHeader}>
            <p>Hello World</p>
        </varnishUi.CardHeader>
        <varnishUi.CardBody className={exampleCardBody}>
            <p>benevolent</p>
            <p>adjective</p>
            <p>well meaning and kindly.</p>
        </varnishUi.CardBody>
        <varnishUi.CardFooter className={exampleCardFooter}>
            <varnishUi.Button>Learn More</varnishUi.Button>
        </varnishUi.CardFooter>
    </CustomCard>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;