import { PropsWithChildren, ReactNode } from 'react';

import cardRecipe, { CardRecipeProps } from './card.styles';
import { cx } from '@/utils/cx';

type CardFooterProps = {
    className?: string;
    children?: ReactNode;
    ariaLabel?: string;
} & CardRecipeProps;

const CardFooter = ({
    className,
    children,
    ariaLabel,
    ...rest
}: PropsWithChildren<CardFooterProps>) => {
    const [variantProps, localProps] = cardRecipe.splitVariantProps(rest);
    const recipeClassNames = cardRecipe(variantProps);

    return (
        <footer
            className={cx(recipeClassNames.footer, className)}
            aria-label={ariaLabel}
            {...localProps}>
            {children}
        </footer>
    );
};

export { CardFooter };
export type { CardFooterProps };
