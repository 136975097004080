import { PropsWithChildren, ReactNode } from 'react';

import cardRecipe, { CardRecipeProps } from './card.styles';
import { cx } from '@/utils/cx';

type CardHeaderProps = {
    className?: string;
    children?: ReactNode;
    ariaLabel?: string;
} & CardRecipeProps;

const CardHeader = ({
    className,
    children,
    ariaLabel,
    ...rest
}: PropsWithChildren<CardHeaderProps>) => {
    const [variantProps, localProps] = cardRecipe.splitVariantProps(rest);
    const recipeClassNames = cardRecipe(variantProps);

    return (
        <div
            className={cx(recipeClassNames.header, className)}
            aria-label={ariaLabel}
            {...localProps}>
            {children}
        </div>
    );
};

export { CardHeader };
export type { CardHeaderProps };
