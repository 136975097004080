
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Selection'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Menu supports multiple selection modes. By default, selection is disabled, however this can be changed using the selectionMode prop.
Use defaultSelectedKeys to provide a default set of selected items (uncontrolled) and selectedKeys to set the selected items (controlled).
The value of the selected keys must match the id prop of the items.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from "react";
import type {Selection} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
  const [selected, setSelected] = React.useState<Selection>(new Set(['center']));
  const [selectedMultiple, setSelectedMultiple] = React.useState<Selection>(
    new Set(['sidebar', 'console'])
  );

  return (
    <varnishUi.Stack>
        <>
            <varnishUi.MenuTrigger>
                <varnishUi.Button aria-label="Align">Align</varnishUi.Button>
                <varnishUi.Popover>
                    <varnishUi.AriaMenu selectionMode="single" selectedKeys={selected} onSelectionChange={setSelected}>
                        <varnishUi.MenuItem id="left">
                            Left
                        </varnishUi.MenuItem>
                        <varnishUi.MenuItem id="center">
                            Center
                        </varnishUi.MenuItem>
                        <varnishUi.MenuItem id="Right">
                            Right
                        </varnishUi.MenuItem>
                    </varnishUi.AriaMenu>
                </varnishUi.Popover>
            </varnishUi.MenuTrigger>
            <p>Current selection (controlled): {[...selected].join(', ')}</p>
        </>
        <>
            <varnishUi.MenuTrigger>
                <varnishUi.Button aria-label="View">View</varnishUi.Button>
                <varnishUi.Popover>
                    <varnishUi.AriaMenu selectionMode="multiple" selectedKeys={selectedMultiple} onSelectionChange={setSelectedMultiple}>
                        <varnishUi.MenuItem id="sidebar">
                            Sidebar
                        </varnishUi.MenuItem>
                        <varnishUi.MenuItem id="searchbar">
                            Searchbar
                        </varnishUi.MenuItem>
                        <varnishUi.MenuItem id="tools">
                            Tools
                        </varnishUi.MenuItem>
                        <varnishUi.MenuItem id="console">
                            Console
                        </varnishUi.MenuItem>
                    </varnishUi.AriaMenu>
                </varnishUi.Popover>
            </varnishUi.MenuTrigger>
            <p>
                Current selection (controlled):{' '}
                {selectedMultiple === 'all' ? 'all' : [...selectedMultiple].join(', ')}
            </p>
        </>
    </varnishUi.Stack>
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;