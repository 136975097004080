import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const menuRecipe = sva({
    slots: ['root', 'menuItem', 'menuSection', 'separator', 'header'],
    base: {
        root: {
            maxHeight: '[inherit]',
            boxSizing: 'border-box',
            overflow: 'auto',
            padding: '[2px]',
            minWidth: '[150px]',
            outline: 'none',
            '& a[role="menuitem"]': {
                textDecoration: 'none',
                color: 'cream.100',
            },
            '& [role="separator"]': {
                height: '[1px]',
                background: 'gray.60',
                margin: '[2px 4px]',
            },
        },
        menuItem: {
            margin: '[2px]',
            padding: '[5px, 9px]',
            borderRadius: '[6px]',
            outline: 'none',
            cursor: 'default',
            fontSize: '[17px]',
            position: 'relative',
            display: 'grid',
            gridTemplateAreas: `
                "label kbd"
                "desc  kbd"
            `,
            alignItems: 'center',
            columnGap: '[20px]',
            forcedColorAdjust: 'none',
            '&[data-resizing]': {
                backgroundColor: 'accent.tertiary',
            },
            '&[data-selection-mode]': {
                paddingLeft: '[24px]',
                '&::before': {
                    position: 'absolute',
                    left: '[4px]',
                    fontWeight: '[600]',
                },
                '&[data-selection-mode=multiple][data-selected]::before': {
                    content: '"✓"',
                    position: 'absolute',
                    left: '[4px]',
                    fontWeight: '[600]',
                },
                '&[data-selection-mode=single][data-selected]::before': {
                    content: '"●"',
                    transform: 'scale(0.7)',
                },
            },
            '&[data-disabled]': {
                color: 'gray.60',
            },
            '&[data-focused]': {
                backgroundColor: 'accent.tertiary',
            },
            '& [slot="label"]': {
                fontWeight: 'bold',
                gridArea: 'label',
            },
            '& [slot="description"]': {
                fontSize: 'sm',
                gridArea: 'desc',
            },
            '& kbd': {
                gridArea: 'kbd',
                textAlign: 'end',
            },
        },
        separator: {},
        menuSection: {
            '&:not(:first-child)': {
                marginTop: '[12px]',
            },
        },
        header: {
            fontWeight: 'bold',
            fontSize: '[18px]',
        },
    },
});

type MenuRecipeProps = Exclude<RecipeVariantProps<typeof menuRecipe>, undefined>;

export { menuRecipe };
export type { MenuRecipeProps };
